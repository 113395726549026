import { Section, Messages as CoreMessages } from "modules/core";
import React from "react";

const Messages = ({ messages }) => {
	if (!messages) {
		return null;
	}

	return (
		<Section horizontal>
			<CoreMessages messages={messages} />
		</Section>
	);
};

export default Messages;
