import React, { useMemo } from "react";
import { Checkbox, Container, Input, Label, Price } from "./Item.styles";

const Item = ({
	item,
	selectedProductOptions,
	onSelectedProductOptionChanged
}) => {
	const checked = selectedProductOptions[item.menuItemId];
	const inputId = useMemo(() => `product-options-${item.menuItemId}`, [item]);
	const price = useMemo(() => `+ ${item.priceAfterDiscount} kr`, [item]);

	const handleOnChange = () => {
		onSelectedProductOptionChanged({
			...selectedProductOptions,
			[item.menuItemId]: !checked ? item : false
		});
	};

	return (
		<Container>
			<Checkbox>
				<Input
					checked={checked}
					onChange={handleOnChange}
					type="checkbox"
					id={inputId}
				/>
			</Checkbox>

			<Label htmlFor={inputId}>{item.product.name}</Label>

			<Price>{price}</Price>
		</Container>
	);
};

export default Item;
