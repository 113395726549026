import { route, useNavigation } from "modules/navigation";
import { head } from "ramda";
import { useEffect, useMemo } from "react";

const shouldRedirect = checkin => checkin?.apps.length === 1;

const useRedirectToApp = ({ checkin, eventId }) => {
	const { navigate } = useNavigation();
	const isRedirecting = useMemo(() => shouldRedirect(checkin), [checkin]);

	useEffect(() => {
		if (!checkin) {
			return;
		}

		const { apps, checkinId } = checkin;

		if (shouldRedirect(checkin)) {
			const app = head(apps);
			navigate(route.app, { type: app.type, checkinId, eventId });
		}
	}, [navigate, checkin, eventId]);

	return { isRedirecting };
};

export default useRedirectToApp;
