import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: ${p => p.theme.spaceSmall};
	border: 2px solid ${p => p.theme.colorWhite};
	padding-top: ${p => p.theme.spaceSmall};
	padding-bottom: ${p => p.theme.spaceMini};
	padding-left: ${p => p.theme.spaceLarge};
	padding-right: ${p => p.theme.spaceLarge};
	margin-bottom: ${p => p.theme.spaceLarge};
	min-width: 115px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`;

const Prefix = styled.div`
	font-size: 16px;
	line-height: 1.3;
	color: ${p => p.theme.colorWhite};
	text-align: center;
	text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
`;

const Label = styled.div`
	font-size: 54px;
	font-weight: 500;
	line-height: 1.15;
	color: ${p => p.theme.colorWhite};
	text-align: center;
	text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`;

const Badge = ({ prefix, label }) => {
	return (
		<Container>
			<Prefix>{prefix}</Prefix>
			<Label>{label}</Label>
		</Container>
	);
};

export default Badge;
