import { cond, path, propEq, T } from "ramda";
import styled from "styled-components";

const eventDistance = 22 * 2; // spaceHuge
const statusDotCircumference = 38;
const statusDotRadius = statusDotCircumference / 2;
const statusDotBorderWidth = 2;
const statusDotInnerCircumference =
	statusDotCircumference - statusDotBorderWidth * 2;
const timelineWidth = 8;
const labelSize = 15;
const labelLineHeight = labelSize * 1.2;

const Container = styled.div`
	display: flex;
	margin-bottom: ${eventDistance}px;
	flex-direction: row;
	align-items: flex-start;
	margin-top: ${p => p.first && 0};
	margin-bottom: ${p => p.last && 0};
`;

const Timeline = styled.div`
	position: absolute;
	width: ${timelineWidth}px;
	// Centers the timeline in the statusDot
	left: ${statusDotRadius - timelineWidth / 2}px;
	background-color: ${p => p.theme.colorEmeraldGreen};
	background-color: ${cond([
		[propEq("status", "pending"), path(["theme", "colorBorderPrimary"])],
		[propEq("status", "completed"), path(["theme", "colorEmeraldGreen"])],
		[propEq("status", "error"), path(["theme", "colorBitterSweetRed"])]
	])};
	height: ${p => p.height + eventDistance}px;
	top: ${p => -(p.height / 2 + eventDistance)}px;
`;

const StatusDot = styled.div`
	z-index: 999;
	display: flex;
	border-radius: 999px;
	justify-content: center;
	align-items: center;
	margin-right: ${p => p.theme.spaceMedium};
	padding: ${statusDotBorderWidth}px;
	background-color: ${p => p.theme.colorWhite};
`;

const StatusDotInner = styled.div`
	display: flex;
	height: ${statusDotInnerCircumference}px;
	width: ${statusDotInnerCircumference}px;
	border-radius: 999px;
	justify-content: center;
	align-items: center;
	background: ${cond([
		[propEq("status", "pending"), path(["theme", "colorWhite"])],
		[propEq("status", "completed"), path(["theme", "colorEmeraldGreen"])],
		[propEq("status", "error"), path(["theme", "colorBitterSweetRed"])]
	])};
	border-width: ${p => (p.status === "pending" ? 6 : 0)}px;
	border-color: ${p =>
		p.status === "pending" ? p.theme.colorBorderPrimary : "transparent"};
	border-style: solid;
`;

const Body = styled.div`
	min-width: 0;
	flex-shrink: 1;
	position: relative;
	// Aligns the body so that the label is centered on the statusDot
	top: ${statusDotRadius - labelLineHeight / 2}px;
`;

const Label = styled.div`
	font-size: ${labelSize}px;
	line-height: ${labelLineHeight}px;
	color: ${cond([
		[propEq("status", "pending"), path(["theme", "colorTextMuted"])],
		[T, path(["theme", "colorTextPrimary"])]
	])};
	user-select: none;
`;

const Message = styled.div`
	font-size: 13.5px;
	line-height: 1.3;
	margin-top: ${p => p.theme.spaceTiny};
	color: ${p => p.theme.colorTextMuted};
	margin-left: 0.6px;
`;

export { Container, Timeline, StatusDot, StatusDotInner, Body, Label, Message };
