import { toPairs } from "lodash";
import { compose, map } from "ramda";
import React from "react";
import styled from "styled-components";
import { formatCurrency } from "utils";
import Label from "./Label";
import Text from "./Text";

const Group = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom: ${p => (p.isLast ? 0 : p.theme.spaceTiny)};
`;

const VatGroups = ({ order }) => {
	const { vatTotal, groupedVatTotal } = order;
	const shouldRenderGroups = !!groupedVatTotal;
	const renderVat = !!vatTotal;

	const renderRow = ({ label, value, isLast }) => (
		<Group isLast={isLast} key={label}>
			<Text>{label}</Text>
			<Text>{formatCurrency(value)}</Text>
		</Group>
	);

	const renderGroup = compose(renderRow, ({ vatRate, vatTotal }) => ({
		label: `${vatRate * 100}%`,
		value: vatTotal
	}));

	const renderGroups = compose(
		map(renderGroup),
		map(([vatRate, { vatTotal }]) => ({ vatRate, vatTotal })),
		toPairs
	);

	if (!groupedVatTotal && !vatTotal) {
		return null;
	}

	return (
		<>
			<Label>Vat</Label>
			{shouldRenderGroups && renderGroups(groupedVatTotal)}
			{renderVat &&
				renderRow({ label: "Total", value: vatTotal, isLast: true })}
		</>
	);
};

export default VatGroups;
