import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	padding: ${p => p.theme.spaceLarge};
	margin-top: ${p => p.theme.spaceSmall};
	display: flex;
	align-items: center;
	color: ${p => p.theme.colorWhite};

	&:first-child {
		margin-top: 0;
	}

	@media ${mediaQuery(device.tablet)} {
		cursor: pointer;
		padding: ${p => p.theme.spaceMedium};

		&:hover {
			background-color: ${p => p.theme.colorDirtyWhite};
		}
	}
`;

const Item = ({ children, onClick }) => {
	return <Container onClick={onClick}>{children}</Container>;
};

export default Item;
