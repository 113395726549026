import { EmptyState } from "modules/core";
import React from "react";
import { useTheme } from "styled-components";

const ErrorScreen = ({ error }) => {
	const theme = useTheme();

	if (error.message === "404") {
		return (
			<EmptyState
				label="QR-code / link not found"
				icon="close-circle"
				iconColor={theme.colorBitterSweetRed}
			/>
		);
	}

	return (
		<EmptyState
			label="Something went wrong"
			icon="close-circle"
			iconColor={theme.colorBitterSweetRed}
		/>
	);
};

export default ErrorScreen;
