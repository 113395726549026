import { Icon } from "modules/core";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";

const Button = styled.div`
	flex-shrink: 1;
	padding: ${p => p.theme.spaceMini} 0;
	padding-left: ${p => p.theme.spaceHuge};
	cursor: pointer;
`;

const CloseButton = ({ onClick }) => {
	return (
		<Button onClick={onClick}>
			<Icon name="close" size={26} color={theme.colorIconBlack} />
		</Button>
	);
};

export default CloseButton;
