import React from "react";
import { formatCurrency } from "utils";
import styled from "styled-components";
import { useGlobalState } from "state";

const Divider = styled.div`
	&:after {
		content: "-";
		padding-left: ${p => p.theme.spaceMini};
		padding-right: ${p => p.theme.spaceMini};
	}
`;

const Value = styled.div``;

const Total = () => {
	const { cart } = useGlobalState();

	return (
		<>
			<Divider />

			<Value>
				{formatCurrency(cart.total, {
					toFixed: 0,
					minimumFractionDigits: 0
				})}
			</Value>
		</>
	);
};

export default Total;
