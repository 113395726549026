import { Icon } from "modules/core";
import { cond, propEq } from "ramda";
import theme from "styles/theme";
import MessengerIcon from "../components/MessengerIcon";

const Messenger =
	({ openMessengerModal }) =>
	() => ({
		key: "Messenger",
		label: "Messenger",
		onClick: openMessengerModal,
		leftIcon: () => <MessengerIcon />
	});

const SMS =
	({ openSmsModal }) =>
	() => ({
		key: "SMS",
		label: "SMS",
		onClick: openSmsModal,
		leftIcon: () => (
			<Icon name="chat" size={25.5} color={theme.colorEmeraldGreen} />
		),
		leftIconColor: theme.colorEmeraldGreen
	});

const Email =
	({ openEmailModal }) =>
	() => ({
		key: "EMAIL",
		label: "Email",
		onClick: openEmailModal,
		leftIcon: "email",
		leftIconColor: theme.colorMidGray
	});

const storeContactChannelToDisplayContactChannel = ({
	openMessengerModal,
	openSmsModal,
	openEmailModal
}) =>
	cond([
		[propEq("type", "MESSENGER"), Messenger({ openMessengerModal })],
		[propEq("type", "SMS"), SMS({ openSmsModal })],
		[propEq("type", "EMAIL"), Email({ openEmailModal })]
	]);

export default storeContactChannelToDisplayContactChannel;
