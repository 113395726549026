import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	background: ${p => p.theme.colorWhite};
	flex-basis: 100%;
	flex: 1;
	position: relative;
	overflow: hidden;
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@media ${mediaQuery(device.tablet)} {
		min-height: 300px;
		padding-bottom: ${p => p.theme.spaceHuge};
	}
`;

export { Container, Body };
