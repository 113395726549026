import { equals, isEmpty } from "ramda";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
	display: grid;
	grid-template-columns: ${p => `repeat(${p.columns}, 1fr)`};
	width: 100%;
	padding: ${p => p.theme.spaceMini};
	background: ${p => p.theme.colorDirtyWhite};
	border-radius: ${p => p.theme.spaceSmall};
`;

const ToogleButton = ({ value, states, onChange }) => {
	const handleClick = useCallback(s => () => onChange(s.value), [onChange]);

	const isActiveState = useCallback(
		s => value && equals(value, s.value),
		[value]
	);

	const buttons = useMemo(
		() =>
			states.map(s => (
				<Button
					key={s.label}
					onClick={handleClick(s)}
					active={isActiveState(s)}
				>
					{s.label}
				</Button>
			)),
		[states, handleClick, isActiveState]
	);

	if (!states || isEmpty(states)) {
		return null;
	}

	return <Container columns={buttons.length}>{buttons}</Container>;
};

ToogleButton.defaultProps = {
	onChange: () => {}
};

export default ToogleButton;
