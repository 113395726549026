import config from "config";
import { route } from "modules/navigation";
import { compose } from "ramda";

const openMessengerBot = ({ navigate, userId, orderId }) =>
	compose(
		url => navigate(route.CUSTOM, { url, mode: "open" }),
		ref => `${config.FACEBOOK_MESSENGER_BOT_URL}?ref=${ref}`,
		encodeURIComponent,
		() => JSON.stringify({ u: userId, o: orderId })
	)();

export default openMessengerBot;
