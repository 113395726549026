import { contactChannelRepo } from "data";
import { andThen, compose } from "ramda";
import { useEffect, useState } from "react";

const useStoreContactChannels = storeId => {
	const [contactChannels, setContactChannels] = useState([]);

	useEffect(() => {
		if (!storeId) {
			return;
		}

		compose(
			andThen(setContactChannels),
			contactChannelRepo.getByStoreId
		)({ storeId });
	}, [storeId]);

	return contactChannels;
};

export default useStoreContactChannels;
