import styled from "styled-components";

const Main = styled.div`
	display: flex;
	flex-direction: column;
	height: 50%;
	justify-content: space-between;
`;

export default Main;
