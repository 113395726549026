import React from "react";
import ClampLines from "react-clamp-lines";
import styled from "styled-components";

const Text = styled(ClampLines)`
	font-size: 12px;
	margin-bottom: ${p => p.theme.spaceSmall};
	color: ${p => p.theme.colorTextMuted};
	line-height: 1.4;
`;

const Description = ({ itemId, children }) => {
	return (
		<Text
			id={`item-${itemId}-description`}
			text={children}
			lines={2}
			ellipsis="..."
			innerElement="p"
			buttons={false}
		/>
	);
};

Description.defaultProps = {
	children: ""
};

export default Description;
