import { Section, SectionLabel } from "modules/core";
import { when } from "ramda";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "state";
import styled from "styled-components";
import isValidTip from "./is-valid-tip";
import OptionFactory from "./OptionFactory";
import Options from "./Options";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Tip = ({ total }) => {
	const [options, setOptions] = useState([]);
	const { tipOption, setTipOption } = useGlobalState();

	useEffect(() => {
		setOptions([
			OptionFactory({ total, percentage: 0.05 }),
			OptionFactory({ total, percentage: 0.1 }),
			OptionFactory({ total, percentage: 0.2 }),
			OptionFactory({
				custom: true,
				customAmount:
					tipOption?.optionId === "custom" ? tipOption.amount : null
			})
		]);
	}, [total, tipOption]);

	const safelySetTip = when(isValidTip, setTipOption);

	const handleOptionSelected = o => {
		safelySetTip(o);
		setTipOption(o);
	};

	return (
		<Section>
			<Container>
				<SectionLabel>Tip</SectionLabel>

				<Options
					options={options}
					selectedOption={tipOption}
					onOptionSelected={handleOptionSelected}
				/>
			</Container>
		</Section>
	);
};

export default Tip;
