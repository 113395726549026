import { compose, map } from "ramda";
import { useEffect, useState } from "react";
import storeContactChannelToDisplayContactChannel from "./store-conctact-channel-to-display-contact-channel";

const useDisplayStoreContactChannels = ({
	contactChannels,
	openMessengerModal,
	openSmsModal,
	openEmailModal
}) => {
	const [displayContactChannels, setDisplayContactChannels] = useState([]);

	useEffect(() => {
		if (!Array.isArray(contactChannels)) {
			return;
		}

		compose(
			setDisplayContactChannels,
			map(
				storeContactChannelToDisplayContactChannel({
					openMessengerModal,
					openSmsModal,
					openEmailModal
				})
			)
		)(contactChannels);
	}, [contactChannels, openEmailModal, openMessengerModal, openSmsModal]);

	return displayContactChannels;
};

export default useDisplayStoreContactChannels;
