import React from "react";
import styled from "styled-components";

const Container = styled.div`
	height: 80px;
`;

const ContainerInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: -${p => p.theme.spaceGiant};
`;

const Heading = styled.span`
	font-family: Peak-Semibold;
	color: ${p => p.theme.colorTextWhite};
	text-align: center;
	width: 80%;
	margin-bottom: ${p => p.theme.spaceSmall};
	font-size: 54px;
	line-height: 0.9;
`;

const Text = styled.span`
	text-align: center;
	color: ${p => p.theme.colorTextWhite};
	font-size: 16px;
	line-height: 1.1;
	font-weight: 500;
`;

const Content = ({ heading, message }) => {
	return (
		<Container>
			<ContainerInner>
				<Heading>{heading}</Heading>
				<Text>{message}</Text>
			</ContainerInner>
		</Container>
	);
};

export default Content;
