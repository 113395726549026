import styled from "styled-components";

const Label = styled.span`
	font-size: 12px;
	line-height: 1.1;
	font-weight: 500;
	margin-bottom: ${p => p.theme.spaceTiny};
`;

export default Label;
