import { curry } from "ramda";
import BeingPaidByOtherUsersMessage from "./BeingPaidByOtherUsersMessage";
import filterFlatItemsByTabItemId from "./filter-flat-items-by-tab-item-id";
import getNumberOfUnpaidFlatItemsClaimedByOtherUsers from "./get-number-of-unpaid-flat-Items-claimed-by-other-users";
import isItemActivleyClaimedByOtherUsers from "./is-item-actively-claimed-by-other-users";

const getClaimsMessages = curry((userId, flatItems, item) => {
	const itemFlatItems = filterFlatItemsByTabItemId(item.tabItemId, flatItems);
	const numberOfUnpaidFlatItemsClaimedByOtherUsers =
		getNumberOfUnpaidFlatItemsClaimedByOtherUsers(userId, itemFlatItems);
	const isItemClaimedByOthers = isItemActivleyClaimedByOtherUsers(
		userId,
		itemFlatItems
	);
	const message = BeingPaidByOtherUsersMessage({
		itemQuantity: item.quantity,
		numberOfUnpaidFlatItemsClaimedByOtherUsers
	});

	return isItemClaimedByOthers ? [message] : [];
});

export default getClaimsMessages;
