import urlcat from "urlcat";
import config from "../config";
import httpClient from "./http-client";

const ReceiptRepo = {
	sendReceipt: ({ orderId, email }) => {
		if (typeof orderId !== "number") {
			throw new Error("orderId is not a number");
		}

		if (typeof email !== "string") {
			throw new Error("email is not a string");
		}

		return httpClient.fetch(
			urlcat(`${config.API_BASE_URL}/orders/:orderId/receipts`, {
				orderId
			}),
			{
				method: "POST",
				body: JSON.stringify({ email })
			}
		);
	}
};

export default ReceiptRepo;
