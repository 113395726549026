import { map } from "ramda";
import React, { useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";
import PaymentMethodsHOC from "./PaymentMethodsHOC";
import ItemWithLabelAndIcon from "./ItemWithLabelAndIcon";
import styled from "styled-components";

const DropdownMenu = styled.div`
	width: 280px;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
	border-color: ${p => p.theme.colorBorderPrimary};
`;

const PaymentMethodsDropdown = ({ items, as }) => {
	const [show, setShow] = useState(false);

	const handleClick = useCallback(
		item => e => {
			item.onClick(e);
			setShow(false);
		},
		[]
	);

	return (
		<Dropdown show={show} onToggle={setShow} align="end">
			<Dropdown.Toggle as={as}>More</Dropdown.Toggle>

			<Dropdown.Menu as={DropdownMenu}>
				{map(
					item => (
						<ItemWithLabelAndIcon
							key={item.key}
							item={item}
							onClick={handleClick(item)}
						/>
					),
					items
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default PaymentMethodsHOC(PaymentMethodsDropdown);
