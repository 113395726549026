import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	align-items: flex-start;
`;

const imageHeightSmall = 36;
const imageHeightLarge = 55;

const ImageContainer = styled.div`
	height: ${imageHeightSmall}px;
	width: ${imageHeightSmall}px;
	border-radius: ${imageHeightSmall / 2}px;
	margin-right: ${p => p.theme.spaceSmall};
	overflow: hidden;
	user-select: none;
	background: ${p => p.theme.colorSoftGray};

	${p =>
		p.size === "large" &&
		`
		@media ${mediaQuery(device.tablet)} {
			height: ${imageHeightLarge}px;
			width: ${imageHeightLarge}px;
			border-radius: ${imageHeightLarge / 2}px;
		}
	`}
`;

const Image = styled.img`
	height: ${imageHeightSmall}px;
	width: auto;
	user-select: none;

	${p =>
		p.size === "large" &&
		`
		@media ${mediaQuery(device.tablet)} {
			height: ${imageHeightLarge}px;
		}
	`}
`;

const Name = styled.h1`
	font-size: 19px;
	font-weight: 700;
	font-family: Peak-Semibold;
	line-height: 1.1;

	${p =>
		p.size === "large" &&
		`
		@media ${mediaQuery(device.tablet)} {
			font-size: 22px;
		}

		@media ${mediaQuery(device.laptop)} {
			font-size: 24px;
		}
	`}
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	align-self: stretch;
	justify-content: center;
`;

export { Container, ImageContainer, Image, Name, Body };
