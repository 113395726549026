import { Badge } from "modules/core";
import { useTab } from "modules/tab";
import React, { useMemo } from "react";
import { formatCurrency } from "utils";

const TabBadge = ({ checkinId }) => {
	const [tab, error] = useTab({ checkinId });
	const label = useMemo(
		() => formatCurrency(tab?.currentUserClaimableTotal),
		[tab]
	);

	if (error?.message === "Not found") {
		return null;
	}

	if (!tab) {
		return null;
	}

	if (tab.currentUserClaimableTotal === 0) {
		return null;
	}

	return <Badge>{label}</Badge>;
};

export default TabBadge;
