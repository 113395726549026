const shouldSignIn = ({ currentUser, customUserToken }) => {
	if (!currentUser) {
		return true;
	}

	if (!customUserToken) {
		return false;
	}

	const isTokenAndUserMismatch = customUserToken.userId !== currentUser.uid;

	if (isTokenAndUserMismatch) {
		return true;
	}

	return false;
};

export default shouldSignIn;
