import React from "react";
import Header from "./Header";
import Container from "./Container";
import Main from "./Main";
import Content from "./Content";

const ViewContainer = ({ header, heading, message, footer, colors, delay }) => {
	return (
		<Container colors={colors}>
			<Header delay={delay}>{header}</Header>

			<Main>
				<Content {...{ heading, message }} />
				{footer}
			</Main>
		</Container>
	);
};

export default ViewContainer;
