import { Loader } from "modules/core";
import { always, compose, cond, equals, prop } from "ramda";
import React, { useMemo } from "react";
import styled from "styled-components";
import ApplePayButton from "./ApplePayButton";
import CardButton from "./CardButton";
import SwishButton from "./SwishButton";
import TestButton from "./TestButton";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
`;

const PayButton = ({ paymentMethod, total, tip, onClick }) => {
	const button = useMemo(() => {
		if (!paymentMethod) {
			return null;
		}

		return compose(
			Button => (
				<Button onClick={onClick} {...{ paymentMethod, total, tip }} />
			),
			cond([
				[equals("APPLE_PAY"), always(ApplePayButton)],
				[equals("SWISH"), always(SwishButton)],
				[equals("CARD"), always(CardButton)],
				[equals("TEST"), always(TestButton)]
			]),
			prop("type")
		)(paymentMethod);
	}, [paymentMethod, onClick, total, tip]);

	return (
		<Container>
			{button}
			{!paymentMethod && <Loader size="small" />}
		</Container>
	);
};

export default PayButton;
