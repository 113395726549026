import { isNil, reject } from "ramda";
import urlcat from "urlcat";
import config from "../config";
import httpClient from "./http-client";

const MenuRepo = {
	getAll: ({ checkinId, storeId, ignoreSchedule = null }) =>
		httpClient.fetch(
			urlcat(
				`${config.API_BASE_URL}/menus`,
				reject(isNil, { checkinId, storeId, ignoreSchedule })
			)
		),

	getProductOptions: ({ menuId, menuItemId }) =>
		httpClient.fetch(
			`${config.API_BASE_URL}/menus/${menuId}/items/${menuItemId}`
		)
};

export default MenuRepo;
