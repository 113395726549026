import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe
} from "@stripe/react-stripe-js";
import { Modal } from "modules/core";
import React, { useCallback, useMemo, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import styled, { useTheme } from "styled-components";
import { useSwitch } from "utils";
import { addCard } from "../functions";

const Container = styled.div`
	padding: ${p => p.theme.spaceLarge} 0 ${p => p.theme.spaceMedium};
	font-size: 16px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
`;

const InputWrapper = styled.div`
	padding: ${p => p.theme.spaceMedium};
	border: 1px solid ${p => p.theme.colorBorderPrimary};
	border-radius: ${p => p.theme.spaceSmall};
`;

const Label = styled(Form.Label)`
	font-weight: 500;
`;

const useOptions = () => {
	const theme = useTheme();

	return useMemo(
		() => ({
			style: {
				base: {
					fontSize: "16px",
					color: theme.colorTextPrimary,
					fontFamily: "Helvetica, Arial, sans-serif"
				},
				invalid: {
					color: theme.colorBittersweetRed
				}
			}
		}),
		[theme]
	);
};

const AddCardModal = props => {
	const [error, setError] = useState();
	const stripe = useStripe();
	const elements = useElements();
	const options = useOptions();
	const [isLoading, startLoading, stopLoading] = useSwitch(false);

	const handleClose = useCallback(() => {
		setError();
		props.onClose();
	}, [props]);

	const handleConfirm = useCallback(async () => {
		setError();
		startLoading();

		try {
			const card = elements.getElement(CardNumberElement);
			const addedCard = await addCard({ stripe, card });
			stopLoading();
			props.onCardAdded(addedCard);
			props.onClose();
		} catch (error) {
			setError(error);
			stopLoading();
		}
	}, [elements, stripe, startLoading, stopLoading, props]);

	return (
		<Modal
			{...{
				...props,
				onClose: handleClose,
				onConfirm: handleConfirm,
				heading: "Add card",
				confirmButtonLabel: "Add",
				disabled: isLoading,
				cancelDisabled: isLoading,
				isLoading,
				backdropClassName: "add-card-modal-backdrop"
			}}
			tmpDontCloseOnConfirm
		>
			<Container>
				<Alert show={!!error} variant="danger">
					{error?.message}
				</Alert>

				<Form.Group className="mb-3">
					<Label>Card number</Label>

					<InputWrapper>
						<CardNumberElement options={options} />
					</InputWrapper>
				</Form.Group>

				<Form.Group className="mb-3">
					<Label>Expiration date</Label>
					<InputWrapper>
						<CardExpiryElement options={options} />
					</InputWrapper>
				</Form.Group>

				<Form.Group>
					<Label>CVC</Label>
					<InputWrapper>
						<CardCvcElement options={options} />
					</InputWrapper>
				</Form.Group>
			</Container>
		</Modal>
	);
};

AddCardModal.defaultProps = {
	onClose: () => {},
	onCardAdded: () => {}
};

export default AddCardModal;
