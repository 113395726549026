import React from "react";
import LoaderView from "./LoaderView";
import useUserAuthToken from "./use-user-auth-token";
import { useGlobalState } from "state";
import { UserErrorScreen, SessionExpiredScreen } from "modules/error";

const AuthProvider = ({ children }) => {
	const { isUserResolved, userError } = useGlobalState();
	const token = useUserAuthToken();

	if (userError?.message === "custom token expired") {
		return <SessionExpiredScreen />;
	}

	if (userError) {
		return <UserErrorScreen />;
	}

	if (!isUserResolved || !token) {
		return <LoaderView />;
	}

	return children;
};

export default AuthProvider;
