import { curry, eqProps } from "ramda";
import isSameSection from "./is-same-section";

const isHighlighted = curry(({ sectionToHighlight, navigatingToRef }, item) => {
	const { section } = item;
	const isSameAsHighlightSection =
		sectionToHighlight && isSameSection(section, sectionToHighlight);
	const isSameAsNavigatingToIfExits = navigatingToRef.current
		? eqProps("menuSectionId", navigatingToRef.current, sectionToHighlight)
		: true;

	if (!isSameAsHighlightSection) {
		return false;
	}

	if (!isSameAsNavigatingToIfExits) {
		return false;
	}

	return true;
});

export default isHighlighted;
