import { authRepo } from "data";
import {
	getAuth,
	signInAnonymously,
	signInWithCustomToken
} from "firebase/auth";

const auth = getAuth();

const signIn = async (args = {}) => {
	const { customUserToken } = args;

	if (customUserToken?.expired) {
		throw new Error("custom token expired");
	}

	const { user } = customUserToken
		? await signInWithCustomToken(auth, customUserToken.customToken)
		: await signInAnonymously(auth);
	await authRepo.registerUser();
	return user;
};

export default signIn;
