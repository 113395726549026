import swishLogoSvg from "assets/images/swish-logo-secondary-light-bg.svg";
import { PlainButton } from "modules/core";
import React from "react";
import styled from "styled-components";

const Button = styled(PlainButton)`
	background: ${p => p.theme.colorDirtyWhite};
	border: 1px solid ${p => p.theme.colorSoftGray};
	display: block;
	width: 100%;
	height: 52px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-tap-highlight-color: transparent;
`;

const Logo = styled.img`
	height: 34px;
	width: auto;
	user-select: none;
`;

const SwishButton = ({ onClick }) => (
	<Button onClick={onClick}>
		<Logo src={swishLogoSvg} />
	</Button>
);

export default SwishButton;
