import styled from "styled-components";

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ButtonContainer = styled.div`
	display: ${p => (p.isHidden ? "none" : "block")};
	flex-grow: 1;
`;

const LoaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 0;
`;

export { Container, ButtonContainer, LoaderContainer };
