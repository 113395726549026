import { route, useNavigation } from "modules/navigation";
import React, { useMemo } from "react";
import styled from "styled-components";
import CloseButton from "../CloseButton";
import ReceiptButton from "./ReceiptButton";

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${p => p.theme.spaceLarge};
`;

const Footer = ({ order, callbackSuccess }) => {
	const { navigate, buildUrl } = useNavigation();

	const callbackUrl = useMemo(
		() =>
			order &&
			buildUrl(route.checkoutSuccess, { orderId: order?.orderId }),
		[buildUrl, order]
	);

	const handleCloseButtonClick = () =>
		navigate(route.CUSTOM, { url: callbackSuccess, uriEncoded: true });

	return (
		<Container>
			<ReceiptButton {...{ order, callbackUrl }} />
			<CloseButton onClick={handleCloseButtonClick} />
		</Container>
	);
};

export default Footer;
