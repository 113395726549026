import { always, cond, equals } from "ramda";
import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import theme from "styles/theme";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 250px;
	background: ${p =>
		cond([
			[equals("green"), always(theme.colorEmeraldGreen)],
			[equals("purple"), always(theme.colorLightSlatePurple)],
			[equals("red"), always(theme.colorBitterSweetRed)]
		])(p.color)};

	@media ${mediaQuery(device.tablet)} {
		min-height: 340px;
	}
`;

const Header = ({ children, color }) => {
	return <Container color={color}>{children}</Container>;
};

export default Header;
