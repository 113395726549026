import { EmptyState as CoreEmptyState } from "modules/core";
import React from "react";
import styled from "styled-components";

const EmptyState = styled(CoreEmptyState)`
	padding-top: ${p => p.theme.spaceGiant};
	margin-top: ${p => p.theme.spaceGiant};
`;

const InactiveState = () => {
	return <EmptyState label="Inactive QR-code / link" icon="sleep" />;
};

export default InactiveState;
