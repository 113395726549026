import { useCallback, useLayoutEffect, useState } from "react";

const useDimensions = () => {
	const [ref, setRef] = useState(null);
	const [size, setSize] = useState({ width: 0, height: 0 });

	const handleSize = useCallback(() => {
		setSize({
			width: ref?.offsetWidth || 0,
			height: ref?.offsetHeight || 0
		});
	}, [ref?.offsetHeight, ref?.offsetWidth]);

	useLayoutEffect(() => {
		handleSize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref?.offsetHeight, ref?.offsetWidth]);

	return [setRef, size];
};

export default useDimensions;
