import { Button } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import React, { useCallback, useMemo } from "react";
import { useGlobalState } from "state";
import { Label } from "./CartButton.styles";
import Icon from "./Icon";
import Total from "./Total";

const CartButton = ({
	children = "View Cart",
	menuId,
	menuCallbackUrl,
	checkinId,
	icon,
	leftIcon,
	size
}) => {
	const { cart, isCartLoading } = useGlobalState();
	const { navigate } = useNavigation();
	const iconComp = useMemo(() => () => <Icon size={size} />, [size]);

	const handleViewCartButtonClick = useCallback(
		() =>
			navigate(route.menuCart, {
				cartId: cart?.cartId,
				menuId,
				menuCallbackUrl,
				checkinId
			}),
		[navigate, menuId, cart, menuCallbackUrl, checkinId]
	);

	return (
		<Button
			size={size}
			icon={icon && iconComp}
			leftIcon={leftIcon && iconComp}
			isLoading={isCartLoading}
			onClick={handleViewCartButtonClick}
		>
			<Label>
				{children}
				{cart?.total > 0 && <Total />}
			</Label>
		</Button>
	);
};

export default CartButton;
