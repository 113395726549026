import styled from "styled-components";

const LoaderContainer = styled.div`
	height: 20px; // Height of loader
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default LoaderContainer;
