import { is } from "ramda";
import React, { forwardRef, useMemo } from "react";
import theme from "styles/theme";
import Icon from "../Icon";
import {
	Container,
	Content,
	LeftIconContainer,
	IconContainer,
	Text
} from "./Button.styles";
import Loader from "./Loader";

const defaultProps = { color: "green", size: "medium", onClick: () => {} };

const Button = (
	{
		isLoading,
		icon,
		iconColor,
		leftIcon,
		leftIconColor,
		children,
		className,
		size,
		color,
		onClick,
		disabled
	},
	ref
) => {
	const buttonProps = useMemo(
		() => ({
			...defaultProps,
			onClick: e => !disabled && onClick(e),
			...(disabled && { disabled }),
			...(size && { size }),
			...(color && { color }),
			...(className && { className }),
			ref
		}),
		[disabled, size, color, className, ref, onClick]
	);
	const iconProps = useMemo(
		() => ({ size: buttonProps.size }),
		[buttonProps]
	);

	const leftIconComp = useMemo(() => {
		if (!leftIcon) {
			return null;
		}

		if (is(String, leftIcon)) {
			return (
				<Icon
					name={leftIcon}
					size={24}
					color={leftIconColor || theme.colorIconPrimary}
				/>
			);
		}

		return leftIcon();
	}, [leftIcon, leftIconColor]);

	const iconComp = useMemo(() => {
		if (!icon) {
			return null;
		}

		if (is(String, icon)) {
			return (
				<Icon
					name={icon}
					size={24}
					color={iconColor || theme.colorIconPrimary}
				/>
			);
		}

		return icon();
	}, [icon, iconColor]);

	const content = useMemo(
		() => (
			<Content isLoading={isLoading} size={buttonProps.size}>
				{leftIcon && (
					<LeftIconContainer {...iconProps}>
						{leftIconComp}
					</LeftIconContainer>
				)}

				{icon && <IconContainer>{iconComp}</IconContainer>}

				<Text>{children}</Text>
			</Content>
		),
		[
			children,
			leftIcon,
			iconProps,
			leftIconComp,
			iconComp,
			icon,
			isLoading,
			buttonProps
		]
	);

	return (
		<Container {...buttonProps}>
			{content}
			<Loader isLoading={isLoading} buttonSize={buttonProps.size} />
		</Container>
	);
};

export default forwardRef(Button);
