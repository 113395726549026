import { complement, compose, curry, isEmpty, reject } from "ramda";
import filterUnpaidFlatItemsClaimedByOtherUsers from "./filter-unpaid-flat-items-claimed-by-other-users";
import isClaimExpired from "./is-claim-expired";

const isItemClaimedByOtherUsers = curry((userId, itemflatItems) =>
	compose(
		complement(isEmpty),
		reject(isClaimExpired),
		filterUnpaidFlatItemsClaimedByOtherUsers(userId)
	)(itemflatItems)
);

export default isItemClaimedByOtherUsers;
