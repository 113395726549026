import { curry } from "ramda";
import isSameSection from "./is-same-section";

const navigateToSection = curry(
	({ navigations, navigatingToRef, highlightedSection }, item) => {
		const { section } = item;
		const isAlreadyHighlighted = isSameSection(section, highlightedSection);

		if (!navigations) {
			return;
		}

		if (!isAlreadyHighlighted) {
			navigatingToRef.current = section;
		}

		navigations.next(section);
	}
);

export default navigateToSection;
