import { always, cond, equals } from "ramda";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	background: ${p =>
		cond([
			[equals("green"), always(p.theme.colorEmeraldGreen)],
			[equals("black"), always(p.theme.colorBlack)]
		])(p.color)};
	padding: 0 ${p => p.theme.spaceSmall};
	border-radius: 20px;
	display: flex;
	align-items: center;
	height: 24px;
`;

const Label = styled.div`
	font-size: ${p => (p.size === "large" ? "15px" : "11.5px")};
	font-weight: ${p => (p.strong ? "500" : "normal")};
	color: ${p => p.theme.colorTextWhite};
	line-height: 24px;
	margin: 0 ${p => p.theme.spaceTiny};
`;

const Badge = ({ children, size, strong, color }) => {
	return (
		<Container color={color}>
			<Label size={size} strong={strong}>
				{children}
			</Label>
		</Container>
	);
};

Badge.defaultProps = {
	color: "black"
};

export default Badge;
