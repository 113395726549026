import React from "react";
import { Modal } from "modules/core";

const RemoveModal = ({
	paymentMethod,
	isOpen,
	onClose,
	onConfirm,
	isLoading
}) => {
	return (
		<Modal
			{...{
				isOpen,
				onClose,
				onConfirm,
				heading: "Remove card",
				confirmButtonLabel: "Remove",
				disabled: isLoading,
				cancelDisabled: isLoading,
				isLoading
			}}
			tmpDontCloseOnConfirm
		>
			Do you want to remove {paymentMethod.label}?
		</Modal>
	);
};

export default RemoveModal;
