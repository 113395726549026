import { pick } from "ramda";
import config from "../config";
import httpClient from "./http-client";

const contactChannelRepo = {
	getByStoreId: ({ storeId }) =>
		httpClient.fetch(
			`${config.API_BASE_URL}/stores/${storeId}/contact-channels`,
			{ method: "GET" }
		),
	getByUser: () =>
		httpClient.fetch(`${config.API_BASE_URL}/users/contact-channels`, {
			method: "GET"
		}),
	save: args => {
		const body = pick(["type", "identifier", "consented"], args);
		return httpClient.fetch(
			`${config.API_BASE_URL}/users/contact-channels`,
			{
				method: "PUT",
				body: JSON.stringify(body, null, 2)
			}
		);
	},
	delete: contactChannelId =>
		httpClient.fetch(
			`${config.API_BASE_URL}/users/contact-channels/${contactChannelId}`,
			{ method: "DELETE" }
		)
};

export default contactChannelRepo;
