const replaceUrl = url => {
	if (!url) {
		throw new Error("url is required");
	}

	/* eslint-disable no-restricted-globals */
	window.history && history.replaceState
		? window.location.replace(url)
		: location.replace(url);
};

export default replaceUrl;
