import styled from "styled-components";

const Container = styled.div`
	align-self: center;
	display: flex;
	align-items: center;
	padding: ${p => p.theme.spaceMini};
	border: 1px solid;
	border-color: ${p => p.theme.colorBorderPrimary};
	border-radius: 30px;
	user-select: none;
`;

const Quantity = styled.span`
	margin: 0 ${p => p.theme.spaceMini};
	font-size: 14px;
	line-height: 2;
	width: 32px;
	text-align: center;
	font-weight: 500;
	user-select: none;
`;

export { Container, Quantity };
