import React from "react";
import { Tile, Icon, Body, Label, Description } from "./Item.styles";
import TabBadge from "./TabBadge";

const Item = ({
	label,
	icon,
	iconPack,
	description,
	onClick,
	type,
	checkinId
}) => {
	return (
		<Tile onClick={onClick}>
			<Icon name={icon} size={24} pack={iconPack} />

			<Body>
				<Label>{label}</Label>
				<Description>{description}</Description>
			</Body>

			{type === "tab" && <TabBadge checkinId={checkinId} />}
		</Tile>
	);
};

export default Item;
