import { Button as CoreButton } from "modules/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	align-items: center;
	line-height: 1;
`;

const Divider = styled.div`
	margin-left: ${p => p.theme.spaceSmall};
	margin-right: ${p => p.theme.spaceSmall};
	color: ${p => p.theme.colorSoftGray};
`;

const Button = styled(CoreButton)`
	height: 52px;
`;

const CardButton = ({ onClick, paymentMethod }) => {
	const { shortLabel } = paymentMethod || {};

	return (
		<Button size="medium" onClick={onClick}>
			<Container>
				Pay <Divider>|</Divider> {shortLabel}
			</Container>
		</Button>
	);
};

export default CardButton;
