import { Items, LoadingScreen } from "modules/core";
import { compose } from "ramda";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import Footer from "./Footer";
import Header from "./Header";
import mapOrderItemsToDisplayItems from "./map-order-items-to-display-items";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	@media ${mediaQuery(device.tablet)} {
		min-height: 300px;
	}
`;

const Body = styled.div`
	flex: 1;
`;

const Receipt = ({ order }) => {
	const [displayItems, setDisplayItems] = useState([]);

	useEffect(() => {
		if (!order) {
			return;
		}

		compose(setDisplayItems, mapOrderItemsToDisplayItems)(order);
	}, [order]);

	if (!order) {
		return (
			<Container>
				<LoadingScreen />
			</Container>
		);
	}

	return (
		<Container>
			<Header order={order} />

			<Body>
				<Items items={displayItems} />
			</Body>

			<Footer order={order} />
		</Container>
	);
};

export default Receipt;
