import { authRepo } from "data";
import createOrder from "./create-order";
import createPayment from "./create-payment";
import paymentCallbackUrl from "./payment-callback-url";

const checkout = async ({
	tabId,
	cartId,
	tip,
	paymentProvider,
	paymentProviderArgs,
	deliveryMethod,
	callbackSuccess,
	callbackError,
	callbackCancel
}) => {
	const [order, { customUserTokenId }] = await Promise.all([
		createOrder({ tabId, cartId, deliveryMethod }),
		authRepo.createCustomUserToken()
	]);
	const { orderId } = order;

	const callbackUrl = paymentCallbackUrl({
		orderId,
		customUserTokenId,
		callbackSuccess,
		callbackError,
		callbackCancel
	});

	const payment = await createPayment({
		orderId,
		tip,
		provider: paymentProvider,
		providerArgs: paymentProviderArgs,
		callbackUrl
	});

	return { order, payment };
};

export default checkout;
