import { always, anyPass, compose, is, isNil, prop } from "ramda";
import findItemByProductIdOrBarcode from "./find-item-by-product-id-or-barcode";

/**
 * @param { items, productId, barcode, note } args
 */
const shouldCreateNewItem = ({ items, productId, barcode, note }) =>
	compose(
		anyPass([isNil, always(!!note), compose(is(String), prop("note"))]),
		findItemByProductIdOrBarcode
	)({ items, productId, barcode, note });

export default shouldCreateNewItem;
