import { always, cond, equals, T } from "ramda";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";
import accountIcon from "assets/icons/account-linear-black.svg";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${p => `${p.size}px`};
	width: ${p => `${p.size}px`};
	user-select: none;
`;

const MaterialDesingIcon = styled.span`
	color: ${p => p.color};
	font-size: ${p => p.size}px;
	line-height: ${p => p.lineheight || 1};
	user-select: none;
`;

const PassbuyIconContainer = styled.div`
	height: ${p => p.size}px;
	width: ${p => p.size}px;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
`;

const PassbuyIcon = styled.img`
	height: ${p => p.size}px;
	width: auto;
`;

const Icon = ({ name, pack, size, color, className, lineheight, onClick }) => {
	const classes = `mdi mdi-${name}`;

	if (pack === "passbuy") {
		return cond([
			[
				equals("account"),
				always(
					<PassbuyIconContainer size={size} className={className}>
						<PassbuyIcon
							src={accountIcon}
							alt="account"
							size={size}
						/>
					</PassbuyIconContainer>
				)
			],
			[T, always(null)]
		])(name);
	}

	return (
		<Container {...{ size, className, onClick }}>
			<MaterialDesingIcon
				className={classes}
				{...{ size, color, lineheight }}
			/>
		</Container>
	);
};

Icon.defaultProps = {
	size: "medium",
	color: theme.colorTextBlack
};

export default Icon;
