import { eqProps, map } from "ramda";
import React from "react";
import styled from "styled-components";
import SimpleOption from "./SimpleOption";

const Container = styled.div`
	display: flex;
`;

const OptionButtons = ({ options, selectedOption, onOptionSelected }) => {
	const isSelected = eqProps("optionId", selectedOption || {});

	const renderOption = o => {
		const props = {
			key: o.optionId,
			option: o,
			selected: isSelected(o),
			onSelect: onOptionSelected
		};

		return <SimpleOption {...props} />;
	};

	const renderOptions = map(renderOption);

	return <Container>{renderOptions(options)}</Container>;
};

OptionButtons.defaultProps = {
	options: []
};

export default OptionButtons;
