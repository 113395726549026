import React from "react";
import styled from "styled-components";

const Outer = styled.div`
	flex: 1;
	background: ${p => p.colors[0]};
	background: linear-gradient(
		0deg,
		${p => p.colors[1]} 0%,
		${p => p.colors[0]} 30%
	);
`;

const Container = ({ colors, children }) => {
	return <Outer colors={colors}>{children}</Outer>;
};

export default Container;
