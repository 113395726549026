import { route, useNavigation } from "modules/navigation";
import { assoc, compose, map, objOf, prop, sortBy } from "ramda";
import { useMemo } from "react";
import AccountItem from "./AccountItem";
import appToItem from "./app-to-item";

const useItems = ({ apps, checkinId }) => {
	const { buildUrl, navigate } = useNavigation();
	const callbackUrl = useMemo(
		() => buildUrl(route.checkin, { checkinId }),
		[buildUrl, checkinId]
	);
	const appsSection = useMemo(
		() =>
			compose(
				assoc("id", "apps"),
				objOf("items"),
				map(app =>
					appToItem({ app, checkinId, navigate, callbackUrl })
				),
				sortBy(prop("sortOrder"))
			)(apps),
		[apps, checkinId, navigate, callbackUrl]
	);
	const accountSection = useMemo(
		() => ({
			id: "account",
			items: [AccountItem({ navigate, callbackUrl })]
		}),
		[navigate, callbackUrl]
	);

	return useMemo(
		() => [appsSection, accountSection],
		[appsSection, accountSection]
	);
};

export default useItems;
