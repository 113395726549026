import {
	compose,
	defaultTo,
	find,
	map,
	mergeLeft,
	prop,
	propEq,
	propOr,
	uniqBy
} from "ramda";

const dealToMessage = d => ({
	type: "success",
	description: d.description
});

const itemDisplayMessages = (orderItem, order) => {
	const { note, deals } = orderItem;
	const messages = propOr([], "messages", orderItem);
	const dealsMessages = compose(
		map(dealToMessage),
		// TODO: Remove this map when order item deals contains a 'description' prop
		map(dm =>
			mergeLeft(
				dm,
				find(propEq("orderDealId", dm.orderDealId), order.deals)
			)
		),
		uniqBy(prop("dealId")),
		defaultTo([])
	)(deals);

	const result = [...messages, ...dealsMessages];

	return note
		? [{ type: "note", name: "NOTE", description: note }, ...result]
		: result;
};

export default itemDisplayMessages;
