import { compose, curry, filter, propEq, reject } from "ramda";
import filterUnpaidFlatItems from "./filter-unpaid-flat-items";

const filterUnpaidFlatItemsClaimedByOtherUsers = curry((userId, flatItems) =>
	compose(
		filter(propEq("isClaimed", true)),
		reject(propEq("userIdClaimed", userId)),
		filterUnpaidFlatItems
	)(flatItems)
);

export default filterUnpaidFlatItemsClaimedByOtherUsers;
