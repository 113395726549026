import { Modal } from "react-bootstrap";
import { isBrowser } from "react-device-detect";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import View from "./View";

const Container = styled(Modal)`
	background: ${p => p.theme.colorWhite};

	@media ${mediaQuery(device.tablet)} {
		display: flex;
		background: initial;
		overflow-y: initial;
	}
`;

const ContainerInner = styled.div`
	flex-grow: 1;
	overflow-y: scroll;

	@media ${mediaQuery(device.tablet)} {
		overflow: initial;
	}
`;

const ViewOverlay = ({ children, onClose }) => {
	return (
		<Container
			fullscreen="md-down"
			animation={isBrowser}
			show={true}
			autoFocus={false}
			enforceFocus={false}
			backdropClassName="view-overlay-backdrop"
			onHide={onClose}
		>
			<ContainerInner>
				<View>{children}</View>
			</ContainerInner>
		</Container>
	);
};

export default ViewOverlay;
