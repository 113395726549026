import { compose, prop, unless } from "ramda";
import React from "react";
import Button from "./Button";

const Option = ({ option, selected, onSelect, children }) => {
	const handleClick = compose(unless(prop("disabled"), onSelect), () =>
		selected ? {} : option
	);

	return (
		<Button
			onClick={handleClick}
			selected={selected}
			disabled={option.disabled}
		>
			{children}
		</Button>
	);
};

export default Option;
