import React, { useMemo } from "react";
import { Messages, Section } from "modules/core";
import { compose, filter, isEmpty, map, prop, propEq } from "ramda";

const dealToMessage = d => ({
	type: "success",
	description: d.description
});

const Deals = ({ order }) => {
	const messages = useMemo(
		() =>
			compose(
				map(dealToMessage),
				filter(propEq("category", "CART")),
				prop("deals")
			)(order),
		[order]
	);

	if (isEmpty(messages)) {
		return null;
	}

	return (
		<Section>
			<Messages messages={messages} />
		</Section>
	);
};

export default Deals;
