import React from "react";
import ContentLoader from "react-content-loader";
import { cond, propEq } from "ramda";

const SekeletonSmall = () => (
	<ContentLoader
		speed={2}
		width="100%"
		height={36}
		viewBox={`0 0 ${36 + 8 + 180} 36`}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="44" y="2" rx="4" ry="4" width="160" height="14" />
		<rect x="44" y="23" rx="4" ry="4" width="60" height="12.5" />
		<circle cx="18" cy="18" r="18" />
	</ContentLoader>
);

const SekeletonLarge = () => (
	<ContentLoader
		speed={2}
		width="100%"
		height={55}
		viewBox={`0 0 ${55 + 8 + 180} 55`}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect x="63" y="9" rx="4" ry="4" width="180" height="18" />
		<rect x="63" y="35" rx="4" ry="4" width="80" height="14" />
		<circle cx="27.5" cy="27.5" r="27.5" />
	</ContentLoader>
);

const Skeleton = cond([
	[propEq("size", "small"), () => <SekeletonSmall />],
	[propEq("size", "large"), () => <SekeletonLarge />]
]);

export default Skeleton;
