import { tabRepo } from "data";
import pluckClaimableTabFlatItemIds from "./pluck-claimable-tab-flat-item-ids";

const claimTabItems = tab =>
	tabRepo.claimFlatItems({
		tabId: tab.tabId,
		tabFlatItemIds: pluckClaimableTabFlatItemIds(tab),
		unclaimOnCheckoutFailure: true
	});

export default claimTabItems;
