import { always, cond, equals, toLower } from "ramda";
import route from "./route";

const routes = {
	[route.checkout]: ({
		orderId,
		paymentId,
		callbackCancel,
		callbackError,
		callbackSuccess,
		customUserTokenId
	}) => {
		if (!callbackCancel || !callbackError || !callbackSuccess) {
			throw new Error("callback urls is required");
		}

		if (!orderId) {
			throw new Error("orderId is required");
		}

		if (!paymentId) {
			throw new Error("paymentId is required");
		}

		return [
			"/checkout",
			{
				orderId,
				paymentId,
				callbackCancel,
				callbackError,
				callbackSuccess,
				customUserTokenId
			}
		];
	},
	[route.checkoutError]: ({ callbackError }) => [
		"/checkout/error",
		{ callbackError }
	],
	[route.checkoutSuccess]: ({ orderId }) => {
		if (!orderId) {
			throw new Error("orderId is required");
		}

		return ["/checkout/success", { orderId }];
	},
	[route.home]: () => [
		"/",
		{
			tabId: null,
			orderId: null,
			paymentId: null,
			customUserTokenId: null
		}
	],
	[route.menu]: ({ menuId, checkinId, callbackUrl }) => {
		if (!checkinId) {
			throw new Error("checkinId is required");
		}

		const route = menuId ? `/menu/${menuId}` : `/menu`;

		return [route, { checkinId, callbackUrl }];
	},
	[route.menuCart]: ({ menuId, cartId, checkinId, menuCallbackUrl }) => {
		if (!menuId) {
			throw new Error("menuId is required");
		}

		if (!checkinId) {
			throw new Error("checkinId is required");
		}

		if (!menuCallbackUrl) {
			throw new Error("menuCallbackUrl is required");
		}

		return [
			`/menu/${menuId}/cart${cartId ? `/${cartId}` : ""}`,
			{ checkinId, menuCallbackUrl }
		];
	},
	[route.menuItem]: ({ menuId, menuItemId, callbackUrl }) => {
		if (!menuId) {
			throw new Error("menuId is required");
		}

		if (!menuItemId) {
			throw new Error("menuItemId is required");
		}

		return [`/menu/${menuId}/item/${menuItemId}`, { callbackUrl }];
	},
	[route.app]: ({ type, checkinId, eventId, callbackUrl }) => {
		type = toLower(type);

		if (!type) {
			throw new Error("type is required");
		}

		if (!checkinId) {
			throw new Error("checkinId is required");
		}

		const path = cond([
			[equals("order_and_pay"), always("/menu")],
			[equals("tab"), always("/tab")]
		])(type);

		return [path, { checkinId, eventId, callbackUrl, type: null }];
	},
	[route.receipt]: ({ orderId, callbackUrl }) => {
		if (!orderId) {
			throw new Error("orderId is required");
		}

		return [`/receipt/${orderId}`, { callbackUrl }];
	},
	[route.tab]: ({ tabId, checkinId, callbackUrl }) => {
		if (!tabId && !checkinId) {
			throw new Error("tabId or checkinId is required");
		}

		const path = tabId ? `/tab/${tabId}` : "/tab";

		return [path, { checkinId, callbackUrl }];
	},
	[route.orderProgress]: ({ orderId, callbackUrl }) => {
		if (!orderId) {
			throw new Error("orderId is required");
		}

		if (!callbackUrl) {
			throw new Error("callbackUrl is required");
		}

		return [`/order-progress/${orderId}`, { callbackUrl }];
	},
	[route.paymentMethods]: () => "/payment-methods",
	[route.account]: ({ callbackUrl }) => ["/account", { callbackUrl }],
	[route.accountOrder]: ({ orderId, callbackUrl }) => [
		`/account/orders/${orderId}`,
		{ callbackUrl }
	],
	[route.checkin]: ({ checkinId, eventId }) => {
		if (!checkinId) {
			throw new Error("checkinId is required");
		}

		return [`/checkin`, { checkinId, eventId }];
	},
	[route.marketplace]: ({ marketplaceId }) => {
		if (!marketplaceId) {
			throw new Error("marketplaceId is required");
		}

		return `/m/${marketplaceId}`;
	},
	[route.landing]: () => `/landing`,
	[route.event]: ({ eventId, checkinId }) => {
		if (!eventId) {
			throw new Error("eventId is required");
		}

		if (!checkinId) {
			throw new Error("checkinId is required");
		}

		return [
			`/event/${eventId}`,
			{
				checkinId,
				checkinLabel: null,
				checkinLabelPrefix: null,
				eventName: null,
				eventStartTime: null,
				externalEventId: null,
				storeId: null
			}
		];
	}
};

export default routes;
