import { Button, EmptyState, Loader } from "modules/core";
import React, { useState } from "react";
import ButtonContainer from "./ButtonContainer";
import Container from "./Container";
import Content from "./Content";
import LoaderContainer from "./LoaderContainer";

const ErrorState = ({ onRetry }) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = async () => {
		setIsLoading(true);
		await onRetry();
		setIsLoading(false);
	};

	if (isLoading) {
		return (
			<LoaderContainer>
				<Loader />
			</LoaderContainer>
		);
	}

	return (
		<Container>
			<Content>
				<EmptyState label="Something went wrong" icon="close-circle" />

				<ButtonContainer>
					<Button size="small" color="black" onClick={handleClick}>
						Try again
					</Button>
				</ButtonContainer>
			</Content>
		</Container>
	);
};

ErrorState.defaultProps = {
	onRetry: async () => {}
};

export default ErrorState;
