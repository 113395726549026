import queryString from "query-string";
import { useLocation } from "react-router-dom";

const UseQuery = () => {
	const location = useLocation();
	return queryString.parse(location.search, {
		arrayFormat: "bracket"
	});
};

export default UseQuery;
