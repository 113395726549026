import { CheckoutError } from "modules/checkout";
import { View, ViewContainer } from "modules/core";
import React from "react";

const CheckoutPendingView = () => {
	return (
		<View>
			<ViewContainer breakpoint="mobileLarge" panel>
				<CheckoutError />
			</ViewContainer>
		</View>
	);
};

export default CheckoutPendingView;
