import { checkinRepo } from "data";
import { useCallback, useEffect, useState } from "react";

const useCheckin = checkinId => {
	const [checkin, setCheckin] = useState();
	const [error, setError] = useState();

	const fetchCheckin = useCallback(async () => {
		if (!checkinId) {
			return;
		}

		try {
			const fetchedOrder = await checkinRepo.get(checkinId);
			setCheckin(fetchedOrder);
		} catch (e) {
			setError(e);
		}
	}, [checkinId]);

	useEffect(() => {
		fetchCheckin();
	}, [fetchCheckin]);

	return [checkin, error];
};

export default useCheckin;
