import { compose, path } from "ramda";
import React from "react";
import FormControl from "react-bootstrap/FormControl";
import styled from "styled-components";

const Container = styled.div`
	margin-bottom: ${p => p.theme.spaceHuge};
`;

const NoteInput = ({ note, onChange }) => {
	const handleChange = compose(onChange, path(["target", "value"]));

	return (
		<Container>
			<FormControl
				value={note}
				onChange={handleChange}
				type="text"
				placeholder="Add a note (e.g. no coriander)"
				maxLength={50}
			/>
		</Container>
	);
};

export default NoteInput;
