import React, { useMemo } from "react";
import Store from "./Store";
import { map } from "ramda";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	@media ${mediaQuery(device.tablet)} {
		display: grid;
		grid-template-columns: ${p => `repeat(2, minmax(0, 1fr))`};
		column-gap: ${p => p.theme.spaceLarge};
		row-gap: ${p => p.theme.spaceLarge};
	}

	@media ${mediaQuery(device.laptop)} {
		grid-template-columns: ${p => `repeat(3, minmax(0, 1fr))`};
	}

	@media ${mediaQuery(device.desktop)} {
		grid-template-columns: ${p => `repeat(4, minmax(0, 1fr))`};
		column-gap: ${p => p.theme.spaceHuge};
		row-gap: ${p => p.theme.spaceHuge};
		max-width: 2560px; // 24" at 122 PPI
	}
`;

const Stores = ({ checkins, callbackUrl }) => {
	const storeComps = useMemo(
		() =>
			map(
				checkin => (
					<Store
						checkin={checkin}
						key={checkin.checkinId}
						callbackUrl={callbackUrl}
					/>
				),
				checkins
			),
		[checkins, callbackUrl]
	);

	return <Container>{storeComps}</Container>;
};

export default Stores;
