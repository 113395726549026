import { Cart } from "modules/cart";
import { ViewOverlay } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "utils";

const CartView = () => {
	const { menuId, cartId } = useParams();
	const { checkinId, menuCallbackUrl } = useQuery();
	const { navigate, buildUrl } = useNavigation();

	const menuCartCallbackUrl = useMemo(
		() =>
			buildUrl(route.menuCart, {
				menuId,
				cartId,
				menuCallbackUrl,
				checkinId
			}),
		[buildUrl, menuId, cartId, menuCallbackUrl, checkinId]
	);

	const handleClose = useCallback(
		() => navigate(route.CUSTOM, menuCallbackUrl),
		[navigate, menuCallbackUrl]
	);

	return (
		<ViewOverlay onClose={handleClose}>
			<Cart
				{...{
					cartId,
					menuCallbackUrl,
					menuCartCallbackUrl,
					onClose: handleClose
				}}
			/>
		</ViewOverlay>
	);
};

export default CartView;
