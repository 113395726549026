import { useCheckin } from "modules/checkin";
import { View, ViewContainer } from "modules/core";
import { Tab, useTab } from "modules/tab";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "utils";

const TabView = () => {
	const { checkinId, callbackUrl } = useQuery();
	const { tabId } = useParams();
	const [tab, error, fetchTab] = useTab({ checkinId, tabId });
	const [checkin] = useCheckin(checkinId || tab?.checkinId);
	const headerProps = useMemo(
		() => ({ checkin, callbackUrl }),
		[checkin, callbackUrl]
	);

	return (
		<View>
			<ViewContainer breakpoint="mobileLarge" panel>
				<Tab {...{ tab, error, headerProps, checkinId, fetchTab }} />
			</ViewContainer>
		</View>
	);
};

export default TabView;
