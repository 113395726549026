import getSession from "./get-session";
import setUserProperties from "./set-user-properties.js";
import { getAnalytics } from "firebase/analytics";

const setupAnalytics = firebase => {
	const analytics = getAnalytics(firebase);
	const session = getSession();
	setUserProperties({
		session
	});

	return analytics;
};

export default setupAnalytics;
