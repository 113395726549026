import { Loader } from "modules/core";
import React, { useMemo } from "react";
import styled from "styled-components";
import Footer from "./Footer";

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${p => p.theme.colorDark};
`;

const View = ({ payment, onCancel }) => {
	const isFooterShowing = useMemo(() => payment?.type === "swish", [payment]);

	return (
		<Container>
			<Loader size="medium" />

			{isFooterShowing && <Footer onCancel={onCancel} />}
		</Container>
	);
};

View.defaultProps = {
	onCancel: () => {}
};

export default View;
