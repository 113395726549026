import { checkoutRepo } from "data";

const createPayment = ({
	orderId,
	tip,
	provider,
	providerArgs,
	callbackUrl
}) => {
	providerArgs.appCallbackUrl = callbackUrl;

	return checkoutRepo.createPayment({
		orderId,
		provider,
		providerArgs,
		tip
	});
};
export default createPayment;
