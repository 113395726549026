import styled from "styled-components";

const Heading = styled.h2`
	font-size: 20px;
	margin-bottom: ${p => p.theme.spaceSmall};
	font-weight: 500;
	line-height: 1;
	font-family: Peak-Semibold;
`;

export default Heading;
