import styled from "styled-components";

const SectionValue = styled.span`
	font-size: ${p => (p.size === "large" ? "17px" : "14px")};
	line-height: 1;
`;

SectionValue.defaultProps = {
	size: "small"
};

export default SectionValue;
