import { map } from "ramda";
import { useCallback } from "react";
import { addItem, refreshCart } from "../functions";
import useCart from "./use-cart";

const useAddToCart = ({ checkinId, eventId }) => {
	const { setCart, resolveCart, setCartLoading } = useCart();

	const addToCart = useCallback(
		async ({ items, item, forceNewCart }) => {
			setCartLoading(true);

			try {
				const cart = await resolveCart({
					checkinId,
					eventId,
					forceNewCart
				});
				const cartItems = items ? items : [item];
				const promises = map(
					cartItem => addItem({ cart, ...cartItem }),
					cartItems
				);

				await Promise.all(promises);
				refreshCart(setCart, setCartLoading, cart.cartId);
			} finally {
				setCartLoading(false);
			}
		},
		[checkinId, eventId, setCart, setCartLoading, resolveCart]
	);

	return addToCart;
};

export default useAddToCart;
