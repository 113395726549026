import { Section } from "modules/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StoreData = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: ${p => p.theme.spaceMedium};
`;

const OrderRef = styled.span`
	font-size: 11px;
	line-height: 1.1;
	color: ${p => p.theme.colorTextMuted};
`;

const Text = styled.span`
	font-size: 11px;
	line-height: 1.5;
	color: ${p => p.theme.colorTextMuted};
`;

const MetaData = ({ order }) => {
	const { orderId, storeAddress, storeOrgNo } = order;

	const renderStoreData = () => (
		<StoreData>
			{storeAddress && <Text>{storeAddress}</Text>}
			{storeOrgNo && <Text>Org. no. {storeOrgNo}</Text>}
		</StoreData>
	);

	return (
		<Section>
			<Container>
				{(storeAddress || storeOrgNo) && renderStoreData()}
				<OrderRef>{orderId}</OrderRef>
			</Container>
		</Section>
	);
};

export default MetaData;
