import { Icon } from "modules/core";
import React from "react";
import styled from "styled-components";

const Button = styled.div`
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
`;

const CloseButton = ({ onClose }) => {
	return (
		<Button onClick={onClose}>
			<Icon name="close" size={22} />
		</Button>
	);
};

export default CloseButton;
