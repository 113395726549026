import React from "react";
import { Route as ReactRoute } from "react-router-dom";

const Route = ({ path, Component, exact, props }) => {
	return (
		<ReactRoute path={path} exact={exact || false}>
			<Component {...props} />
		</ReactRoute>
	);
};

export default Route;
