import styled from "styled-components";

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const AddCardButtonContainer = styled.div`
	padding-top: ${p => p.theme.spaceSmall};
	padding-bottom: ${p => p.theme.spaceMedium};
`;

export { Container, AddCardButtonContainer };
