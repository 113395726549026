import { allPass, always, anyPass, find, propEq } from "ramda";

/**
 * @param { items, productId, barcode, note } args
 * @returns items
 */
const findItemByProductIdOrBarcode = ({ items, barcode, productId, note }) =>
	find(
		anyPass([
			allPass([
				always(barcode !== undefined),
				propEq("barcode", barcode),
				propEq("note", note)
			]),
			allPass([propEq("productId", productId), propEq("note", note)])
		]),
		items
	);

export default findItemByProductIdOrBarcode;
