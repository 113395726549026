import { useCheckin } from "modules/checkin";
import { Modal } from "modules/core";
import React from "react";
import { useGlobalState } from "state";

const NewCartModal = ({ checkinId, isOpen, onClose, onConfirm, onCancel }) => {
	const { cart } = useGlobalState();
	const [checkin] = useCheckin(isOpen && cart?.checkinId); // only fetch checkin when isOpen

	return (
		<Modal
			{...{
				heading: "Start a new order?",
				children: `This will remove your items from ${checkin?.brandName}`,
				closeButton: true,
				backdropClassName: "create-cart-modal-backdrop",
				isOpen,
				onClose,
				onConfirm,
				onCancel,
				isLoading: !checkin
			}}
		/>
	);
};

export default NewCartModal;
