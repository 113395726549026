import { Badge, SectionLabel } from "modules/core";
import React, { useMemo } from "react";
import styled from "styled-components";
import { formatCurrency } from "utils";

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const Total = styled(SectionLabel)``;

const OldTotal = styled(SectionLabel)`
	text-decoration: line-through;
	margin-right: ${p => p.theme.spaceMedium};
`;

const Value = ({ totalWithTip, totalBeforeDiscount, isDiscounted }) => {
	const isTipOrDiscount = useMemo(
		() => totalBeforeDiscount !== totalWithTip,
		[totalWithTip, totalBeforeDiscount]
	);
	const displayTotalWithTip = formatCurrency(totalWithTip);

	return (
		<Container>
			{isTipOrDiscount && (
				<OldTotal size="large">
					{formatCurrency(totalBeforeDiscount)}
				</OldTotal>
			)}

			{!isDiscounted && <Total size="large">{displayTotalWithTip}</Total>}
			{isDiscounted && (
				<Badge color="green" size="large" strong>
					{displayTotalWithTip}
				</Badge>
			)}
		</Container>
	);
};

export default Value;
