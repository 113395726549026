import { compose, defaultTo, filter, map, prop, sortBy } from "ramda";
import { useEffect, useMemo, useState } from "react";
import useApplePay from "../components/PayButton/ApplePayButton/use-apple-pay";
import paymentMethodToDisplayPaymentMethod from "./payment-method-to-display-payment-method";

const useDisplayPaymentMethods = paymentMethods => {
	const [displayPaymentMethods, setDisplayPaymentMethods] = useState();
	const applePayOptions = useMemo(() => ({ amount: 3 }), []);
	const { isApplePayAvailable } = useApplePay(applePayOptions);

	useEffect(() => {
		if (isApplePayAvailable === undefined) {
			return;
		}

		compose(
			setDisplayPaymentMethods,
			sortBy(prop("sortOrder")),
			filter(prop("isAvailable")),
			map(paymentMethodToDisplayPaymentMethod({ isApplePayAvailable })),
			defaultTo([])
		)(paymentMethods);
	}, [setDisplayPaymentMethods, paymentMethods, isApplePayAvailable]);

	return displayPaymentMethods;
};

export default useDisplayPaymentMethods;
