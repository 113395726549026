import React from "react";
import styled from "styled-components";
import CloseButton from "./CloseButton";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${p => p.theme.spaceMedium};
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background-color: ${p => p.theme.colorWhite};

	@media ${mediaQuery(device.tablet)} {
		position: static;
	}
`;

const Heading = styled.div`
	font-size: 18px;
	font-family: Peak-Semibold;
`;

const ReceiptViewHeader = ({ onClose }) => {
	return (
		<Container>
			<Heading>Order</Heading>
			<CloseButton onClose={onClose} />
		</Container>
	);
};

export default ReceiptViewHeader;
