import { Section, SectionLabel } from "modules/core";
import React from "react";
import Value from "./Value";

const Total = ({ total, totalWithTip, totalBeforeDiscount, isDiscounted }) => {
	return (
		<Section horizontal>
			<SectionLabel size="large">Total</SectionLabel>
			<Value
				{...{ total, isDiscounted, totalWithTip, totalBeforeDiscount }}
			/>
		</Section>
	);
};

export default Total;
