import { curry } from "ramda";
import getClaimsMessages from "./get-claims-messages";

const appendClaimMessagesToItem = curry((userId, flatItems, item) => {
	item.messages = item.messages || [];
	const claimMessages = getClaimsMessages(userId, flatItems, item);
	item.messages = [...item.messages, ...claimMessages];
	return item;
});

export default appendClaimMessagesToItem;
