import { compose, curry, length } from "ramda";
import filterUnpaidFlatItemsClaimedByOtherUsers from "./filter-unpaid-flat-items-claimed-by-other-users";

const getNumberOfUnpaidFlatItemsClaimedByOtherUsers = curry(
	(userId, flatItems) =>
		compose(
			length,
			filterUnpaidFlatItemsClaimedByOtherUsers(userId)
		)(flatItems)
);

export default getNumberOfUnpaidFlatItemsClaimedByOtherUsers;
