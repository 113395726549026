import { Icon as CoreIcon } from "modules/core";
import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import PaymentMethodIcon from "./PaymentMethodIcon";

const ItemIcon = ({ name, iconPack }) => {
	const theme = useTheme();

	const styledCoreIcon = useMemo(
		() => <CoreIcon name={name} size={24} color={theme.colorBlack} />,
		[name, theme]
	);

	const styledPaymentmethodIcon = useMemo(
		() => <PaymentMethodIcon name={name} />,
		[name]
	);

	return useMemo(
		() =>
			iconPack === "payment-methods"
				? styledPaymentmethodIcon
				: styledCoreIcon,
		[iconPack, styledPaymentmethodIcon, styledCoreIcon]
	);
};

export default ItemIcon;
