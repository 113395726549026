import accountIcon from "assets/icons/account-linear-black.svg";
import cameraIcon from "assets/icons/camera-linear-black.svg";
import cartIcon from "assets/icons/cart-linear-black.svg";
import { always, cond, equals } from "ramda";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";
import Icon from "./Icon";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Label = styled.span`
	margin-top: ${p => p.theme.spaceSmall};
	text-align: center;
	font-size: 14.5px;
`;

const CustomIcon = styled.img`
	color: ${p => p.theme.colorIconBlack};
	width: 40px;
	height: auto;
`;

const getIcon = cond([
	[equals("cart"), always(cartIcon)],
	[equals("camera"), always(cameraIcon)],
	[equals("account"), always(accountIcon)]
]);

const EmptyState = ({ label, icon, iconColor, pack, className }) => {
	const renderIcon = () =>
		pack === "passbuy" ? (
			<CustomIcon src={getIcon(icon)} alt="icon" />
		) : (
			<Icon name={icon} size={50} color={iconColor} />
		);

	return (
		<Container className={className}>
			<Content>
				{renderIcon()}
				<Label>{label}</Label>
			</Content>
		</Container>
	);
};

EmptyState.defaultProps = {
	iconColor: theme.colorIconBlack
};

export default EmptyState;
