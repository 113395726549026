import React from "react";
import styled from "styled-components";
import Loader from "./Loader";

const Screen = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100%;
`;

const LoadingScreen = () => {
	return (
		<Screen>
			<Loader fadeIn="quarter" />
		</Screen>
	);
};

export default LoadingScreen;
