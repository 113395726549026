import styled from "styled-components";

const Amount = styled.span`
	font-size: 9px;
	line-height: 1;
	white-space: nowrap;
	color: ${p =>
		p.selected ? p.theme.colorTextWhite : p.theme.colorTextPrimary};

	&:visited {
		color: ${p =>
			p.selected ? p.theme.colorTextWhite : p.theme.colorTextPrimary};
	}
`;

export default Amount;
