import React from "react";
import passbuySvg from "assets/icons/payment-methods/passbuy-black.svg";
import styled from "styled-components";
import { View } from "modules/core";

const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Img = styled.img`
	height: ${p => p.size}px;
	width: auto;
	user-select: none;
	position: relative;
	top: -16%;
`;

const LandingView = () => {
	return (
		<View>
			<Container>
				<Img src={passbuySvg} size={160} />
			</Container>
		</View>
	);
};

export default LandingView;
