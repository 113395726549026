import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const Main = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: ${p => p.theme.spaceHuge};
	border-bottom: 1px solid ${p => p.theme.colorBorderPrimary};
`;

const Body = styled.div`
	padding-bottom: 80px;
`;

const BrandCover = styled.div`
	height: 41vw;
	max-height: 350px;
	min-height: 160px;
	width: 100%;
	background: ${({ coverImage, theme }) =>
		(coverImage && `url("${coverImage.large}");`) || theme.colorSoftGray};
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin-bottom: ${p =>
		`${p.brandImageSize / 2 + 12}`}px; // 12 === theme.spaceMedium

	@media ${mediaQuery(device.tablet)} {
		max-height: 200px;
	}
`;

const BrandName = styled.h1`
	font-family: Peak-Semibold;
	font-size: 28px;
	line-height: 1;
	text-align: center;
	margin-bottom: ${p => p.theme.spaceLarge};
	padding-left: ${p => p.theme.spaceHuge};
	padding-right: ${p => p.theme.spaceHuge};
`;

const CheckinLabel = styled.div`
	font-size: 14px;
	align-self: center;
`;

export { Body, Header, Container, Main, BrandCover, BrandName, CheckinLabel };
