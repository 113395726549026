import config from "../config";
import httpClient from "./http-client";

const analyticsRepo = {
	postSession: async args => {
		return httpClient.fetch(`${config.PUBLIC_API_BASE_URL}/session`, {
			method: "POST",
			body: JSON.stringify(args)
		});
	}
};

export default analyticsRepo;
