import {
	AccountView,
	CheckinView,
	CheckoutErrorView,
	CheckoutSuccessView,
	CheckoutView,
	LandingView,
	MarketplaceView,
	MenuView,
	OrderProgressView,
	PaymentMethodsView,
	ReceiptView,
	TabView,
	HomeView,
	EventView
} from "views";

const routes = [
	{
		path: "/",
		exact: true,
		Component: HomeView
	},
	{
		path: "/tab/:tabId?",
		exact: true,
		Component: TabView
	},
	{
		path: "/checkin",
		exact: true,
		Component: CheckinView
	},
	{
		path: "/checkout",
		Component: CheckoutView,
		exact: true
	},
	{
		path: "/checkout/success",
		Component: CheckoutSuccessView,
		exact: true
	},
	{
		path: "/checkout/error",
		Component: CheckoutErrorView,
		exact: true
	},
	{
		path: "/receipt/:orderId",
		Component: ReceiptView,
		exact: true
	},
	{
		path: "/menu/:menuId?",
		Component: MenuView
	},
	{
		path: "/order-progress/:orderId",
		Component: OrderProgressView,
		exact: true
	},
	{
		path: "/payment-methods",
		Component: PaymentMethodsView,
		exact: true
	},
	{
		path: "/account",
		Component: AccountView
	},
	{
		path: "/m/:marketplaceId",
		Component: MarketplaceView,
		exact: true
	},
	{
		path: "/landing",
		Component: LandingView,
		exact: true
	},
	{
		path: "/event/:eventId",
		Component: EventView,
		exact: true
	}
];

export default routes;
