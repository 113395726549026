import { menuRepo } from "data";
import { compose } from "ramda";
import { useEffect, useState } from "react";
import { menuItem } from "../functions";

const useMenuItem = ({ menu, menuItemId }) => {
	const [item, setItem] = useState();
	const [productOptions, setProductOptions] = useState({});

	useEffect(() => {
		if (!menu) {
			return;
		}

		menuRepo
			.getProductOptions({ menuId: menu.menuId, menuItemId })
			.then(setProductOptions);

		compose(setItem, menuItem(menuItemId))(menu);
	}, [menu, menuItemId]);

	return { item, productOptions };
};

export default useMenuItem;
