import { andThen, compose, find, propEq } from "ramda";
import React, { useCallback, useMemo } from "react";
import { useGlobalState } from "state";
import { useSwitch } from "utils";
import { useDisplayPaymentMethods, usePaymentMethodItems } from "../functions";
import paymentMethodToDisplayPaymentMethod from "../functions/payment-method-to-display-payment-method";
import usePaymentMethods from "../functions/use-payment-methods";
import AddCardModal from "./AddCardModal";

const PaymentMethodsHOC = WrappedComp => {
	const Comp = ({ onClose, checkinId, ...props }) => {
		const { setPaymentMethod } = useGlobalState();
		const { paymentMethods, fetchPaymentMethods } =
			usePaymentMethods(checkinId);
		const displayPaymentMethods = useDisplayPaymentMethods(paymentMethods);
		const [isAddCardModalOpen, openAddCardModal, closeAddCardModal] =
			useSwitch(false);

		const handlePaymentMethodClick = useCallback(
			pm => {
				setPaymentMethod(pm);
				onClose();
			},
			[setPaymentMethod, onClose]
		);

		const handleCardAdded = useCallback(
			stripePm =>
				compose(
					andThen(
						compose(
							() => onClose(),
							setPaymentMethod,
							paymentMethodToDisplayPaymentMethod({}),
							find(propEq("paymentMethodId", stripePm.id))
						)
					),
					fetchPaymentMethods
				)(),
			[onClose, setPaymentMethod, fetchPaymentMethods]
		);

		const paymentMethodItems = usePaymentMethodItems({
			displayPaymentMethods,
			onClick: handlePaymentMethodClick
		});

		const addCardItem = useMemo(
			() => ({
				key: "add-card",
				icon: "credit-card-plus",
				label: "Add card",
				onClick: openAddCardModal
			}),
			[openAddCardModal]
		);

		const items = useMemo(
			() => [...paymentMethodItems, addCardItem],
			[paymentMethodItems, addCardItem]
		);

		return (
			<>
				<WrappedComp items={items} onClose={onClose} {...props} />

				<AddCardModal
					{...{
						isOpen: isAddCardModalOpen,
						onClose: closeAddCardModal,
						onCardAdded: handleCardAdded
					}}
				/>
			</>
		);
	};

	Comp.defaultProps = {
		onClose: () => {}
	};

	return Comp;
};

export default PaymentMethodsHOC;
