import { CheckoutSuccess } from "modules/checkout";
import { View, ViewContainer } from "modules/core";
import { useOrder } from "modules/order";
import React from "react";
import { useQuery } from "utils";

const CheckoutSuccessView = () => {
	const { orderId, callbackSuccess } = useQuery();
	const order = useOrder(orderId);

	return (
		<View backgroundColor="dark">
			<ViewContainer breakpoint="mobileLarge" panel>
				<CheckoutSuccess
					order={order}
					callbackSuccess={callbackSuccess}
				/>
			</ViewContainer>
		</View>
	);
};

export default CheckoutSuccessView;
