import { useStripe } from "@stripe/react-stripe-js";
import { compose, defaultTo, propOr } from "ramda";
import { useEffect, useState } from "react";

const onToken = () => {};

const usePaymentRequest = (options, onComplete) => {
	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState();
	const [isApplePayAvailable, setIsApplePayAvailable] = useState();

	useEffect(() => {
		if (!stripe) {
			setIsApplePayAvailable(false);
			return;
		}

		if (paymentRequest) {
			paymentRequest.on("token", onToken); // Breaks without this for some unknown reason
			paymentRequest.on("paymentmethod", onComplete);

			return () => {
				paymentRequest.off("token", onToken); // Breaks without this for some unknown reason
				paymentRequest.off("paymentmethod", onComplete);
			};
		}

		try {
			const request = stripe.paymentRequest(options);
			setPaymentRequest(request);
			request
				.canMakePayment()
				.then(
					compose(
						setIsApplePayAvailable,
						propOr(false, "applePay"),
						defaultTo({})
					)
				);
		} catch (error) {
			setIsApplePayAvailable(false);
		}
	}, [stripe, options, onComplete, paymentRequest]);

	useEffect(() => {
		if (paymentRequest) {
			paymentRequest.update({
				total: options.total,
				currency: options.currency
			});
		}
	}, [paymentRequest, options]);

	return { paymentRequest, isApplePayAvailable };
};

export default usePaymentRequest;
