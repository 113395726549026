import { EmptyState } from "modules/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	min-height: 300px;
`;

const EmptyCartState = () => {
	return (
		<Container>
			<EmptyState label="Your cart is empty" icon="cart" pack="passbuy" />
		</Container>
	);
};

export default EmptyCartState;
