import { curry, tap } from "ramda";
import getCart from "./get-cart";

const refreshCart = curry(async (setCart, setCartLoading, cartId) => {
	setCartLoading(true);

	return getCart({ cartId })
		.then(tap(setCart))
		.finally(() => setCartLoading(false));
});

export default refreshCart;
