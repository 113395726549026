import styled from "styled-components";
import { Heading } from "modules/core";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	padding-top: ${p => p.theme.spaceLarge};
	padding-bottom: ${p => p.theme.spaceLarge};

	&:last-child {
		padding-bottom: 0;
	}

	@media ${mediaQuery(device.tablet)} {
		padding-top: ${p => p.theme.spaceGiant};
		padding-bottom: ${p => p.theme.spaceGiant};
	}
`;

const Header = styled.header`
	margin-bottom: ${p => p.theme.spaceMedium};

	@media ${mediaQuery(device.tablet)} {
		margin-bottom: ${p => p.theme.spaceLarge};
	}
`;

const Name = styled(Heading)`
	font-size: 24px;
`;

const Description = styled.p`
	font-size: 12px;
	line-height: 1.5;
	margin-left: 1px;
	margin-top: ${p => p.theme.spaceMini};
	margin-bottom: ${p => p.theme.spaceMini};
`;

const Items = styled.div`
	@media ${mediaQuery(device.tablet)} {
		display: grid;
		grid-template-columns: ${p => `repeat(2, 1fr)`};
		column-gap: ${p => p.theme.spaceLarge};
		row-gap: ${p => p.theme.spaceLarge};
	}

	@media ${mediaQuery(device.laptop)} {
		grid-template-columns: ${p => `repeat(3, 1fr)`};
	}

	@media ${mediaQuery(device.desktop)} {
		grid-template-columns: ${p => `repeat(4, 1fr)`};
		column-gap: ${p => p.theme.spaceHuge};
		row-gap: ${p => p.theme.spaceHuge};
	}
`;

export { Container, Header, Name, Description, Items };
