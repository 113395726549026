import event from "./event";
import logEvent from "./log-event";

const logError = (error, fatal = false) =>
	logEvent(event.exception, {
		description: `[${error.message}] ${error.stack}`,
		fatal
	});

export default logError;
