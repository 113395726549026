import { EmptyState } from "modules/core";
import React from "react";
import theme from "styles/theme";

const PaidState = () => (
	<EmptyState
		label="All paid-up"
		icon="check-circle"
		iconColor={theme.colorEmeraldGreen}
	/>
);

export default PaidState;
