const brandColor = {
	emeraldGreen: "#41CE83",
	grannySmithGreen: "#9FE695",
	lightSlatePurple: "#8E72F9",
	bitterSweetRed: "#FF735D",
	goldenSand: "#EFD160",
	dark: "#1D1D1D",
	black: "#000000",
	white: "#FFFFFF",
	midGray: "#666666",
	lightGray: "#999999",
	softGray: "#EEEEEE",
	dirtyWhite: "#F6F6F6"
};

const space = {
	tiny: 2,
	mini: 4,
	small: 8,
	medium: 12,
	large: 16,
	huge: 22,
	giant: 28
};

const theme = {
	// Space
	spaceTiny: `${space.tiny}px`,
	spaceMini: `${space.mini}px`,
	spaceSmall: `${space.small}px`,
	spaceMedium: `${space.medium}px`,
	spaceLarge: `${space.large}px`,
	spaceHuge: `${space.huge}px`,
	spaceGiant: `${space.giant}px`,

	spaceGutterMobile: `${space.medium}px`,
	spaceGutterTablet: `${space.large}px`,

	//Color
	colorEmeraldGreen: brandColor.emeraldGreen,
	colorGrannySmithGreen: brandColor.grannySmithGreen,
	colorLightSlatePurple: brandColor.lightSlatePurple,
	colorBitterSweetRed: brandColor.bitterSweetRed,
	colorGoldenSand: brandColor.goldenSand,
	colorDark: brandColor.dark,
	colorBlack: brandColor.black,
	colorWhite: brandColor.white,
	colorMidGray: brandColor.midGray,
	colorLightGray: brandColor.lightGray,
	colorSoftGray: brandColor.softGray,
	colorDirtyWhite: brandColor.dirtyWhite,

	// Text
	colorTextPrimary: brandColor.black,
	colorTextMuted: brandColor.midGray,
	colorTextWhite: brandColor.white,

	// Border
	colorBorderPrimary: brandColor.softGray,
	colorBorderMuted: brandColor.dirtyWhite,

	// Icon
	colorIconBlack: brandColor.black,
	colorIconMuted: brandColor.lightGray
};

export default theme;
export { space };
