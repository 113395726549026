import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Event from "./Event";

const Container = styled.div`
	position: relative;
`;

const AnimatedEventWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: ${p => (p.isActive ? 1 : 0)};
	transition: opacity 500ms ease-in-out;
`;

const AnimatedEvent = props => {
	const { status, delay, label } = props;
	const [isActive, setIsActive] = useState(false);
	const pendingLabel = isActive ? "" : label;

	useEffect(() => {
		const isActive = status !== "pending";

		if (!isActive) {
			return;
		}

		const timeOut = setTimeout(() => setIsActive(isActive), delay);
		return () => clearTimeout(timeOut);
	}, [status, delay]);

	return (
		<Container>
			<Event {...{ ...props, label: pendingLabel }} status="pending" />

			{isActive && (
				<AnimatedEventWrapper isActive={isActive}>
					<Event {...props} />
				</AnimatedEventWrapper>
			)}
		</Container>
	);
};

export default AnimatedEvent;
