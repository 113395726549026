import React from "react";
import Option from "../Option";
import Amount from "./Amount";
import Label from "./Label";

const OptionPercentage = props => {
	const { option, selected } = props;
	const { label, displayAmount } = option;

	return (
		<Option {...props}>
			<Label selected={selected}>{label}</Label>
			<Amount selected={selected}>{displayAmount}</Amount>
		</Option>
	);
};

export default OptionPercentage;
