import fetchCustomUserToken from "./fetch-custom-user-token";
import signIn from "./sign-in";
import getCurrentUser from "./get-current-user";
import shouldSignIn from "./should-sign-in";

const resolveUser = async ({ customUserTokenId }) => {
	const [currentUser, customUserToken] = await Promise.all([
		getCurrentUser(),
		fetchCustomUserToken(customUserTokenId)
	]);

	const isSigningIn = shouldSignIn({ currentUser, customUserToken });

	if (isSigningIn) await signIn({ customUserToken });
};

export default resolveUser;
