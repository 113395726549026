import config from "config";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import logPageView from "./log-page-view";

const usePageTracking = () => {
	const location = useLocation();

	useEffect(() => {
		if (!location) {
			return;
		}

		logPageView({
			page_location: `${config.APP_BASE_URL}${location.pathname}${location.search}`
		});
	}, [location]);
};

export default usePageTracking;
