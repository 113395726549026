import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50%;
`;

const Header = ({ children }) => {
	return <Container>{children}</Container>;
};

export default Header;
