import { Icon } from "modules/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	width: 32px;
	height: 32px;
	background-color: ${p => p.theme.colorDirtyWhite};
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: ${p => p.theme.colorSoftGray};
		transition: 100ms ease-in-out;
	}
`;

const Button = ({ icon, onClick }) => {
	return (
		<Container onClick={onClick}>
			<Icon name={icon} />
		</Container>
	);
};

export default Button;
