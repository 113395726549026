import { useUser } from "modules/auth";
import { dec, inc } from "ramda";
import React, { useCallback, useMemo, useState } from "react";
import { useSessionState } from "utils";

const Context = React.createContext({});

const Provider = ({ children }) => {
	const [user, isUserResolved, userError] = useUser();
	const [tipOption, setTipOption] = useSessionState("tipOption");
	const [menu, setMenu] = useState();
	const [cart, setCartState] = useSessionState("cart", undefined);
	const [isCartLoadingCounter, setIsCartLoadingCounter] = useState(0);
	const isCartLoading = useMemo(
		() => isCartLoadingCounter > 0,
		[isCartLoadingCounter]
	);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [paymentMethod, setPaymentMethod] = useSessionState("paymentMethod");
	/* eslint-disable react-hooks/exhaustive-deps */
	const setCart = useCallback(cart => setCartState(cart), []);

	const resetCheckoutState = useCallback(() => {
		setCart();
		setTipOption();
	}, [setCart]);

	const setCartLoading = useCallback(val =>
		setIsCartLoadingCounter(val ? inc : dec)
	);

	return (
		<Context.Provider
			value={{
				user,
				isUserResolved,
				userError,
				tip: tipOption?.amount,
				tipOption,
				setTipOption,
				menu,
				setMenu,
				cart,
				setCart,
				isCartLoading,
				setCartLoading,
				paymentMethod,
				setPaymentMethod,
				paymentMethods,
				setPaymentMethods,
				resetCheckoutState
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Context;
export { Provider };
