import { paymentMethodRepo } from "data";

const addCard = async ({ stripe, card }) => {
	const { paymentMethod, error } = await stripe.createPaymentMethod({
		type: "card",
		card
	});

	if (error) {
		throw new Error(error.message);
	}

	try {
		return await paymentMethodRepo.registerPaymentMethod(paymentMethod);
	} catch (error) {
		throw new Error("Something went wrong");
	}
};

export default addCard;
