import React from "react";
import styled from "styled-components";
import { always, cond, equals } from "ramda";
import Icon from "./Icon";

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 9px rgba(0, 0, 0, 0.1);
	background-color: ${p => p.theme.colorWhite};
	border: 1px solid transparent;
	border-radius: ${p => p.diameter}px;
	width: ${p => p.diameter}px;
	height: ${p => p.diameter}px;
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;

	&:hover {
		background-color: ${p => p.theme.colorDirtyWhite};
		border-color: ${p => p.theme.colorBorderPrimary};
		transition: 100ms ease-in-out;
	}
`;

const RoundButton = ({ icon, size, onClick }) => {
	const diameter = cond([
		[equals("small"), always(35)],
		[equals("medium"), always(50)],
		[equals("large"), always(70)]
	])(size);

	const iconSize = cond([
		[equals("small"), always(17)],
		[equals("medium"), always(24)],
		[equals("large"), always(26)]
	])(size);

	const iconLineHeight = cond([
		[equals("small"), always(35)],
		[equals("medium"), always(50)],
		[equals("large"), always(70)]
	])(size);

	return (
		<Button diameter={diameter} onClick={onClick}>
			<Icon name={icon} size={iconSize} lineHeight={iconLineHeight} />
		</Button>
	);
};

RoundButton.defaultProps = {
	size: "medium",
	onClick: () => {}
};

export default RoundButton;
