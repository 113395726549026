import { rgba } from "polished";
import styled from "styled-components";

const fontSize = 12;
const lineHeight = 1.4 * 12;
const expandButtonCircumference = 28;
const expandButtonRadius = expandButtonCircumference / 2;

const Container = styled.div`
	display: flex;
	min-width: 0;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
	padding: 0 ${p => p.theme.spaceLarge};
`;

const Text = styled.div`
	overflow: hidden;
	font-size: ${fontSize}px;
	line-height: ${lineHeight}px;
	padding-bottom: ${p => (p.expandable ? p.theme.spaceMedium : 0)};
	text-align: ${p => !p.expandable && "center"};
	position: relative;
	white-space: pre-line;
	max-height: ${({ isOpen }) => (isOpen ? "999px" : `${lineHeight * 3}px`)};
	transition: max-height 500ms ease-in-out;
	margin-top: ${p => p.theme.spaceLarge};
`;

const Overlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: ${p =>
		`linear-gradient(0deg, #fff 0%, ${rgba(p.theme.colorWhite, 0)} 70%)`};
	opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
	transition: opacity 500ms ease-in-out;
`;

const Button = styled.div`
	width: ${expandButtonCircumference}px;
	height: ${expandButtonCircumference}px;
	background: ${p => p.theme.colorWhite};
	border: 1px solid ${p => p.theme.colorBorderPrimary};
	border-radius: ${expandButtonRadius}px;
	position: absolute;
	z-index: 999;
	bottom: -${expandButtonRadius}px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ButtonIcon = styled.div`
	font-size: ${expandButtonCircumference - 2}px;
	line-height: ${expandButtonCircumference}px;
	width: ${expandButtonCircumference}px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	left: 0.4px;
	top: ${p => (p.isOpen ? "-1px" : "1px")};
	color: ${p => p.theme.colorIconMuted};
	transform: ${p => (p.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
	transition: all 250ms ease-in-out;
	transition-delay: 500ms;
`;

export { Container, Text, Overlay, Button, ButtonIcon };
