import { always, cond, not } from "ramda";

const getIssue = ({
	isCheckoutable,
	deliveryMethods,
	deliveryMethod,
	isCartLoading,
	requiresTimeSlot,
	eventTimeSlot
}) =>
	cond([
		[
			always(isCartLoading),
			always({
				heading: "Updating cart...",
				description: "The cart is updating. Please try again."
			})
		],

		[
			always(requiresTimeSlot && !eventTimeSlot),
			always({
				heading: "Time slot",
				description: "There's no time slot choosen, please select one."
			})
		],
		[
			always(deliveryMethods && !deliveryMethod),
			always({
				heading: "Delivery method",
				description:
					"There's no delivery method choosen, please select one."
			})
		],
		[
			always(not(isCheckoutable)),
			always({
				heading: "Cart issues",
				description:
					"There's some issues with your cart that you need to resolve."
			})
		]
	])();

export default getIssue;
