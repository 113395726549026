import { formatCurrency } from "utils";
import currency from "currency.js";

const getTotalPrice = ({ productPrice, productOptionsItems, quantity }) => {
	let price = currency(productPrice);

	if (productOptionsItems) {
		for (const productOptionsItem of productOptionsItems) {
			price = price.add(productOptionsItem.priceAfterDiscount);
		}
	}

	return formatCurrency(price.multiply(quantity).value, {
		toFixed: 0,
		minimumFractionDigits: 0
	});
};

const Price = props => getTotalPrice(props);

export default Price;
