import { PlainButton } from "modules/core";
import { darken } from "polished";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Button = styled(PlainButton)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: ${p => p.theme.spaceMini};
	border: 1px solid ${p => p.theme.colorBorderPrimary};
	padding-top: ${p => p.theme.spaceSmall};
	padding-bottom: ${p => p.theme.spaceSmall};
	margin-left: ${p => p.theme.spaceMini};
	width: 56px;

	&:hover {
		background-color: ${p => p.theme.colorDirtyWhite};
		transition: 100ms ease-in-out;
	}

	@media ${mediaQuery(device.mobileMedium)} {
		width: 60px;
		margin-left: ${p => p.theme.spaceSmall};
	}
`;

const ButtonSelected = styled(Button)`
	background-color: ${p => p.theme.colorEmeraldGreen};
	border-color: ${p => p.theme.colorEmeraldGreen};

	&:hover {
		background-color: ${p => darken("0.05", p.theme.colorEmeraldGreen)};
	}
`;

const Component = props =>
	props.selected ? <ButtonSelected {...props} /> : <Button {...props} />;

export default Component;
