import { eventRepo } from "data";
import { route, useNavigation } from "modules/navigation";
import React, { useCallback } from "react";
import { useEffectOnce, useQuery } from "utils";

const HomeView = () => {
	const {
		marketplaceId,
		checkinId,
		checkinLabel,
		eventName,
		eventStartTime,
		externalEventId,
		storeId
	} = useQuery();
	const { navigate } = useNavigation();

	const redirect = useCallback(async () => {
		if (externalEventId) {
			const { checkin, event } = await eventRepo.createOrUpdateEvent({
				storeId,
				externalId: externalEventId,
				name: eventName,
				startTime: eventStartTime,
				checkinLabel
			});

			navigate(route.event, {
				checkinId: checkin.checkinId,
				eventId: event.eventId
			});

			return;
		}

		if (marketplaceId) {
			navigate(route.marketplace, { marketplaceId });
			return;
		}

		if (checkinId) {
			navigate(route.checkin, { checkinId });
			return;
		}

		navigate(route.landing);
	}, [
		navigate,
		marketplaceId,
		checkinLabel,
		externalEventId,
		checkinId,
		storeId,
		eventName,
		eventStartTime
	]);

	useEffectOnce(() => {
		redirect();
	}, [redirect]);

	return <></>;
};

export default HomeView;
