import { Modal } from "modules/core";
import { compose } from "ramda";
import React, { useEffect, useState } from "react";
import getIssue from "./get-issue";

const IssuesModal = ({ isOpen, onClose, onCancel, validationProps }) => {
	const [issue, setIssue] = useState();

	// Set it to state because otherwise when cart syncing is done it clears the modal content
	useEffect(() => {
		compose(setIssue, getIssue)(validationProps);
	}, [validationProps]);

	return (
		<Modal
			{...{ isOpen, onClose, onCancel }}
			heading={issue?.heading}
			backdropClassName="cart-issues-modal-backdrop"
			confirmButtonLabel="Ok"
		>
			{issue?.description}
		</Modal>
	);
};

export default IssuesModal;
