import React from "react";
import Sheet from "react-modal-sheet";
import styled from "styled-components";

const ContentInner = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	overflow-y: scroll;
`;

const HeaderContainer = styled.div`
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;
`;

const BottomSheet = ({ isOpen, header, onClose, children }) => (
	<Sheet isOpen={isOpen} onClose={onClose}>
		<Sheet.Container>
			{!!header && (
				<HeaderContainer>
					<Sheet.Header disableDrag>{header}</Sheet.Header>
				</HeaderContainer>
			)}

			<Sheet.Content>
				<ContentInner>{children}</ContentInner>
			</Sheet.Content>
		</Sheet.Container>

		<Sheet.Backdrop />
	</Sheet>
);

BottomSheet.defaultProps = {
	isOpen: false,
	onClose: () => {}
};

export default BottomSheet;
