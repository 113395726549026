import styled from "styled-components";

const Container = styled.div`
	flex-grow: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: ${p => p.theme.spaceSmall} 0;
`;

export default Container;
