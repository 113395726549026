import { Modal } from "modules/core";
import React, { useCallback } from "react";
import "react-phone-number-input/style.css";
import styled from "styled-components";
import { useSwitch } from "utils";

const Body = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const ContactChannelModal = ({
	heading,
	isOpen,
	onClose,
	onSave,
	fetchUserContactChannels,
	children,
	disabled
}) => {
	const [isLoading, startLoading, stopLoading] = useSwitch(false);

	const save = useCallback(async () => {
		startLoading();
		try {
			await onSave();
			await fetchUserContactChannels();
			stopLoading();
			onClose();
		} catch {
			stopLoading();
		}
	}, [onSave, onClose, startLoading, stopLoading, fetchUserContactChannels]);

	return (
		<Modal
			{...{ heading, isOpen, onClose, isLoading }}
			tmpDontCloseOnConfirm // TODO: remove
			onConfirm={save}
			disabled={disabled || isLoading}
			cancelDisabled={isLoading}
			closeButton
			onCancel={onClose}
		>
			<Body>{children}</Body>
		</Modal>
	);
};

export default ContactChannelModal;
