import { compose, concat, inc, reduce, times } from "ramda";

const randomString = length => {
	if (!length) {
		throw new Error("lenght is required");
	}

	var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	const randomNumber = () => Math.floor(Math.random() * chars.length);
	const randomChar = compose(n => chars.charAt(n), randomNumber);

	return compose(reduce(concat, ""), times(randomChar), inc)(length);
};

export default randomString;
