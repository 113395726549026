import { View, ViewContainer } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import { OrderProgress, useOrder } from "modules/order";
import { usePollDelivery } from "modules/order/hooks";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "utils";

const OrderProgressView = () => {
	const { orderId } = useParams();
	const { callbackUrl } = useQuery();
	const delivery = usePollDelivery(orderId);
	const order = useOrder(orderId);
	const { navigate } = useNavigation();

	const handleClose = () =>
		navigate(route.CUSTOM, { url: callbackUrl, uriEncoded: true });

	return (
		<View>
			<ViewContainer breakpoint="tablet" panel>
				<OrderProgress
					delivery={delivery}
					order={order}
					onClose={handleClose}
				/>
			</ViewContainer>
		</View>
	);
};

export default OrderProgressView;
