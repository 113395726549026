import { useEffect, useState } from "react";

const getItemByKey = (key, initialValue) => {
	const item = window.sessionStorage.getItem(key);
	if (item === null) return initialValue;
	if (item === "undefined") return undefined;

	return JSON.parse(item);
};

const useSessionState = (key, initialValue) => {
	const [storedValue, setStoredValue] = useState(
		getItemByKey(key, initialValue)
	);

	useEffect(() => {
		setStoredValue(getItemByKey(key, initialValue));
	}, [key, initialValue]);

	useEffect(() => {
		if (!key) {
			return;
		}

		const jsonValue = JSON.stringify(storedValue);
		window.sessionStorage.setItem(key, jsonValue);
	}, [key, storedValue]);

	return [storedValue, setStoredValue];
};

export default useSessionState;
