import React from "react";
import CheckinLabelWithPrefix from "./CheckinLabelWithPrefix";
import Skeleton from "./Skeleton";
import {
	Body,
	Container,
	Image,
	ImageContainer,
	Name
} from "./StoreBadge.styles";

const StoreBadge = ({ checkin, hideLabel, size }) => {
	if (!checkin) {
		return <Skeleton size={size} />;
	}

	const { brandImage, brandName, label, labelPrefix } = checkin;
	const checkinLabelWithPrefix = `${labelPrefix} ${label}`;

	return (
		<Container>
			<ImageContainer size={size}>
				<Image src={brandImage?.small} size={size} />
			</ImageContainer>

			<Body>
				<Name size={size}>{brandName}</Name>

				{label && !hideLabel && (
					<CheckinLabelWithPrefix
						checkinLabelWithPrefix={checkinLabelWithPrefix}
						size={size}
					/>
				)}
			</Body>
		</Container>
	);
};

export default StoreBadge;
