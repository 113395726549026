import symbol from "assets/brand/symbol-white.svg";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	height: 190px;
	width: 190px;
`;

const Logo = styled.img`
	height: 190px;
	width: 190px;
`;

const Header = () => {
	return (
		<Container>
			<Logo src={symbol} />
		</Container>
	);
};

export default Header;
