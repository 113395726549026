import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import { isSameSection } from "./functions";

const useNavigationEnds = ({
	navigationEnds,
	navigatingToRef,
	setHighlightedSection,
	sectionToHighlight,
	highlightedSection
}) => {
	useDeepCompareEffect(() => {
		const onNavigationEnd = () => {
			navigatingToRef.current = null;

			/* 
		If the section to highlight is not set as highlighted when the navigation ends (user interupts scroll),
		we make sure to set it here
	*/
			if (!isSameSection(sectionToHighlight, highlightedSection)) {
				setHighlightedSection(sectionToHighlight);
			}
		};

		const sub = navigationEnds.subscribe({ next: onNavigationEnd });
		return () => sub.unsubscribe();
	}, [
		navigationEnds,
		sectionToHighlight,
		highlightedSection,
		setHighlightedSection,
		navigatingToRef
	]);
};

export default useNavigationEnds;
