import { contactChannelRepo } from "data";
import { FloatingActionButtons, Modal, Tile as CoreTile } from "modules/core";
import { always, compose, cond, defaultTo, head, propEq } from "ramda";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useSwitch, useToggle } from "utils";
import PushNotificationIcon from "./PushNotificationIcon";

const Tile = styled(CoreTile)`
	margin-top: ${p => p.theme.spaceMedium};
	margin-bottom: ${p => p.theme.spaceMedium};
`;

const Container = styled.div`
	min-height: 37.5px;
	display: flex;
	align-items: center;
	position: relative;
	flex-grow: 1;
`;

const Message = styled.div`
	font-size: 12.5px;
`;

const contactChannelToMessage = compose(
	cond([
		[propEq("type", "MESSENGER"), always("Messenger")],
		[propEq("type", "SMS"), ({ identifier }) => `SMS to ${identifier}`],
		[propEq("type", "EMAIL"), ({ identifier }) => `email to ${identifier}`]
	]),
	defaultTo({})
);

const UserActiveContactChannel = ({ userContactChannels, onChange }) => {
	const [isActions, toggleActions] = useToggle();
	const [isConfirmationModal, openConfirmationModal, closeConfirmationModal] =
		useSwitch(false);
	const activeUserContactChannel = useMemo(
		() => head(userContactChannels),
		[userContactChannels]
	);
	const contactChannelMessage = useMemo(
		() => contactChannelToMessage(activeUserContactChannel),
		[activeUserContactChannel]
	);
	const removeContactChannel = useCallback(
		() =>
			contactChannelRepo.delete(
				activeUserContactChannel.contactChannelId
			),
		[activeUserContactChannel]
	);
	const actions = useMemo(
		() => [
			{
				icon: "trash-can",
				handler: openConfirmationModal
			}
		],
		[openConfirmationModal]
	);

	const handleModalConfirm = useCallback(async () => {
		await removeContactChannel();
		onChange();
	}, [removeContactChannel, onChange]);

	return (
		<>
			<Tile clickable>
				<Container onClick={toggleActions}>
					<PushNotificationIcon />

					<Message>
						Push notifications is enabled via{" "}
						{contactChannelMessage}
					</Message>

					{isActions && <FloatingActionButtons actions={actions} />}
				</Container>
			</Tile>

			<Modal
				heading="Remove push notifications"
				isOpen={isConfirmationModal}
				onConfirm={handleModalConfirm}
				onClose={closeConfirmationModal}
				confirmButtonLabel="Remove"
			>
				Do you want to remove push notification by{" "}
				{contactChannelMessage}?
			</Modal>
		</>
	);
};

export default UserActiveContactChannel;
