import { compose, map, prop, sortBy } from "ramda";
import React, { forwardRef } from "react";
import ItemPreview from "../ItemPreview";
import { Container, Header, Name, Description, Items } from "./Section.styles";

const Section = ({ menuId, section, menuCallbackUrl }, ref) => {
	const { name, description, items } = section;

	const renderItems = compose(
		map(i => (
			<ItemPreview
				menuId={menuId}
				item={i}
				callbackUrl={menuCallbackUrl}
				key={i.product.productId}
			/>
		)),
		sortBy(prop("sortOrder"))
	);

	return (
		<Container ref={ref}>
			<Header>
				<Name>{name}</Name>
				<Description>{description}</Description>
			</Header>

			<Items>{renderItems(items)}</Items>
		</Container>
	);
};

export default forwardRef(Section);
