import { Section, SectionLabel } from "modules/core";
import React from "react";
import styled from "styled-components";
import { formatCurrency } from "utils";

const Value = styled.span`
	font-size: 14px;
	line-height: 2;
`;

const Tip = ({ tip }) => {
	if (!tip) {
		return null;
	}

	return (
		<Section slim horizontal>
			<SectionLabel>Tip</SectionLabel>

			<Value>{formatCurrency(tip)}</Value>
		</Section>
	);
};

export default Tip;
