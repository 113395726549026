import { compose, defaultTo, map } from "ramda";
import paymentMethodToItem from "./payment-method-to-item";

const usePaymentMethodItems = ({ displayPaymentMethods, onClick }) =>
	compose(
		map(paymentMethodToItem({ onClick })),
		defaultTo([])
	)(displayPaymentMethods);

export default usePaymentMethodItems;
