import { map } from "ramda";

const mapTabItemsToDisplayItems = map(i => ({
	key: i.tabItemId,
	name: i.productName,
	quantity: i.quantity,
	price: i.productPrice,
	messages: i.messages
}));

export default mapTabItemsToDisplayItems;
