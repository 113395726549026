import { LoaderOverlay } from "modules/core";
import { Delivery } from "modules/delivery";
import { TimeSlot } from "modules/event";
import { PayButton, useDefaultPaymentMethod } from "modules/payment";
import { Tip } from "modules/tips";
import { mergeRight } from "ramda";
import React, { useCallback, useMemo } from "react";
import { useGlobalState } from "state";
import { useQuery, useSwitch } from "utils";
import { useCheckout } from "../functions";
import { Buttons, Container } from "./CheckoutFooter.styles";
import IssuesModal, { getIssue } from "./IssuesModal";
import Messages from "./Messages";
import MoreButton from "./MoreButton";
import Total from "./Total";

const CheckoutFooter = ({
	total,
	totalWithTip,
	totalBeforeDiscount,
	tip,
	isDiscounted,
	deliveryDestination,
	deliveryMethod,
	deliveryMethods,
	messages,
	isCheckoutable,
	checkoutProps,
	onDeliveryMethodChanged,
	requiresTimeSlot,
	eventId,
	eventTimeSlot,
	onEventTimeSlotChanged
}) => {
	const { checkinId } = useQuery();
	const { paymentMethod, isCartLoading } = useGlobalState();
	const [isIssuesModalOpen, openIssuesModal, closeIssuesModal] = useSwitch();
	const checkout = useCheckout();
	const [isLoading, startLoading, stopLoading] = useSwitch(false);
	useDefaultPaymentMethod(checkinId);
	const validationProps = useMemo(
		() => ({
			isCheckoutable,
			isCartLoading,
			deliveryMethods,
			deliveryMethod,
			requiresTimeSlot,
			eventTimeSlot
		}),
		[
			isCheckoutable,
			isCartLoading,
			deliveryMethods,
			deliveryMethod,
			requiresTimeSlot,
			eventTimeSlot
		]
	);
	const issue = useMemo(() => getIssue(validationProps), [validationProps]);

	const onPayButtonClick = useCallback(
		async (args = {}) => {
			if (issue) {
				openIssuesModal();
				return;
			}

			try {
				startLoading();
				await checkout({
					...checkoutProps,
					paymentProvider: paymentMethod.provider,
					paymentProviderArgs: mergeRight(
						paymentMethod.providerArgs,
						args.providerArgs || {}
					)
				});
			} finally {
				stopLoading();
			}
		},
		[
			issue,
			checkoutProps,
			checkout,
			openIssuesModal,
			paymentMethod,
			startLoading,
			stopLoading
		]
	);

	return (
		<>
			<Container>
				<Delivery
					{...{
						deliveryDestination,
						deliveryMethod,
						deliveryMethods
					}}
					onChange={onDeliveryMethodChanged}
				/>
				{requiresTimeSlot && (
					<TimeSlot
						{...{
							eventId,
							checkinId,
							eventTimeSlot,
							onEventTimeSlotChanged
						}}
					/>
				)}
				<Tip total={total} />
				<Total
					{...{ totalWithTip, totalBeforeDiscount, isDiscounted }}
				/>
				<Messages messages={messages} />
				<Buttons>
					<PayButton
						onClick={onPayButtonClick}
						{...{ paymentMethod, total, tip }}
					/>

					<MoreButton checkinId={checkinId} />
				</Buttons>
			</Container>

			<IssuesModal
				{...{
					isOpen: isIssuesModalOpen,
					onClose: closeIssuesModal,
					onCancel: closeIssuesModal,
					validationProps
				}}
			/>

			<LoaderOverlay isLoading={isLoading} />
		</>
	);
};

export default CheckoutFooter;
