import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CONFIG from "config";
import React from "react";

const stripePromise = loadStripe(CONFIG.STRIPE_PUBLIC_KEY);

const StripeProvider = ({ children }) => (
	<Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeProvider;
