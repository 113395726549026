import { Button as CoreButton, Icon } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import { PaymentMethodsDropdown } from "modules/payment";
import React, { forwardRef, useCallback } from "react";
import styled from "styled-components";
import { device, displayOn } from "styles/device";

const Btn = styled(CoreButton)`
	width: 70px;
	margin-left: ${p => p.theme.spaceSmall};
`;

const Button = forwardRef(({ onClick }, ref) => {
	return (
		<Btn onClick={onClick} color="gray" ref={ref}>
			<Icon size={26} name="dots-horizontal" />
		</Btn>
	);
});

const ButtonContainer = styled.div`
	${displayOn({ max: device.tablet })}
`;

const DropdownContainer = styled.div`
	${displayOn({ min: device.tablet })}
`;

const MoreButton = ({ checkinId }) => {
	const { navigate } = useNavigation();

	const handleClick = useCallback(() => {
		navigate(route.paymentMethods);
	}, [navigate]);

	return (
		<>
			<ButtonContainer>
				<Button onClick={handleClick} color="gray">
					<Icon size={26} name="dots-horizontal" />
				</Button>
			</ButtonContainer>

			<DropdownContainer>
				<PaymentMethodsDropdown as={Button} checkinId={checkinId} />
			</DropdownContainer>
		</>
	);
};

export default MoreButton;
