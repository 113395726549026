import { defaultTo, map, propOr, compose, isEmpty } from "ramda";
import React, { useMemo } from "react";
import Section from "./Section";
import { Collapse, Section as CoreSection, SectionLabel } from "modules/core";

const ProductOptions = ({
	productOptions,
	selectedProductOptions,
	onSelectedProductOptionChanged,
	collapse
}) => {
	const sectionComps = useMemo(
		() =>
			compose(
				map(section => (
					<Section
						{...{
							key: section.menuSectionId,
							selectedProductOptions,
							onSelectedProductOptionChanged,
							section
						}}
					/>
				)),
				propOr([], "sections"),
				defaultTo({})
			)(productOptions),
		[productOptions, selectedProductOptions, onSelectedProductOptionChanged]
	);
	const content = useMemo(
		() => (
			<CoreSection>
				<SectionLabel size="large">
					Frequently bought together
				</SectionLabel>
				{sectionComps}
			</CoreSection>
		),
		[sectionComps]
	);

	if (isEmpty(sectionComps)) {
		return null;
	}

	if (collapse) {
		return <Collapse closedHeight={180}>{content}</Collapse>;
	}

	return content;
};

export default ProductOptions;
