import { compose, map, prop } from "ramda";
import { itemDisplayMessages } from "../functions";

const mapTabItemsToDisplayItems = order =>
	compose(
		map(i => ({
			key: i.orderItemId,
			name: i.product.name,
			quantity: i.quantity,
			price: i.total,
			oldPrice: i.isDiscounted && i.totalBeforeDiscount,
			messages: itemDisplayMessages(i, order)
		})),
		prop("items")
	)(order);

export default mapTabItemsToDisplayItems;
