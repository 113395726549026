import React from "react";
import { useToggle } from "utils";
import {
	Button,
	ButtonIcon,
	Container,
	Overlay,
	Content
} from "./Collapse.styles";

const Collapse = ({ children, expandable, closedHeight }) => {
	const [isOpen, toggle] = useToggle();

	return (
		<Container>
			<Content {...{ isOpen, expandable, closedHeight }}>
				{children}

				{expandable && <Overlay isOpen={isOpen} />}
			</Content>

			{expandable && (
				<Button onClick={toggle}>
					<ButtonIcon
						isOpen={isOpen}
						className="mdi mdi-chevron-down"
					/>
				</Button>
			)}
		</Container>
	);
};

Collapse.defaultProps = {
	expandable: true,
	closedHeight: 100
};

export default Collapse;
