import React, { useCallback } from "react";
import { Tile, Image, Body, BodyLeft, StoreName, Date } from "./Order.styles";
import Moment from "react-moment";
import Total from "./Total";
import { route, useNavigation } from "modules/navigation";

const Order = ({ order }) => {
	const { navigate } = useNavigation();
	const { orderId, storeImage, storeName, timeCreated } = order;

	const handleClick = useCallback(
		() =>
			navigate(route.accountOrder, {
				orderId,
				callbackUrl: window.location.pathname + window.location.search
			}),
		[orderId, navigate]
	);

	return (
		<Tile onClick={handleClick}>
			<Image src={storeImage} />

			<Body>
				<BodyLeft>
					<StoreName>{storeName}</StoreName>
					<Moment
						element={Date}
						date={timeCreated}
						format="dddd, D MMM"
						fromNowDuring={86400000}
					/>
				</BodyLeft>

				<Total order={order} />
			</Body>
		</Tile>
	);
};

export default Order;
