import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	margin: ${p => p.theme.spaceMedium};
	margin-bottom: ${p => p.theme.spaceSmall};
	overflow: hidden;
`;

const HeaderContainer = styled.div`
	margin-bottom: ${p => p.theme.spaceHuge};
`;

const TotalContainer = styled.div`
	margin-bottom: ${p => p.theme.spaceLarge};
	display: flex;
	justify-content: space-between;
`;

const ItemSkeleton = ({ y }) => (
	<ContentLoader
		speed={2}
		width="100%"
		height={32}
		viewBox={`0 0 ${window.innerWidth} 32`}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ marginBottom: 16 }}
	>
		<rect x="0" y="0" rx="4" ry="4" width="75%" height="14" />
		<rect x="0" y="18" rx="4" ry="4" width="55%" height="12" />
	</ContentLoader>
);

const TabSkeleton = () => {
	return (
		<Container>
			{/* Header */}
			<div>
				<HeaderContainer>
					<ContentLoader
						speed={2}
						width="100%"
						height={36}
						viewBox={`0 0 ${window.innerWidth} 36`}
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="44"
							y="9.5"
							rx="4"
							ry="4"
							width="150"
							height="17"
						/>
						<circle cx="18" cy="18" r="18" />
					</ContentLoader>
				</HeaderContainer>

				{/* Items */}
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
			</div>

			{/* Footer */}
			<div>
				<TotalContainer>
					<ContentLoader
						speed={2}
						width="45"
						height={16}
						viewBox="0 0 45 16"
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="0"
							y="0"
							rx="4"
							ry="4"
							width="45"
							height="16"
						/>
					</ContentLoader>

					<ContentLoader
						speed={2}
						width="85"
						height={16}
						viewBox="0 0 85 16"
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="0"
							y="0"
							rx="4"
							ry="4"
							width="85"
							height="16"
						/>
					</ContentLoader>
				</TotalContainer>

				{/* Swish Button */}
				<ContentLoader
					speed={2}
					width="100%"
					height={52}
					viewBox={`0 0 ${window.innerWidth} 52`}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<rect x="0" y="0" rx="8" ry="8" width="100%" height="52" />
				</ContentLoader>
			</div>
		</Container>
	);
};

export default TabSkeleton;
