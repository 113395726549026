import { compose, prop, always, cond, equals, mergeLeft } from "ramda";
import { route } from "modules/navigation";

const AppToItem = ({ app, checkinId, navigate, callbackUrl }) =>
	compose(
		mergeLeft({
			key: app.type,
			onClick: () => {
				navigate(route.app, {
					checkinId,
					type: app.type,
					callbackUrl
				});
			}
		}),
		cond([
			[
				equals("ORDER_AND_PAY"),
				always({
					type: "menu",
					label: "Menu",
					icon: "food-fork-drink",
					description: "Order and pay from the menu"
				})
			],
			[
				equals("TAB"),
				always({
					type: "tab",
					label: "Tab",
					icon: "receipt",
					description: "View and pay your tab",
					checkinId
				})
			]
		]),
		prop("type")
	)(app);

export default AppToItem;
