import { pick } from "ramda";

const paymentMethodToItem =
	({ onClick }) =>
	pm => ({
		...pick(["label", "icon"], pm),
		iconPack: "payment-methods",
		key: pm.paymentMethodId,
		payload: pm,
		onClick: () => onClick(pm),
		label: `Use ${pm.label}`
	});

export default paymentMethodToItem;
