import styled from "styled-components";

const Container = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`;

const Cover = styled.div`
	flex-shrink: 1;
	width: 100%;
	height: calc(100vh-16px);
	box-sizing: border-box;
	resize: horizontal;
	overflow: auto;
	position: relative;
`;

const CoverInner = styled.div`
	width: 100%;
	padding-bottom: 75%;
`;

const Image = styled.img`
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

export { Cover, CoverInner, Image, Container };
