import { compose, dec, inc, lt, when } from "ramda";
import React, { useCallback } from "react";
import Button from "./Button";
import { Container, Quantity } from "./QuantityCounter.styles";

const QuantityConunter = ({ quantity, onChange }) => {
	const handleIncrement = useCallback(
		() => compose(onChange, inc)(quantity),
		[quantity, onChange]
	);

	const handleDecrement = useCallback(
		() => when(lt(1), compose(onChange, dec))(quantity),
		[quantity, onChange]
	);

	return (
		<Container>
			<Button icon="minus" onClick={handleDecrement} />
			<Quantity>{quantity}</Quantity>
			<Button icon="plus" onClick={handleIncrement} />
		</Container>
	);
};

export default QuantityConunter;
