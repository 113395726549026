import styled from "styled-components";
import { FloatingFooter } from "modules/core";

const Container = styled.div`
	padding-top: ${p => p.theme.spaceMedium};
	padding-bottom: 77px;
`;

const Buttons = styled(FloatingFooter)`
	display: flex;
`;

export { Container, Buttons };
