import { cartRepo } from "data";
import { andThen, compose, curry, equals, pick, reject, tap } from "ramda";

const updateCart = curry((setCart, args) =>
	compose(
		andThen(tap(setCart)),
		cartRepo.update,
		reject(equals(undefined)),
		pick(["cartId", "checkinId", "deliveryMethod", "eventTimeSlotId"])
	)(args)
);

export default updateCart;
