import React from "react";
import theme from "styles/theme";
import ViewContainer from "../ViewContainer";
import Footer from "./Footer";
import Header from "./Header";

const CheckoutSuccess = ({ order, callbackSuccess }) => {
	return (
		<ViewContainer
			header={<Header />}
			heading="Success"
			message="Payment completed"
			footer={<Footer {...{ order, callbackSuccess }} />}
			colors={[theme.colorEmeraldGreen, theme.colorGrannySmithGreen]}
		/>
	);
};

export default CheckoutSuccess;
