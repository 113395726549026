import { StoreHeader } from "modules/core";
import React from "react";
import Body from "./Body";
import Container from "./Container";
import TabSkeleton from "./TabSkeleton";

const Tab = ({ tab, error, headerProps, checkinId, fetchTab }) => {
	const isSkeleton = !headerProps.checkin || (!tab && !error);

	if (isSkeleton) {
		return <TabSkeleton />;
	}

	return (
		<Container>
			<StoreHeader {...headerProps} />

			<Body {...{ error, tab, checkinId, fetchTab }} />
		</Container>
	);
};

export default Tab;
