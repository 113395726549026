import React from "react";
import styled from "styled-components";

const Img = styled.img`
	width: 100%;
	height: auto;
	border-radius: 50%;
`;

const Placeholder = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: ${p => p.theme.colorSoftGray};
`;

const Image = ({ src }) => {
	if (!src) {
		return <Placeholder />;
	}

	return <Img src={src} alt="checkin" />;
};

export default Image;
