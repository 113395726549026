import { useEffect, useState } from "react";
import useSwitch from "./use-switch";

const useFetch = fn => {
	const [data, setData] = useState();
	const [isFetching, start, stop] = useSwitch(true);

	useEffect(() => {
		if (!fn) {
			return;
		}

		start();
		fn().then(setData).finally(stop);
	}, [fn, start, stop]);

	return [data, isFetching];
};

export default useFetch;
