import { CheckoutFooter, useTotalWithTip } from "modules/checkout";
import { route, useNavigation } from "modules/navigation";
import React, { useMemo } from "react";
import { useGlobalState } from "state";
import { useQuery } from "utils";

const Footer = ({ tab, checkinId }) => {
	const { tip } = useGlobalState();
	const { buildUrl } = useNavigation();
	const { callbackUrl } = useQuery();
	const { currentUserClaimableTotal } = tab;
	const isCheckoutable = currentUserClaimableTotal !== 0;
	const totalWithTip = useTotalWithTip({
		total: currentUserClaimableTotal,
		tip
	});

	const tabUrl = useMemo(
		() => buildUrl(route.tab, { checkinId, callbackUrl }),
		[buildUrl, checkinId, callbackUrl]
	);

	const checkoutProps = useMemo(
		() => ({
			tab,
			tip,
			callbackSuccess: tabUrl,
			callbackError: tabUrl,
			callbackCancel: tabUrl
		}),
		[tab, tip, tabUrl]
	);

	return (
		<CheckoutFooter
			{...{
				total: currentUserClaimableTotal,
				totalWithTip,
				totalBeforeDiscount: currentUserClaimableTotal,
				tip
			}}
			{...{ isCheckoutable, checkoutProps, tip }}
		/>
	);
};

export default Footer;
