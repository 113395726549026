import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Outer = styled.div`
	flex: 1;
	min-height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-start;

	@media ${p => mediaQuery(`${device[p.device]}`)} {
		${p => p.panel && `padding: ${p.theme.spaceGiant};`}
	}
`;

const Inner = styled.div`
	width: 100%;
	min-height: 100%;
	margin: 0 auto;
	flex: 1;
	display: flex;
	position: relative;

	@media ${p => mediaQuery(`${device[p.device]}`)} {
		max-width: ${p => device[p.device]}px;
		${p =>
			p.panel &&
			`
			border: 1px solid ${p.theme.colorBorderMuted};
			border-radius: ${p.theme.spaceMedium};
			box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.03);
			min-height: 50vh;
			overflow: hidden;
			margin-top: 1.5%;
			margin-bottom: 1.5%; 
		`}
	}
`;

const ViewContainer = ({ children, breakpoint, panel }) => {
	return (
		<Outer device={breakpoint} panel={panel}>
			<Inner device={breakpoint} panel={panel}>
				{children}
			</Inner>
		</Outer>
	);
};

ViewContainer.defaultProps = {
	breakpoint: "tablet"
};

export default ViewContainer;
