import { checkoutRepo } from "data";
import { useCallback, useState } from "react";
import { useInterval } from "utils";

const usePollPayment = ({ active = true, paymentId, callback, interval }) => {
	const [error, setError] = useState();

	const poll = useCallback(async () => {
		if (!active) {
			return;
		}

		if (!paymentId) {
			return;
		}

		try {
			const payment = await checkoutRepo.getPayment(paymentId);
			callback(payment);
		} catch (error) {
			setError(error);
		}
	}, [paymentId, callback, active]);

	useInterval(poll, interval);

	return error;
};

export default usePollPayment;
