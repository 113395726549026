import React, { useCallback } from "react";
import styled from "styled-components";
import Item from "./Item";
import ItemIcon from "./ItemIcon";

const IconContainer = styled.div`
	width: 40px;
	height: 24px;
	margin-right: ${p => p.theme.spaceMedium};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Label = styled.div`
	color: ${p => p.theme.colorTextPrimary};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
`;

const ItemWithLabelAndIcon = ({ item, onClick }) => {
	const { label, icon, iconPack } = item;

	const handleClick = useCallback(() => onClick(item), [item, onClick]);

	return (
		<Item onClick={handleClick}>
			<IconContainer>
				<ItemIcon name={icon} iconPack={iconPack} />
			</IconContainer>

			<Label>{label}</Label>
		</Item>
	);
};

export default ItemWithLabelAndIcon;
