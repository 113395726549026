import React from "react";
import { useTheme } from "styled-components";
import Message from "./Message";
import styled from "styled-components";
import { useMemo } from "react";
import { always, compose, cond, equals, prop, T } from "ramda";

const InlineButton = styled.span`
	text-decoration: underline;
	cursor: pointer;
`;

const Divider = styled.span`
	margin: 0 ${p => p.theme.spaceSmall};
`;

const ErrorMessage = ({ onCancel, error }) => {
	const theme = useTheme();

	const message = useMemo(
		() =>
			compose(
				cond([
					[
						equals("RECEIPT_EMAIL_ALREADY_SENT"),
						always("This receipt is already sent")
					],
					[T, always("Something went wrong")]
				]),
				prop("message")
			)(error),
		[error]
	);

	return (
		<Message icon="close-circle" iconColor={theme.colorBitterSweetRed}>
			{message} <Divider>|</Divider>
			<InlineButton onClick={onCancel}>close</InlineButton>
		</Message>
	);
};

export default ErrorMessage;
