import { always, cond, equals, T } from "ramda";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";
import Icon from "../Icon";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: ${p => (p.isHead ? 0 : p.theme.spaceTiny)};
	margin-bottom: ${p => (p.isTail ? 0 : p.theme.spaceTiny)};
`;

const Description = styled.span`
	font-size: ${p => (p.size === "small" ? "11px" : "13px")};
	line-height: 1;
	flex-shrink: 1;
	position: relative;
	top: ${p => (p.size === "small" ? "0.5px" : "0px")};
`;

const IconContainer = styled.div`
	margin-right: ${p =>
		p.size === "small" ? p.theme.spaceMini : p.theme.spaceSmall};
`;

const Message = ({ message, isHead, isTail, size }) => {
	const { type } = message;

	const renderIcon = () => {
		const color = cond([
			[equals("error"), always(theme.colorBitterSweetRed)],
			[equals("success"), always(theme.colorEmeraldGreen)],
			[equals("info"), always(theme.colorLightSlatePurple)],
			[equals("note"), always(theme.colorTextMuted)]
		])(type);

		const name = cond([
			[equals("error"), always("alert-circle")],
			[equals("success"), always("check-circle")],
			[equals("info"), always("information")],
			[equals("note"), always("account-edit")]
		])(type);

		const iconSize = cond([
			[equals("small"), always(16)],
			[equals("large"), always(22)],
			[T, always(18)]
		])(size);

		return (
			<IconContainer size={size}>
				<Icon {...{ name }} size={iconSize} color={color} />
			</IconContainer>
		);
	};

	return (
		<Container {...{ isTail, isHead }}>
			{renderIcon()}
			<Description size={size}>{message.description}</Description>
		</Container>
	);
};

export default Message;
