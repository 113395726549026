import { Badge, Section } from "modules/core";
import { compose, prop } from "ramda";
import React, { useMemo } from "react";
import styled from "styled-components";
import { formatCurrency } from "utils";

const Text = styled.span`
	font-size: 17px;
	font-weight: 500;
	line-height: 1;
`;

const TotalsContainer = styled.div`
	display: flex;
	align-items: center; ;
`;

const OldTotal = styled(Text)`
	text-decoration: line-through;
	margin-right: ${p => p.theme.spaceMedium};
`;

const Total = ({ order }) => {
	const displayTotal = compose(formatCurrency, prop("total"))(order);
	const displayTotalBeforeDiscount = compose(
		formatCurrency,
		prop("totalBeforeDiscount")
	)(order);
	const isDiscounted = useMemo(
		() => displayTotalBeforeDiscount !== displayTotal,
		[displayTotalBeforeDiscount, displayTotal]
	);

	return (
		<Section horizontal>
			<Text>Total</Text>

			<TotalsContainer>
				{isDiscounted && (
					<>
						<OldTotal>{displayTotalBeforeDiscount}</OldTotal>

						<Badge color="green" size="large" strong>
							{displayTotal}
						</Badge>
					</>
				)}

				{!isDiscounted && <Text>{displayTotal}</Text>}
			</TotalsContainer>
		</Section>
	);
};

export default Total;
