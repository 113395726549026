import { checkoutRepo } from "data";
import { route, useNavigation } from "modules/navigation";
import { useOrder } from "modules/order";
import { path } from "ramda";
import React, { useCallback, useState } from "react";
import { useGlobalState } from "state";
import { useQuery, useSwitch } from "utils";
import { hasDelivery } from "../functions";
import LoadingView from "../LoadingView";
import usePollPayment from "../use-poll-payment";
import { isBrowser } from "react-device-detect";
import SwishModal from "./SwishModal";

const CheckoutPending = () => {
	const [payment, setPayment] = useState();
	const [isUsed, setIsUsed] = useState(false);
	const { resetCheckoutState } = useGlobalState();
	const { navigate } = useNavigation();
	const {
		orderId,
		paymentId,
		callbackCancel,
		callbackSuccess,
		callbackError,
		isCallback
	} = useQuery();
	const order = useOrder(orderId);
	const [isSwishModalOpen, openSwishModal] = useSwitch();

	const pollCallback = useCallback(
		async payment => {
			const { status } = payment;
			setPayment(payment);

			if (status === "AWAITING_USER_AUTHORIZATION") {
				if (isCallback) {
					return;
				}

				if (isUsed) {
					return;
				}

				const actionSwishToken = path(
					["action", "swishToken"],
					payment
				);

				if (actionSwishToken && isBrowser) {
					setIsUsed(true);
					openSwishModal();
					return;
				}

				const actionUrl = path(["action", "redirectUrl"], payment);

				if (actionUrl) {
					setIsUsed(true);
					navigate(route.CUSTOM, { url: actionUrl, mode: "replace" });
					return;
				}
			}

			if (status === "COMPLETED") {
				resetCheckoutState();

				if (hasDelivery(order)) {
					navigate(route.orderProgress, {
						orderId,
						callbackUrl: callbackSuccess
					});
					return;
				}

				navigate(route.checkoutSuccess, { orderId });
			}

			if (status === "FAILED" || status === "CANCELED") {
				navigate(route.checkoutError, { callbackError });
			}
		},
		[
			resetCheckoutState,
			order,
			orderId,
			callbackSuccess,
			isUsed,
			isCallback,
			callbackError,
			navigate,
			openSwishModal
		]
	);

	usePollPayment({
		active: !!order,
		paymentId,
		interval: 500,
		callback: pollCallback
	});

	const handleCancel = useCallback(() => {
		const { paymentId } = payment;
		checkoutRepo.cancelPayment({ paymentId });
		navigate(route.CUSTOM, { url: callbackCancel, uriEncoded: true });
	}, [payment, navigate, callbackCancel]);

	return (
		<>
			<LoadingView payment={payment} onCancel={handleCancel} />
			<SwishModal
				payment={payment}
				isOpen={isSwishModalOpen}
				onCancel={handleCancel}
			/>
		</>
	);
};

export default CheckoutPending;
