import { Providers, Container } from "modules/app";
import { usePreloadImages } from "utils";
import routes from "router/routes"; // Not exported from route/index bca circular refrences
import { RouterView } from "router";

function App() {
	usePreloadImages();

	return (
		<Providers>
			<Container>
				<RouterView routes={routes} />
			</Container>
		</Providers>
	);
}

export default App;
