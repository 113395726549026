import {
	StoreContactChannels,
	UserActiveContactChannel,
	useUserContactChannels
} from "modules/contact-channels";
import { Loader as CoreLoader, Timeline } from "modules/core";
import { any, includes, propIs } from "ramda";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Badge, Content, Framework } from "../components";
import Header from "./Header";
import Status from "./Status";
import TimelineEvents from "./TimelineEvents";

const Loader = styled(CoreLoader)`
	align-self: center;
`;

const Pickup = ({ delivery, order, checkin, onClose }) => {
	const timelineEvents = useMemo(
		() => TimelineEvents({ delivery, order }),
		[delivery, order]
	);

	const storeId = checkin.storeId;
	const [
		userContactChannels,
		fetchUserContactChannels,
		isLoadingUserContactChannels
	] = useUserContactChannels();
	const hasUserAnyContactChannel = any(
		propIs(String, "timeConsented"),
		userContactChannels
	);
	const showUserActiveContactChannel = useMemo(
		() => hasUserAnyContactChannel && !isLoadingUserContactChannels,
		[hasUserAnyContactChannel, isLoadingUserContactChannels]
	);
	const showStoreContactChannels = useMemo(
		() =>
			includes(delivery?.status, ["CREATED", "RECEIVED"]) &&
			!hasUserAnyContactChannel &&
			!isLoadingUserContactChannels,
		[
			delivery?.status,
			hasUserAnyContactChannel,
			isLoadingUserContactChannels
		]
	);
	const showTimeline = useMemo(
		() => !showStoreContactChannels && !isLoadingUserContactChannels,
		[showStoreContactChannels, isLoadingUserContactChannels]
	);

	return (
		<Framework {...{ onClose, order, checkin }}>
			<Header delivery={delivery}>
				<Badge prefix="Pickup" label={delivery?.reference} />
				<Status delivery={delivery} />
			</Header>

			<Content>
				{isLoadingUserContactChannels && <Loader size="small" />}
				{showStoreContactChannels && (
					<StoreContactChannels
						storeId={storeId}
						order={order}
						fetchUserContactChannels={fetchUserContactChannels}
					/>
				)}

				{showTimeline && <Timeline events={timelineEvents} />}

				{showUserActiveContactChannel && (
					<UserActiveContactChannel
						userContactChannels={userContactChannels}
						onChange={fetchUserContactChannels}
					/>
				)}
			</Content>
		</Framework>
	);
};

export default Pickup;
