import styled from "styled-components";

const Label = styled.span`
	font-size: 13.5px;
	font-weight: 500;
	line-height: 1;
	margin-top: 1.5px;
	color: ${p =>
		p.selected ? p.theme.colorTextWhite : p.theme.colorTextPrimary};

	&:visited {
		color: ${p =>
			p.selected ? p.theme.colorTextWhite : p.theme.colorTextPrimary};
	}
`;

export default Label;
