import { useAddToCart } from "modules/cart";
import { Button } from "modules/core";
import { compose, filter, is, map, values } from "ramda";
import React, { useCallback, useMemo } from "react";
import { useGlobalState } from "state";
import { useSwitch } from "utils";
import NewCartModal from "./NewCartModal";
import Price from "./Price";

const AddToCartButton = ({
	checkinId,
	eventId,
	cartItem,
	productPrice,
	productOptions,
	onClick
}) => {
	const { cart, isCartLoading } = useGlobalState();
	const addToCart = useAddToCart({ checkinId, eventId });
	const [isNewCartModalOpen, openNewCartModal, closeNewCartModal] =
		useSwitch();
	const productOptionsItems = useMemo(
		() => compose(filter(is(Object)), values)(productOptions),
		[productOptions]
	);
	const cartItems = useMemo(
		() => [
			cartItem,
			...map(
				menuItem => ({
					productId: menuItem.product.productId,
					quantity: cartItem.quantity
				}),
				productOptionsItems
			)
		],
		[cartItem, productOptionsItems]
	);
	const pricePops = useMemo(
		() => ({
			productPrice,
			productOptionsItems,
			quantity: cartItem.quantity
		}),
		[cartItem, productPrice, productOptionsItems]
	);

	const addItems = useCallback(
		async ({ forceNewCart } = {}) => {
			try {
				if (cart && cart.checkinId !== checkinId && !forceNewCart) {
					throw new Error("cart checkinId mismatch");
				}

				onClick();
				await addToCart({ items: cartItems, forceNewCart });
			} catch (error) {
				if (error.message === "cart checkinId mismatch") {
					openNewCartModal();
					return;
				}

				throw error;
			}
		},
		[addToCart, onClick, cartItems, openNewCartModal, cart, checkinId]
	);

	const handleModalConfirm = useCallback(
		async () => addItems({ forceNewCart: true }),
		[addItems]
	);

	const handleClick = useCallback(() => {
		addItems();
	}, [addItems]);

	return (
		<>
			<Button onClick={handleClick} isLoading={isCartLoading}>
				Add to cart - <Price {...pricePops} />
			</Button>

			<NewCartModal
				{...{
					isOpen: isNewCartModalOpen,
					onClose: closeNewCartModal,
					onCancel: closeNewCartModal,
					onConfirm: handleModalConfirm
				}}
			/>
		</>
	);
};

export default AddToCartButton;
