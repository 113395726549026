import { compose, identity, map, times } from "ramda";
import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	overflow: hidden;
`;

const PaymentMethodSkeleton = ({ last }) => (
	<ContentLoader
		speed={2}
		width="100%"
		height={48}
		viewBox={`0 0 ${window.innerWidth} 48`}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ marginBottom: last ? 0 : 8 }}
	>
		<rect x="0" y="0" rx="8" ry="8" width="100%" height="48" />
	</ContentLoader>
);

const Skeleton = ({ numPaymentMethods }) => {
	const renderMockOrder = i => (
		<PaymentMethodSkeleton key={i} last={i === numPaymentMethods - 1} />
	);
	const ordersSkeletons = compose(
		map(renderMockOrder),
		times(identity)
	)(numPaymentMethods);

	return <Container>{ordersSkeletons}</Container>;
};

export default Skeleton;
