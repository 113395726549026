import React from "react";
import styled from "styled-components";

const Container = styled.div`
	margin-left: ${p => p.theme.spaceHuge};
	height: 88px;
	width: 114.4px;
	flex-shrink: 0;
	position: relative;
	top: 3px;
	border-radius: ${p => p.theme.spaceMini};
	overflow: hidden;
`;

const Img = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const Image = ({ src }) => {
	if (!src) {
		return <Container />;
	}

	return (
		<Container>
			<Img src={src} alt="product" />
		</Container>
	);
};

export default Image;
