import ReceiptButton from "modules/checkout/CheckoutSuccess/ReceiptButton"; // TODO: fix
import { route, useNavigation } from "modules/navigation";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useQuery } from "utils";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: ${p => p.theme.spaceHuge};
`;

const Footer = ({ order }) => {
	const { buildUrl } = useNavigation();
	const { callbackSuccess } = useQuery();

	const callbackUrl = useMemo(
		() =>
			order &&
			buildUrl(route.orderProgress, {
				orderId: order?.orderId,
				callbackUrl: callbackSuccess
			}),
		[buildUrl, order, callbackSuccess]
	);

	return (
		<Container>
			<ReceiptButton {...{ order, callbackUrl }} />
		</Container>
	);
};

export default Footer;
