import { Icon as CoreIcon, Tile as CoreTile } from "modules/core";
import styled from "styled-components";

const Tile = styled(CoreTile)`
	margin-bottom: ${p => p.theme.spaceMedium};
	padding: ${p => p.theme.spaceMedium};
	cursor: pointer;

	&:hover {
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
		transition: 100ms ease-in-out;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Icon = styled(CoreIcon)`
	margin-right: ${p => p.theme.spaceMedium};
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const Label = styled.div`
	font-size: 15px;
	font-weight: 500;
	line-height: 1;
	margin-bottom: ${p => p.theme.spaceMini};
`;

const Description = styled.div`
	font-size: 12px;
	line-height: 1;
	color: ${p => p.theme.colorTextMuted};
`;

export { Tile, Icon, Body, Label, Description };
