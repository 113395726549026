import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 0;
	overflow: hidden;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${p => p.theme.spaceGiant};
	margin: ${p => p.theme.spaceMedium};
`;

const Content = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-evenly;
	align-self: center;
	padding: ${p => p.theme.spaceLarge};
`;

const ReferenceContainer = styled.div`
	margin: ${p => p.theme.spaceGiant} 0;
`;

const Timeline = styled.div`
	margin-top: ${p => p.theme.spaceHuge};
`;

const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${p => p.theme.spaceHuge};
	border: solid ${p => p.theme.colorBorderPrimary};
	border-bottom-width: 1px;
	border-top-width: 1px;
	padding: ${p => p.theme.spaceMedium};
`;

const Event = ({ i, last }) => {
	const heightFull = 100;
	const heightLast = 50;
	const height = last ? heightLast : heightFull;
	const statusDotWidth = 20;

	return (
		<ContentLoader
			speed={2}
			width="100%"
			height={height}
			viewBox={`0 0 ${window.innerWidth} ${height}`}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			style={{ position: "relative", top: i * -18 }}
		>
			<circle
				cx={statusDotWidth}
				cy={statusDotWidth}
				r={statusDotWidth}
			/>
			<rect x="50" y="12" rx="4" ry="4" width="55%" height="16" />
			{!last && <rect x="14" y="12" width="12" height={height} />}
		</ContentLoader>
	);
};

const TabSkeleton = () => {
	return (
		<Container>
			{/* Header */}
			<HeaderContainer>
				<ContentLoader
					speed={2}
					width={window.innerWidth - 32}
					height={32}
					viewBox={`0 0 ${window.innerWidth - 32} 32`}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<rect x="0" y="0" rx="4" ry="4" width="225" height="32" />
				</ContentLoader>

				<ContentLoader
					speed={2}
					width={32}
					height={32}
					viewBox={`0 0 32 32`}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<circle cx={16} cy={16} r={16} />
				</ContentLoader>
			</HeaderContainer>

			<Content>
				<ReferenceContainer>
					<ContentLoader
						speed={2}
						width="100%"
						height={50}
						viewBox={`0 0 100 50`}
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="0"
							y="0"
							rx="4"
							ry="4"
							width="100"
							height="50"
						/>
					</ContentLoader>
				</ReferenceContainer>

				<Timeline>
					<Event i={0} />
					<Event i={1} />
					<Event i={2} last />
				</Timeline>
			</Content>

			<Footer>
				<ContentLoader
					speed={2}
					width="95"
					height={17}
					viewBox={`0 0 95 17`}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ padding: 0, margin: 0 }}
				>
					<rect x="0" y="1" rx="4" ry="4" width="95" height="16" />
				</ContentLoader>

				<ContentLoader
					speed={2}
					width="75"
					height={17}
					viewBox={`0 0 75 17`}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					style={{ padding: 0, margin: 0 }}
				>
					<rect x="0" y="1" rx="4" ry="4" width="75" height="16" />
				</ContentLoader>
			</Footer>
		</Container>
	);
};

export default TabSkeleton;
