import { isEmpty } from "ramda";
import config from "../config";
import httpClient from "./http-client";

const tabRepo = {
	get: async tabId => {
		if (!tabId) {
			throw new Error("tabId is required");
		}

		return httpClient.fetch(`${config.API_BASE_URL}/tabs/${tabId}`);
	},
	getByCheckinId: async checkinId => {
		if (!checkinId) {
			throw new Error("checkinId is required");
		}

		const tabs = await httpClient.fetch(
			`${config.API_BASE_URL}/tabs?checkinId=${checkinId}`
		);

		if (isEmpty(tabs)) {
			throw new Error("Not found");
		}

		return tabs[0];
	},
	claimFlatItems: async ({
		tabId,
		tabFlatItemIds,
		unclaimOnCheckoutFailure
	}) => {
		if (!tabId) {
			throw new Error("tabId is required");
		}

		if (!Array.isArray(tabFlatItemIds)) {
			throw new Error("tabFlatItemIds is not an array");
		}

		return httpClient.fetch(
			`${config.API_BASE_URL}/tabs/${tabId}/flat-items`,
			{
				method: "PATCH",
				body: JSON.stringify({
					tabFlatItemIds,
					claim: true,
					unclaimOnCheckoutFailure
				})
			}
		);
	}
};

export default tabRepo;
