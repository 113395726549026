import { Items } from "modules/core";
import { compose, propEq, reject } from "ramda";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "state";
import styled from "styled-components";
import Footer from "./Footer";
import {
	appendClaimMessagesToItems,
	mapTabItemsToDisplayItems
} from "./functions";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
`;

const Content = ({ tab, checkinId }) => {
	const [displayItems, setDisplayItems] = useState([]);
	const { user } = useGlobalState();
	const { items, flatItems } = tab;

	useEffect(() => {
		compose(
			setDisplayItems,
			mapTabItemsToDisplayItems,
			appendClaimMessagesToItems(user.uid, flatItems),
			reject(propEq("isPaid", true))
		)(items);
	}, [items, flatItems, user]);

	return (
		<Container>
			<Items items={displayItems} />

			<Footer tab={tab} checkinId={checkinId} />
		</Container>
	);
};

export default Content;
