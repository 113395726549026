/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import { isSameSection } from "./functions";

const useHighlightSection = ({ navigatingToRef }) => {
	const [highlightedSection, setHighlightedSection] = useState();

	const setter = useCallback(
		section => {
			if (!section) {
				return;
			}

			const isAlreadyHighlighted =
				section?.menuSectionId === highlightedSection?.menuSectionId;

			if (isAlreadyHighlighted) {
				return;
			}

			const isAlreadyNavigatingToAnotherSection =
				navigatingToRef.current &&
				!isSameSection(navigatingToRef.current, section);

			if (isAlreadyNavigatingToAnotherSection) {
				return;
			}

			setHighlightedSection(section);
		},
		[highlightedSection?.menuSectionId, navigatingToRef]
	);

	return [highlightedSection, setter];
};

export default useHighlightSection;
