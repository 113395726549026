import React from "react";
import styled from "styled-components";

const Text = styled.div`
	font-size: 18px;
	color: ${p => p.theme.colorTextWhite};
	position: relative;
	top: ${p => p.theme.spaceMedium};
	user-select: none;
	margin-top: ${p => p.theme.spaceTiny};
	text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`;

const Status = ({ children }) => {
	return <Text>{children}</Text>;
};

export default Status;
