import styled from "styled-components";

const SectionLabel = styled.span`
	display: ${p => (p.vertical ? "block" : "inline")};
	margin-bottom: ${p => (p.vertical ? p.theme.spaceMedium : 0)};
	font-size: ${p => (p.size === "large" ? "17px" : "14px")};
	font-weight: 500;
	line-height: 1;
`;

SectionLabel.defaultProps = {
	size: "small",
	vertical: false
};

export default SectionLabel;
