import { darken } from "polished";
import { always, compose, cond, path, propEq, T } from "ramda";
import styled from "styled-components";
import PlainButton from "../PlainButton";

const propsToBackgroundColor = cond([
	[propEq("color", "green"), path(["theme", "colorEmeraldGreen"])],
	[propEq("color", "black"), path(["theme", "colorBlack"])],
	[propEq("color", "gray"), path(["theme", "colorDirtyWhite"])],
	[propEq("color", "white"), path(["theme", "colorWhite"])]
]);

const propsToBorderColor = cond([
	[propEq("color", "green"), path(["theme", "colorEmeraldGreen"])],
	[propEq("color", "black"), path(["theme", "colorBlack"])],
	[propEq("color", "gray"), path(["theme", "colorSoftGray"])],
	[propEq("color", "white"), path(["theme", "colorBorderPrimary"])]
]);

const propsToVerticalPadding = cond([
	[propEq("size", "mini"), path(["theme", "spaceSmall"])],
	[propEq("size", "small"), always("10px")],
	[propEq("size", "medium"), path(["theme", "spaceMedium"])]
]);

const propsToHorizontalPadding = cond([
	[propEq("size", "mini"), always("10px")],
	[propEq("size", "small"), always("10px")],
	[propEq("size", "medium"), path(["theme", "spaceMedium"])]
]);

const propsToFontSize = cond([
	[propEq("size", "mini"), always("14px")],
	[propEq("size", "small"), always("15px")],
	[propEq("size", "medium"), always("16px")]
]);

const propsToColor = cond([
	[propEq("color", "gray"), path(["theme", "colorTextPrimary"])],
	[propEq("color", "white"), path(["theme", "colorTextPrimary"])],
	[T, path(["theme", "colorTextWhite"])]
]);

const Container = styled(PlainButton)`
	position: relative;
	background: ${propsToBackgroundColor};
	border: 1px solid transparent;
	border-color: ${propsToBorderColor};
	display: block;
	width: 100%;
	padding: ${propsToVerticalPadding} ${propsToHorizontalPadding};
	-webkit-tap-highlight-color: transparent;
	color: ${propsToColor};
	opacity: ${p => (p.disabled ? 0.6 : 1)};

	&:hover,
	&:active {
		background: ${compose(darken("0.05"), propsToBackgroundColor)};
		border-color: ${compose(darken("0.05"), propsToBorderColor)};
		transition: 100ms ease-in-out;
	}
`;

const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: ${p => (p.isLoading ? "hidden" : "visible")};
`;

const LeftIconContainer = styled.div`
	position: absolute;
	top: 50%;
	left: ${propsToVerticalPadding};
	transform: ${p => `translate(-${p.theme.spaceTiny}, -50%)`};
`;

const IconContainer = styled.div`
	margin-right: ${p => p.theme.spaceSmall};
`;

const Text = styled.div`
	font-size: ${propsToFontSize};
	font-weight: 500;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
`;

export { Container, Content, LeftIconContainer, IconContainer, Text };
