import styled from "styled-components";

const Button = styled.button`
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	border: 0;
	background-color: transparent;
	border-radius: ${p => p.theme.spaceSmall};
	user-select: none;
	cursor: pointer;
`;

export default Button;
