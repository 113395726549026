import { Loader as CoreLoader, Modal } from "modules/core";
import { useNavigation } from "modules/navigation";
import { any, both, compose, propEq, propIs } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { usePoll } from "utils";
import { openMessengerBot } from "../../functions";

const Body = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: ${p => p.theme.spaceHuge};
	padding-bottom: ${p => p.theme.spaceSmall};
`;

const Lead = styled.div`
	font-size: 13px;
`;

const Loader = styled(CoreLoader)`
	margin-bottom: ${p => p.theme.spaceMedium};
`;

const MessengerModal = ({
	isOpen,
	onClose,
	order,
	fetchUserContactChannels
}) => {
	const [isActivated, setIsActivated] = useState(false);
	const { navigate } = useNavigation();

	const handlePollResult = useCallback(
		res =>
			compose(
				setIsActivated,
				any(
					both(
						propEq("type", "MESSENGER"),
						propIs(String, "timeConsented")
					)
				)
			)(res),
		[]
	);

	usePoll(fetchUserContactChannels, {
		active: !isActivated && isOpen,
		callback: handlePollResult
	});

	const openBot = useCallback(
		() =>
			openMessengerBot({
				navigate,
				userId: order?.userId,
				orderId: order?.orderId
			}),
		// navigate causes inf loop
		/* eslint-disable react-hooks/exhaustive-deps */
		[order?.userId, order?.orderId]
	);

	useEffect(() => isOpen && openBot(), [isOpen, openBot]);

	useEffect(() => isActivated && onClose(), [isActivated]);

	return (
		<Modal
			heading="Messenger"
			isOpen={isOpen}
			onClose={onClose}
			hideFooter
			closeButton
		>
			<Lead>Click on "Get started" in the Messenger app</Lead>
			<Body>
				<Loader size="mini" />
			</Body>
		</Modal>
	);
};

export default MessengerModal;
