import { Icon as CoreIcon } from "modules/core";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";

const Container = styled.div`
	position: relative;
	top: 1px;
	margin-left: 0px;
	margin-right: ${p => p.theme.spaceMedium};
`;

const Icon = styled(CoreIcon)``;

const iconSize = 20;
const badgeContainerDiameter = iconSize * 0.4;
const badgeContainerOffset = 0;
const badgeDiameter = badgeContainerDiameter * 0.7;

const BadgeContainer = styled.div`
	background: ${p => p.theme.colorWhite};
	height: ${badgeContainerDiameter}px;
	width: ${badgeContainerDiameter}px;
	border-radius: ${badgeContainerDiameter}px;
	position: absolute;
	top: -${badgeContainerOffset}px;
	right: -${badgeContainerOffset}px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Badge = styled.div`
	background: ${p => p.theme.colorBitterSweetRed};
	height: ${badgeDiameter}px;
	width: ${badgeDiameter}px;
	border-radius: ${badgeDiameter}px;
`;

const PushNotificationIcon = () => {
	return (
		<Container>
			<BadgeContainer>
				<Badge />
			</BadgeContainer>

			<Icon
				name="square-rounded-outline"
				size={iconSize}
				color={theme.colorIconPrimary}
			/>
		</Container>
	);
};

export default PushNotificationIcon;
