import { receiptRepo } from "data";
import { Button, Loader, EmailForm } from "modules/core";
import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useSwitch } from "utils";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import {
	Container,
	ButtonContainer,
	LoaderContainer
} from "./SendReceiptButton.styles";

const SendReceiptButton = ({ orderId, buttonLabel, color, className }) => {
	const [email, setEmail] = useState("");
	const [isSent, setIsSent] = useState();
	const [error, setError] = useState();
	const [isLoading, startLoading, stopLoading] = useSwitch(false);
	const [isEmailFormOpen, openEmailForm, closeEmailForm] = useSwitch(false);

	const isButtonHidden = useMemo(
		() => isSent || isEmailFormOpen || isLoading || error,
		[isSent, isEmailFormOpen, isLoading, error]
	);

	const sendEmailReceipt = useCallback(
		async email => {
			await receiptRepo.sendReceipt({ orderId, email });
			setIsSent(true);
		},
		[orderId]
	);

	const sendEmail = useCallback(
		async email => {
			try {
				startLoading();
				await sendEmailReceipt(email);
			} catch (e) {
				if (e.message === "402") {
					setError(new Error("RECEIPT_EMAIL_ALREADY_SENT"));
					return;
				}

				setError(e);
			} finally {
				stopLoading();
			}
		},
		[sendEmailReceipt, startLoading, stopLoading]
	);

	const handleDownloadButtonClick = useCallback(() => {
		openEmailForm();
	}, [openEmailForm]);

	const handleEmailFormComplete = useCallback(
		async email => {
			startLoading();

			try {
				await sendEmail(email);
				closeEmailForm();
			} finally {
				stopLoading();
			}
		},
		[closeEmailForm, sendEmail, startLoading, stopLoading]
	);

	const handleErrorMessageClose = useCallback(() => {
		setError();
		openEmailForm();
	}, [openEmailForm]);

	return (
		<Container className={className}>
			<ButtonContainer isHidden={isButtonHidden}>
				<Button color={color} onClick={handleDownloadButtonClick}>
					{buttonLabel}
				</Button>
			</ButtonContainer>

			{isSent && <SuccessMessage />}

			{error && (
				<ErrorMessage
					error={error}
					onCancel={handleErrorMessageClose}
				/>
			)}

			{!isLoading && isEmailFormOpen && (
				<EmailForm
					buttonLabel="Send receipt"
					value={email}
					onChange={setEmail}
					onComplete={handleEmailFormComplete}
					onCancel={closeEmailForm}
					saveAsDefaultValue={false}
					saveAsDefaultOption={false}
				/>
			)}

			{isLoading && (
				<LoaderContainer>
					<Loader size="small" />
				</LoaderContainer>
			)}
		</Container>
	);
};

SendReceiptButton.defaultProps = {
	color: "white",
	buttonLabel: "Email receipt"
};

export default SendReceiptButton;
