import { compose, curry, defaultTo, is, of, when } from "ramda";
import routes from "../routes";
import createUrlFromArray from "./create-url-from-array";
import patchQueryParams from "./patch-query-params";

const buildUrl = curry((history, query, routeName, args) =>
	compose(
		createUrlFromArray(history),
		arr => [arr[0], patchQueryParams(query, arr[1])],
		when(is(String), of),
		routes[routeName],
		defaultTo({})
	)(args)
);

export default buildUrl;
