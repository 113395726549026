import { useLoadCart } from "modules/cart";
import { useCheckin } from "modules/checkin";
import { StoreHeader } from "modules/core";
import React, { useMemo } from "react";
import { useGlobalState } from "state";
import EmptyState from "../EmptyState";
import Footer from "../Footer";
import Items from "../Items";
import { Body, Container } from "./Cart.styles";

const Cart = ({ cartId, onClose, menuCallbackUrl, menuCartCallbackUrl }) => {
	const { cart } = useGlobalState();
	const [checkin] = useCheckin(cart?.checkinId);
	const isEmptyState = useMemo(() => !cart || cart.isEmpty, [cart]);

	useLoadCart(cartId);

	return (
		<Container>
			{!isEmptyState && <StoreHeader {...{ checkin, onClose }} />}

			{cart && !cart.isEmpty && <Body>{cart && <Items />}</Body>}

			{isEmptyState && <EmptyState />}

			{cart && (
				<Footer
					{...{ checkin, menuCallbackUrl, menuCartCallbackUrl }}
				/>
			)}
		</Container>
	);
};

export default Cart;
