import { View } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import { PaymentMethods } from "modules/payment";
import React, { useCallback } from "react";
import { useQuery } from "utils";

const PaymentMethodsView = () => {
	const { checkinId } = useQuery();
	const { navigate } = useNavigation();

	const handleClose = useCallback(() => navigate(route.BACK), [navigate]);

	return (
		<View>
			<PaymentMethods onClose={handleClose} checkinId={checkinId} />
		</View>
	);
};

export default PaymentMethodsView;
