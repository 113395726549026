import { route, useNavigation } from "modules/navigation";
import { pick } from "ramda";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import ScreenHeader from "../ScreenHeader";
import StoreBadge from "../StoreBadge";
import Actions from "./Actions";

const Container = styled.span`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media ${mediaQuery(device.tablet)} {
		align-items: center;
	}
`;

const StoreBadgeContainer = styled.div`
	padding: ${p => p.theme.spaceMini};

	@media ${mediaQuery(device.tablet)} {
		padding: ${p => p.theme.spaceMini} 0;
	}
`;

const CloseAction = ({ handleClose }) => ({
	key: "close",
	onClick: handleClose,
	icon: "close"
});

const StoreHeader = props => {
	const {
		checkin,
		action,
		actions: actionsProp,
		callbackUrl,
		hideLabel,
		size
	} = props;
	const screenHeaderProps = pick(["onClose", "size"], props);
	const { navigate } = useNavigation();
	const handleClose = useCallback(
		() => navigate(route.CUSTOM, callbackUrl),
		[navigate, callbackUrl]
	);

	const actions = useMemo(
		() => [
			...(actionsProp ? actionsProp : []),
			...(action ? [action] : []),
			...(callbackUrl ? [CloseAction({ callbackUrl, handleClose })] : [])
		],
		[actionsProp, action, callbackUrl, handleClose]
	);

	return (
		<ScreenHeader {...screenHeaderProps}>
			<Container>
				<StoreBadgeContainer>
					<StoreBadge {...{ checkin, hideLabel, size }} />
				</StoreBadgeContainer>

				<Actions {...{ actions, callbackUrl }} />
			</Container>
		</ScreenHeader>
	);
};

StoreHeader.defaultProps = {
	size: "small"
};

export default StoreHeader;
