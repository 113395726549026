import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "utils";
import { buildUrl } from "./functions";

const useBuildUrl = () => {
	const history = useHistory();
	const currentQuery = useQuery();

	const buildUrlCallback = useCallback(
		(routeName, args) => buildUrl(history, currentQuery, routeName, args),
		[history, currentQuery]
	);

	return buildUrlCallback;
};

export default useBuildUrl;
