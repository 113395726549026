import amexSvg from "assets/icons/payment-methods/amex.svg";
import applePaySvg from "assets/icons/payment-methods/apple-pay.svg";
import mastercardSvg from "assets/icons/payment-methods/mastercard.svg";
import passbuySvg from "assets/icons/payment-methods/passbuy-black.svg";
import swishSvg from "assets/icons/payment-methods/swish.svg";
import visaSvg from "assets/icons/payment-methods/visa.svg";
import { always, cond, equals, T } from "ramda";
import React from "react";
import styled from "styled-components";

const Img = styled.img`
	height: ${p => p.size}px;
	width: auto;
	user-select: none;
`;

const PaymentMethodIcon = ({ name }) =>
	cond([
		[equals("apple_pay"), () => <Img src={applePaySvg} size={18} />],
		[equals("swish"), () => <Img src={swishSvg} size={22} />],
		[equals("amex"), () => <Img src={amexSvg} size={18} />],
		[equals("mastercard"), () => <Img src={mastercardSvg} size={18} />],
		[equals("visa"), () => <Img src={visaSvg} size={18} />],
		[equals("passbuy"), () => <Img src={passbuySvg} size={38} />],
		[T, always(null)]
	])(name);

export default PaymentMethodIcon;
