import { compose } from "ramda";
import React from "react";
import Button from "./Button";

const Option = ({ option, selected, onSelect, children }) => {
	const handleClick = compose(onSelect, () => (selected ? {} : option));

	return (
		<Button onClick={handleClick} selected={selected}>
			{children}
		</Button>
	);
};

export default Option;
