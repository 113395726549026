import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
`;

const Body = styled.div`
	position: relative;
	padding: ${p => p.theme.spaceGutterMobile};
`;

const Label = styled.div`
	font-size: 12.5px;
	line-height: 1.3;
	color: ${p =>
		p.highlighted ? p.theme.colorTextWhite : p.theme.colorTextMuted};
	white-space: nowrap;
`;

const HighlightItem = styled.div`
	position: absolute;
	top: 7px;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const HighlightLabel = styled.div`
	font-size: 12.5px;
	line-height: 1.3;
	color: ${p => p.theme.colorTextPrimary};
	font-weight: 500;
`;

const Item = ({ item, highlighted, onClick }) => {
	const { label } = item;

	const handleClick = () => onClick(item);

	return (
		<Container>
			<Body onClick={handleClick}>
				<Label highlighted={highlighted}>{label}</Label>
			</Body>

			{highlighted && (
				<HighlightItem>
					<HighlightLabel>{label}</HighlightLabel>
				</HighlightItem>
			)}
		</Container>
	);
};

export default Item;
