import config from "config";
import { defaultTo } from "ramda";
import React from "react";
import styled from "styled-components";

const Image = styled.img`
	width: 50px;
	height: 50px;
	border-radius: 50px;
	margin-right: ${p => p.theme.spaceSmall};
`;

const StoreImage = ({ url }) => {
	const src = defaultTo(config.DEFAULT_STORE_IMAGE_URL, url);

	return <Image src={src} />;
};

export default StoreImage;
