import { contactChannelRepo } from "data";
import { andThen, compose, tap } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useSwitch } from "utils";

const useUserContactChannels = () => {
	const [contactChannels, setContactChannels] = useState([]);
	const [isLoading, startLoading, stopLoading] = useSwitch(true);

	const fetchUserContactChannels = useCallback(
		() =>
			compose(
				andThen(tap(setContactChannels)),
				andThen(tap(stopLoading)),
				contactChannelRepo.getByUser,
				tap(startLoading)
			)(),
		[startLoading, stopLoading]
	);

	useEffect(() => {
		fetchUserContactChannels();
	}, [fetchUserContactChannels]);

	return [contactChannels, fetchUserContactChannels, isLoading];
};

export default useUserContactChannels;
