import React from "react";
import styled from "styled-components";
import Icon from "../Icon";

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const CloseButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const Header = ({ children, onClose, closeButton }) => {
	return (
		<Container>
			{children}

			{closeButton && (
				<CloseButton onClick={onClose}>
					<Icon name="close" size={22} />
				</CloseButton>
			)}
		</Container>
	);
};

export default Header;
