import React from "react";
import { useTheme } from "styled-components";
import Message from "./Message";

const SuccessMessage = () => {
	const theme = useTheme();

	return (
		<Message icon="check-circle" iconColor={theme.colorEmeraldGreen}>
			Receipt sent. Please check your inbox.
		</Message>
	);
};

export default SuccessMessage;
