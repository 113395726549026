import styled from "styled-components";
import { Heading } from "modules/core";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	padding: ${p => p.theme.spaceHuge} 0;
	border: 0px solid;
	border-color: ${p => p.theme.colorBorderMuted};
	border-bottom-width: 1px;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;

	&:hover {
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.03);
		border-color: ${p => p.theme.colorBorderPrimary};
		cursor: pointer;
		transition: 100ms ease-in-out;
	}

	@media ${mediaQuery(device.tablet)} {
		border-width: 1px;
		border-radius: ${p => p.theme.spaceSmall};
		padding: ${p => p.theme.spaceLarge};
	}
`;

const Body = styled.div`
	flex-shrink: 1;
`;

const Name = styled(Heading)`
	font-size: 14.5px;
	line-height: 1.3;
	font-weight: 500;
	margin-bottom: ${p => p.theme.spaceSmall};

	@media ${mediaQuery(device.tablet)} {
		font-size: 15.5px;
	}

	@media ${mediaQuery(device.laptop)} {
		font-size: 16px;
	}
`;

export { Container, Body, Name };
