import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import { isSameSection } from "./functions";

const useHighlights = ({
	sectionToHighlight,
	highlights,
	setSectionToHighlight,
	setHighlightedSection
}) => {
	useDeepCompareEffect(() => {
		const onHighlight = section => {
			if (!section) {
				return;
			}

			if (isSameSection(section, sectionToHighlight)) {
				return;
			}

			setSectionToHighlight(section);
			setHighlightedSection(section);
		};

		const sub = highlights.subscribe({ next: onHighlight });
		return () => sub.unsubscribe();
	}, [
		setHighlightedSection,
		highlights,
		sectionToHighlight,
		setSectionToHighlight
	]);
};

export default useHighlights;
