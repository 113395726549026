import { getAuth, onIdTokenChanged } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";

const auth = getAuth();

const useUserAuthToken = () => {
	const [token, setToken] = useState();

	const setUserAuthToken = useCallback(async user => {
		const token = user && (await user.getIdToken());
		setToken(token);
	}, []);

	useEffect(() => {
		onIdTokenChanged(auth, setUserAuthToken);
	}, [setUserAuthToken]);

	return token;
};

export default useUserAuthToken;
