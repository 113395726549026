import { add } from "ramda";

const poll = (fn, timeout = 5000, interval = 1000) => {
	const endTime = add(Number(new Date()), timeout);
	let isCanceled = false;

	const checkCondition = async () => {
		const [isCompleted, result] = await fn();

		if (isCanceled) {
			return new Error("Canceled");
		}

		if (isCompleted) {
			return result;
		}

		if (Number(new Date()) > endTime) {
			throw new Error("Timeout");
		}
	};

	const intervalFn = async (resolve, reject, timer) => {
		try {
			const result = await checkCondition();

			if (!result) {
				return;
			}

			resolve(result);
			clearInterval(timer);
		} catch (error) {
			reject(error);
			clearInterval(timer);
		}
	};

	const pollFn = (resolve, reject) => {
		let timer;

		intervalFn(resolve, reject, timer);
		timer = setInterval(() => intervalFn(resolve, reject, timer), interval);
	};

	return [
		new Promise(pollFn),
		() => {
			isCanceled = true;
		}
	];
};

export default poll;
