import { useMemo } from "react";
import useBreakpoint from "./use-breakpoint";

const useBreakpointTernary = (device, a, b) => {
	const isAtBreakpoint = useBreakpoint(device);

	return useMemo(
		() =>
			isAtBreakpoint
				? typeof a === "function"
					? a()
					: a
				: typeof b === "function"
				? b()
				: b,
		[isAtBreakpoint, a, b]
	);
};

export default useBreakpointTernary;
