import { Badge } from "modules/core";
import React from "react";
import styled from "styled-components";
import { formatCurrency } from "utils";

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const Text = styled.p`
	font-size: 13px;
	font-weight: 300;
	margin-top: ${p => p.theme.spaceMini};
	margin-right: ${p => p.theme.spaceSmall};
`;

const Price = ({ item }) => {
	const { price, priceAfterDiscount, isDiscounted } = item;

	return (
		<Container>
			{isDiscounted && (
				<Badge color="green">
					{formatCurrency(priceAfterDiscount)}
				</Badge>
			)}
			{!isDiscounted && <Text>{formatCurrency(price)}</Text>}
		</Container>
	);
};

export default Price;
