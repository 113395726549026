import config from "config";
import { httpClient } from "data";
import { useCallback, useEffect, useState } from "react";
import urlcat from "urlcat";

const qrBaseUrl =
	config.ENV_NAME === "local"
		? `${config.CORS_PROXY_URL}/${config.SWISH_QR_CODE_URL}`
		: config.SWISH_QR_CODE_URL;

const useSwishQrSrc = ({ token }) => {
	const [src, setSrc] = useState();
	const [error, setError] = useState();

	const fetchSwishQR = useCallback(async token => {
		try {
			const url = urlcat(qrBaseUrl, { token });
			const res = await httpClient.fetch(url, {}, { rawResponse: true });
			const blob = await res.blob();
			const src = URL.createObjectURL(blob);
			setSrc(src);
		} catch (error) {
			setError(error);
		}
	}, []);

	useEffect(() => {
		if (src || !token) {
			return;
		}

		fetchSwishQR(token);
	}, [fetchSwishQR, token, src]);

	return [src, error];
};

export default useSwishQrSrc;
