import {
	getAnalytics,
	setUserProperties as firebaseSetUserProperties
} from "firebase/analytics";

const setUserProperties = ({ session }) => {
	const analytics = getAnalytics();
	firebaseSetUserProperties(analytics, { session });
};

export default setUserProperties;
