import React from "react";
import cartIcon from "assets/icons/cart-linear-white.svg";
import styled from "styled-components";
import { always, cond, propEq } from "ramda";

const IconImg = styled.img`
	color: ${p => p.theme.colorIconBlack};
	width: ${cond([
		[propEq("size", "mini"), always(17)],
		[propEq("size", "small"), always(17.5)],
		[propEq("size", "medium"), always(21)]
	])}px;
	line-height: 1;
	height: auto;
`;

const Icon = ({ size }) => <IconImg src={cartIcon} alt="icon" size={size} />;

export default Icon;
