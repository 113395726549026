import { compose, filter, isEmpty, map, propEq } from "ramda";
import { useMemo } from "react";

const dealToMessage = d => ({
	type: "success",
	description: d.description
});

const useCartMessages = ({ messages = [], deals = [] }) =>
	useMemo(() => {
		const dealsMessages = compose(
			map(dealToMessage),
			filter(propEq("category", "CART"))
		)(deals);

		if (isEmpty(messages) && isEmpty(dealsMessages)) {
			return;
		}

		return [...messages, ...dealsMessages];
	}, [messages, deals]);

export default useCartMessages;
