const BeingPaidByOtherUsersMessage = ({
	itemQuantity,
	numberOfUnpaidFlatItemsClaimedByOtherUsers
}) => {
	const description =
		itemQuantity === numberOfUnpaidFlatItemsClaimedByOtherUsers
			? "Being paid by someone else"
			: `${numberOfUnpaidFlatItemsClaimedByOtherUsers} being paid by someone else`;

	return { type: "success", description };
};

export default BeingPaidByOtherUsersMessage;
