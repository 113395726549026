import { SectionLabel } from "modules/core";
import React from "react";
import { useSwitch } from "utils";
import {
	useDisplayStoreContactChannels,
	useStoreContactChannels
} from "../../functions";
import Buttons from "./Buttons";
import Container from "./Container";
import EmailModal from "./EmailModal";
import MessengerModal from "./MessengerlModal";
import SmsModal from "./SmsModal";

const StoreContactChannels = ({ storeId, order, fetchUserContactChannels }) => {
	const storeContactChannels = useStoreContactChannels(storeId);
	const [isMessengerModal, openMessengerModal, closeMessengerModal] =
		useSwitch(false);
	const [isSmsModal, openSmsModal, closeSmsModal] = useSwitch(false);
	const [isEmailModal, openEmailModal, closeEmailModal] = useSwitch(false);
	const displayStoreContactChannels = useDisplayStoreContactChannels({
		contactChannels: storeContactChannels,
		openMessengerModal,
		openSmsModal,
		openEmailModal,
		fetchUserContactChannels
	});

	return (
		<>
			<Container>
				<SectionLabel>Get a push notification</SectionLabel>

				<Buttons contactChannels={displayStoreContactChannels} />
			</Container>

			<MessengerModal
				isOpen={isMessengerModal}
				onClose={closeMessengerModal}
				{...{ order, fetchUserContactChannels }}
			/>

			<SmsModal
				isOpen={isSmsModal}
				onClose={closeSmsModal}
				fetchUserContactChannels={fetchUserContactChannels}
			/>

			<EmailModal
				isOpen={isEmailModal}
				onClose={closeEmailModal}
				fetchUserContactChannels={fetchUserContactChannels}
			/>
		</>
	);
};

export default StoreContactChannels;
