import { cartRepo } from "data";
import { curry } from "ramda";

const createCart = curry(
	async (setCart, setCartLoading, { checkinId, eventId }) => {
		setCartLoading(true);

		try {
			const cart = await cartRepo.create({ checkinId, eventId });
			setCart(cart);
			return cart;
		} finally {
			setCartLoading(false);
		}
	}
);

export default createCart;
