import React from "react";
import useSwishQrSrc from "./use-swish-qr-src";
import styled, { useTheme } from "styled-components";
import Skeleton from "./Skeleton";
import { EmptyState } from "modules/core";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${p => p.size}px;
	width: ${p => p.size}px;
	overflow: hidden;
`;

const Image = styled.img`
	width: 100%;
	height: auto;
	user-select: none;
`;

const SwishQr = ({ token, size }) => {
	const theme = useTheme();
	const [src, error] = useSwishQrSrc({ token });

	if (!src && !error) {
		return <Skeleton size={size} />;
	}

	return (
		<Container size={size} error={error}>
			{!error && src && <Image src={src} alt="swish-qr" />}

			{error && (
				<EmptyState
					label="Failed to load QR"
					icon="close-circle"
					iconColor={theme.colorBitterSweetRed}
				/>
			)}
		</Container>
	);
};

SwishQr.defaultProps = {
	size: 300
};

export default SwishQr;
