import React from "react";
import styled from "styled-components";
import Image from "./Image";

const Container = styled.div`
	width: ${p => p.size}px;
	height: ${p => p.size}px;
	position: relative;
	bottom: -${p => p.size / 2}px;
	overflow: hidden;
	padding: ${p => p.theme.spaceMini};
	border-radius: 50%;
	background-color: ${p => p.theme.colorWhite};
`;

const BrandImage = ({ image, size }) => {
	return (
		<Container size={size}>
			<Image src={image?.large} />
		</Container>
	);
};

export default BrandImage;
