import React from "react";
import styled from "styled-components";
import CoreLoader from "../Loader";
import { always, cond, equals, T } from "ramda";

const buttonSizeToLoaderSize = cond([
	[equals("mini"), always("tiny")],
	[T, always("mini")]
]);

const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;

const Loader = ({ isLoading, buttonSize }) => {
	if (!isLoading) {
		return null;
	}

	return (
		<Container>
			<CoreLoader
				color="white"
				size={buttonSizeToLoaderSize(buttonSize)}
			/>
		</Container>
	);
};

export default Loader;
