import { map } from "ramda";
import React from "react";
import styled from "styled-components";
import Item from "./Item";

const Container = styled.div`
	flex: 1;
	margin-bottom: ${p => p.theme.spaceGiant};
`;

const ContainerInner = styled.div`
	padding: ${p => p.theme.spaceMedium};
`;

const Items = ({ items }) => {
	const renderItems = map(i => <Item item={i} key={i.key} />);

	return (
		<Container>
			<ContainerInner>{renderItems(items)}</ContainerInner>
		</Container>
	);
};

export default Items;
