import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	overflow: hidden;
`;

const Header = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: ${p => p.theme.spaceHuge};
	border-bottom: 1px solid ${p => p.theme.colorBorderPrimary};
`;

const Cover = styled.div`
	height: 41vw;
	height: 41vw;
	max-height: 350px;
	min-height: 160px;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: ${() =>
		`${ImageContainerRadius + 12}`}px; // 12 === theme.spaceMedium
`;

const ImageContainerRadius = 88;
const ImageContainerCirc = ImageContainerRadius * 2;

const Image = styled.div`
	align-self: center;
	width: ${ImageContainerCirc}px;
	height: ${ImageContainerCirc}px;
	position: absolute;
	bottom: -${ImageContainerRadius}px;
	overflow: hidden;
	padding: ${p => p.theme.spaceMini};
	border-radius: 50%;
	background-color: ${p => p.theme.colorWhite};
`;

const Name = styled.div`
	align-self: center;
	width: 70%;
`;

const CheckinLabel = styled.div`
	align-self: center;
	width: 15%;
	margin-top: 12px;
`;

const Items = styled.div`
	align-self: center;
	width: 100%;
	padding: 16px;
	padding-top: 22px;
`;

const Skeleton = () => {
	return (
		<Container>
			<Header>
				<Cover>
					<ContentLoader
						speed={2}
						width="100%"
						height="100%"
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect x="0" y="0" width="100%" height="100%" />
					</ContentLoader>

					<Image>
						<ContentLoader
							speed={2}
							width="100%"
							height="100%"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<circle cx="50%" cy="50%" r="50%" />
						</ContentLoader>
					</Image>
				</Cover>

				<Name>
					<ContentLoader
						speed={2}
						width="100%"
						height={28}
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="0"
							y="0"
							rx="4"
							ry="4"
							width="100%"
							height="28"
						/>
					</ContentLoader>
				</Name>

				<CheckinLabel>
					<ContentLoader
						speed={2}
						width="100%"
						height={20}
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="0"
							y="0"
							rx="4"
							ry="4"
							width="100%"
							height="20"
						/>
					</ContentLoader>
				</CheckinLabel>
			</Header>

			<Items>
				<ContentLoader
					speed={2}
					width="100%"
					height={132}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<rect x="0" y="0" rx="4" ry="4" width="100%" height="60" />
					<rect x="0" y="72" rx="4" ry="4" width="100%" height="60" />
				</ContentLoader>
			</Items>
		</Container>
	);
};

export default Skeleton;
