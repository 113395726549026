import urlcat from "urlcat";
import config from "../config";
import httpClient from "./http-client";

const paymentMethodRepo = {
	registerPaymentMethod: paymentMethod =>
		httpClient.fetch(`${config.API_BASE_URL}/payment-methods`, {
			method: "POST",
			body: JSON.stringify({ paymentMethod })
		}),
	getPaymentMethods: ({ checkinId }) =>
		httpClient.fetch(
			urlcat(config.API_BASE_URL, "/payment-methods", {
				checkinId,
				version: 2
			}),
			{
				method: "GET"
			}
		),
	removePaymentMethod: paymentMethodId =>
		httpClient.fetch(
			urlcat(config.API_BASE_URL, "/payment-methods/:paymentMethodId", {
				paymentMethodId
			}),
			{ method: "DELETE" }
		)
};

export default paymentMethodRepo;
