import { map } from "ramda";
import React from "react";
import { Switch, useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import Route from "./Route";

const RouterView = ({ routes }) => {
	const location = useLocation();

	const renderRoutes = map(props => <Route key={props.path} {...props} />);

	return (
		<TransitionGroup style={{ flex: 1 }}>
			<Switch location={location}>{renderRoutes(routes)}</Switch>
		</TransitionGroup>
	);
};

export default RouterView;
