import React from "react";
import { useItems } from "../../functions";
import BrandImage from "../BrandImage";
import BrandInformation from "../BrandInformation";
import InactiveState from "../InactiveState";
import Items from "../Items";
import {
	Body,
	BrandCover,
	BrandName,
	CheckinLabel,
	Container,
	Header,
	Main
} from "./Content.styles";

const Content = ({ checkin }) => {
	const {
		checkinId,
		label,
		labelPrefix,
		brandName,
		brandImage,
		brandInformation,
		brandCoverImage,
		apps,
		active
	} = checkin;
	const items = useItems({ apps, checkinId });
	const brandImageSize = 176;
	return (
		<Container>
			<Main>
				<Header>
					<BrandCover
						brandImageSize={brandImageSize}
						coverImage={brandCoverImage}
					>
						<BrandImage image={brandImage} size={brandImageSize} />
					</BrandCover>

					<BrandName>{brandName}</BrandName>

					<CheckinLabel>
						{labelPrefix} {label}
					</CheckinLabel>

					<BrandInformation>{brandInformation}</BrandInformation>
				</Header>

				<Body>
					{active && <Items items={items} />}

					{!active && <InactiveState />}
				</Body>
			</Main>
		</Container>
	);
};

export default Content;
