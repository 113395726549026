import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import currency from "currency.js";
import React, { useCallback } from "react";
import styled from "styled-components";
import useApplePay from "./use-apple-pay";

const Container = styled.div`
	width: 100%;
`;

const paymentRequestButtonStyle = {
	paymentRequestButton: {
		theme: "dark",
		height: "52px",
		type: "default"
	}
};

const ApplePayButton = ({ onClick, total, tip }) => {
	const totalWithTip = currency(total).add(tip).multiply(100).value;

	const onAfterApplePaySheet = useCallback(
		async ({ complete, paymentMethod }) => {
			await onClick({ providerArgs: { paymentMethod } });
			complete();
		},
		[onClick]
	);

	const { paymentRequest, isApplePayAvailable } = useApplePay(
		{ amount: totalWithTip },
		onAfterApplePaySheet
	);

	if (!isApplePayAvailable) {
		return null;
	}

	if (!paymentRequest) {
		return null;
	}

	return (
		<Container>
			<PaymentRequestButtonElement
				options={{ paymentRequest, style: paymentRequestButtonStyle }}
			/>
		</Container>
	);
};

export default ApplePayButton;
