import { Account } from "modules/account";
import { View, ViewContainer } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import React, { useCallback, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { RouterView } from "router";
import { useQuery } from "utils";
import AccountOrderView from "./AccountOrderView";

const AccountView = () => {
	const { navigate } = useNavigation();
	const { callbackUrl } = useQuery();
	const { path } = useRouteMatch();

	const navigateToCallback = useCallback(
		() => navigate(route.CUSTOM, { url: callbackUrl, uriEncoded: true }),
		[navigate, callbackUrl]
	);

	const handleClose = useMemo(
		() => (callbackUrl ? navigateToCallback : null),
		[callbackUrl, navigateToCallback]
	);

	return (
		<>
			<View>
				<ViewContainer>
					<Account onClose={handleClose} />
				</ViewContainer>
			</View>

			<RouterView
				routes={[
					{
						path: `${path}/orders/:orderId`,
						Component: AccountOrderView
					}
				]}
			/>
		</>
	);
};

export default AccountView;
