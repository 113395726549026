import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import CloseButton from "./CloseButton";
import CoreHeading from "./Heading";

const Container = styled.div`
	background-color: ${p => p.theme.colorWhite};
`;

const ContainerInner = styled.div`
	display: flex;
	padding: ${p => p.theme.spaceSmall};
	padding-right: ${p => p.theme.spaceMedium};
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	${p =>
		p.size === "large" &&
		`@media ${mediaQuery(device.tablet)} {
			padding: ${p => p.theme.spaceGutterTablet};
		}
	`}
`;

const Heading = styled(CoreHeading)`
	font-size: 32px;
	line-height: 1.1;
`;

const ScreenHeader = ({ children, heading, onClose, size }) => {
	return (
		<Container>
			<ContainerInner size={size}>
				{heading && <Heading>{heading}</Heading>}
				{children}

				{onClose && <CloseButton onClose={onClose} />}
			</ContainerInner>
		</Container>
	);
};

export default ScreenHeader;
