import { compose, lt, multiply, when } from "ramda";
import { formatCurrency } from "utils";

const formatDisplayTipAmount = a =>
	formatCurrency(a, { minimumFractionDigits: 0 });

const tipAmount = (percentage, total) =>
	compose(when(lt(10), Math.round), multiply(percentage))(total);

const OptionFactory = ({ total, percentage, custom, customAmount }) => {
	if (custom) {
		return { optionId: "custom", type: "custom", amount: customAmount };
	}

	if (typeof total != "number") {
		throw new Error("total is not a number");
	}

	if (percentage > 1 || percentage < 0) {
		throw new Error("percentage is not correctly formated");
	}

	const label = `${percentage * 100}%`;
	const amount = tipAmount(percentage, total);
	const displayAmount = formatDisplayTipAmount(amount);

	return {
		type: "percentage",
		optionId: label,
		label,
		amount,
		displayAmount
	};
};

export default OptionFactory;
