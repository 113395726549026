import { Timeline } from "modules/core";
import React, { useMemo } from "react";
import { Badge, Content, Framework } from "../components";
import Header from "./Header";
import Status from "./Status";
import TimelineEvents from "./TimelineEvents";

const PreOrder = ({ delivery, order, checkin, onClose }) => {
	const { checkinLabel, checkinLabelPrefix } = delivery;
	const checkinLabelWithPrefix = useMemo(
		() => `${delivery.checkinLabelPrefix} ${delivery.checkinLabel}`,
		[delivery]
	);
	const timelineEvents = useMemo(
		() => TimelineEvents({ delivery, order, checkinLabelWithPrefix }),
		[delivery, order, checkinLabelWithPrefix]
	);

	return (
		<Framework {...{ onClose, order, checkin }}>
			<Header delivery={delivery}>
				<Badge prefix={checkinLabelPrefix} label={checkinLabel} />
				<Status delivery={delivery} />
			</Header>

			<Content>
				<Timeline events={timelineEvents} />
			</Content>
		</Framework>
	);
};

export default PreOrder;
