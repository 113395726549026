import { Loader } from "modules/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;

const LoaderView = () => {
	return (
		<Container>
			<Loader fadeIn="full" />
		</Container>
	);
};

export default LoaderView;
