import { always, compose, cond, T } from "ramda";
import React from "react";
import styled from "styled-components";
import Content from "./Content";
import ErrorState from "./ErrorState";
import { isTabAllPaidUp } from "./functions";
import NotFoundState from "./NotFoundState";
import PaidState from "./PaidState";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
`;

const Body = props => {
	const { error, fetchTab, tab } = props;

	return compose(
		children => <Container>{children}</Container>,
		cond([
			[
				always(error?.message === "Failed to fetch"),
				always(<ErrorState onRetry={fetchTab} />)
			],
			[always(error?.message === "Not found"), always(<NotFoundState />)],
			[isTabAllPaidUp, always(<PaidState />)],
			[T, always(<Content {...props} />)]
		])
	)(tab);
};

export default Body;
