import { createCart, refreshCart } from "modules/cart";
import { useCallback } from "react";
import { useGlobalState } from "state";

const useCart = () => {
	const { cart: currentCart, setCart, setCartLoading } = useGlobalState();

	const resolveCart = useCallback(
		async ({ checkinId, eventId, forceNewCart }) => {
			if (!checkinId) {
				throw new Error("checkinId is required");
			}

			if (forceNewCart || !currentCart?.cartId) {
				return createCart(setCart, setCartLoading, {
					checkinId,
					eventId
				});
			}

			if (currentCart && currentCart.checkinId !== checkinId) {
				throw new Error("cart checkinId mismatch");
			}

			const cart = await refreshCart(
				setCart,
				setCartLoading,
				currentCart?.cartId
			);

			if (cart.status === "CLOSED") {
				return createCart(setCart, setCartLoading, {
					checkinId,
					eventId
				});
			}

			return cart;
		},
		[setCart, setCartLoading, currentCart]
	);

	return { cart: currentCart, setCart, setCartLoading, resolveCart };
};

export default useCart;
