import { map } from "ramda";
import React, { useMemo } from "react";
import styled from "styled-components";
import Item from "./Item";

const Container = styled.div`
	margin-bottom: ${p => p.theme.spaceHuge};
	padding-bottom: ${p => p.theme.spaceHuge};
	border-bottom: 1px solid ${p => p.theme.colorBorderMuted};

	&:last-child {
		border-bottom: 0;
	}
`;

const Section = ({ section }) => {
	const itemComps = useMemo(
		() => map(item => <Item {...item} />, section.items),
		[section]
	);

	return <Container>{itemComps}</Container>;
};

export default Section;
