import { compose, isNil, prop, sortBy, unless } from "ramda";
import { useEffect, useState } from "react";

const useSections = menu => {
	const [sections, setSections] = useState([]);

	useEffect(() => {
		if (!menu) {
			return;
		}

		compose(
			setSections,
			unless(isNil, sortBy(prop("sortOrder"))),
			prop("sections")
		)(menu);
	}, [menu]);

	return sections;
};

export default useSections;
