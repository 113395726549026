import { contactChannelRepo } from "data";
import React, { useCallback, useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from "styled-components";
import ContactChannelModal from "./ContactChannelModal";
import * as validator from "libphonenumber-js";

const Input = styled(PhoneInput)`
	& > *:focus-visible {
		outline-color: #86b7fe;
	}
`;

const InputContainer = styled.div`
	width: 100%;
	font-size: 16px;
`;

const SmsModal = ({ isOpen, onClose, fetchUserContactChannels }) => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const isValidPhoneNumber = useMemo(() => {
		try {
			const phone = validator.parsePhoneNumber(phoneNumber);
			const isValid = phone.isValid();
			return isValid;
		} catch (error) {
			return false;
		}
	}, [phoneNumber]);

	const save = useCallback(
		async () =>
			contactChannelRepo.save({
				type: "SMS",
				identifier: phoneNumber,
				consented: true
			}),
		[phoneNumber]
	);

	return (
		<ContactChannelModal
			heading="SMS"
			isOpen={isOpen}
			onClose={onClose}
			onSave={save}
			fetchUserContactChannels={fetchUserContactChannels}
			disabled={!isValidPhoneNumber}
		>
			<InputContainer>
				<Input
					international
					defaultCountry="SE"
					placeholder="Enter phone number"
					value={phoneNumber}
					onChange={setPhoneNumber}
					autoFocus
				/>
			</InputContainer>
		</ContactChannelModal>
	);
};

export default SmsModal;
