import { orderRepo } from "data";
import { useCallback, useEffect, useState } from "react";

const useOrder = orderId => {
	const [order, setOrder] = useState();

	const fetchOrder = useCallback(async () => {
		if (!orderId) {
			return;
		}

		const fetchedOrder = await orderRepo.getOrder(orderId);
		setOrder(fetchedOrder);
	}, [orderId]);

	useEffect(() => {
		fetchOrder();
	}, [fetchOrder]);

	return order;
};

export default useOrder;
