import React from "react";
import styled from "styled-components";

const Container = styled.div`
	height: 35px;
	width: 35px;
	overflow: hidden;
	border-radius: 35px;
	margin-right: ${p => p.theme.spaceSmall};
	flex-shrink: 0;
`;

const Img = styled.img`
	width: 100%;
	height: auto;
`;

const Image = ({ image }) => {
	return (
		<Container>
			<Img src={image.large} />
		</Container>
	);
};

export default Image;
