import { Button, Modal } from "modules/core";
import { path } from "ramda";
import React, { useMemo } from "react";
import SwishQr from "./SwishQr";
import styled from "styled-components";

const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: ${p => p.theme.spaceLarge};
	padding-bottom: ${p => p.theme.spaceHuge};
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
`;

const Message = styled.p`
	font-size: 15px;
	margin-top: ${p => p.theme.spaceSmall};
	margin-bottom: ${p => p.theme.spaceGiant};
`;

const CancelButton = styled(Button)`
	width: 150px;
	margin-top: ${p => p.theme.spaceMedium};
`;

const SwishModal = ({ payment = {}, isOpen, onClose, onCancel }) => {
	const token = useMemo(
		() => path(["action", "swishToken"], payment),
		[payment]
	);

	return (
		<Modal
			heading="Swish"
			isOpen={isOpen}
			onClose={onClose}
			onCancel={onCancel}
			confirmButtonLabel="Done"
			hideFooter
		>
			<Body>
				<SwishQr token={token} />

				<Message>Scan the QR code with the Swish app</Message>

				<Footer>
					<CancelButton onClick={onCancel} color="gray" size="small">
						Cancel
					</CancelButton>
				</Footer>
			</Body>
		</Modal>
	);
};

export default SwishModal;
