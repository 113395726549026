import React, { useState } from "react";
import { Subject } from "rxjs";
import { useDimensions } from "utils";
import {
	ClosedMessage,
	Footer,
	Header,
	Sections,
	Skeleton
} from "./components";
import { useSections } from "./hooks";
import { Container, Content } from "./menu.styles";

const Menu = ({
	menu,
	isLoading,
	headerProps,
	heading,
	menuCallbackUrl,
	checkinId
}) => {
	const sections = useSections(menu);
	const [highlights] = useState(new Subject());
	const [navigations] = useState(new Subject());
	const [navigationEnds] = useState(new Subject());
	const [headerRef, { height: headerHeight }] = useDimensions(0); // Set dynamic
	const navigatorProps = { navigations, navigationEnds, highlights };
	const sectionsProps = {
		navigations,
		navigationOffset: -headerHeight,
		onCurrentSectionChange: s => highlights.next(s),
		onMomentumScrollEnd: () => navigationEnds.next()
	};

	if (isLoading) {
		return <Skeleton />;
	}

	const renderContent = () => (
		<>
			<Sections
				{...{
					menuId: menu.menuId,
					menuCallbackUrl,
					sections,
					...sectionsProps
				}}
			/>

			<Footer menuId={menu.menuId} {...{ menuCallbackUrl, checkinId }} />
		</>
	);

	return (
		<Container>
			<Header
				ref={headerRef}
				{...{ headerProps, heading, navigatorProps, sections }}
			/>

			<Content>
				{menu && renderContent()}
				{!menu && <ClosedMessage />}
			</Content>
		</Container>
	);
};

export default Menu;
