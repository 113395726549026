import marketplaceClosed from "assets/images/marketplace_closed_for_summer.png";
import { ScreenFooter, View, ViewContainer } from "modules/core";
import {
	fetchMarketplace,
	Marketplace,
	MarketplaceSkeleton
} from "modules/marketplace";
import { andThen, compose } from "ramda";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const MarketPlaceClosedImage = styled.img`
	width: auto;
	user-select: none;
	margin: auto 0;
`;

const MarketPlaceInactiveText = styled.div`
	width: auto;
	user-select: none;
	margin: auto auto;
`;

const MarketplaceView = () => {
	const [marketplace, setMarketplace] = useState();
	const { marketplaceId } = useParams();

	useEffect(() => {
		compose(andThen(setMarketplace), fetchMarketplace)(marketplaceId);
	}, [marketplaceId]);

	const marketplaceView = useMemo(() => {
		if (!marketplace) return <MarketplaceSkeleton />;

		if (marketplace.active)
			return (
				<Container>
					<Marketplace marketplace={marketplace} />
					<ScreenFooter />
				</Container>
			);

		if (!marketplace.active && new Date() < new Date("2022-09-01"))
			return (
				<Container>
					<MarketPlaceClosedImage src={marketplaceClosed} />
				</Container>
			);

		return (
			<Container>
				<MarketPlaceInactiveText>
					Market place is currently not active.
				</MarketPlaceInactiveText>
			</Container>
		);
	}, [marketplace]);

	return (
		<View>
			<ViewContainer breakpoint="desktop">
				{marketplaceView}
			</ViewContainer>
		</View>
	);
};

export default MarketplaceView;
