import {
	always,
	compose,
	cond,
	defaultTo,
	equals,
	flip,
	includes,
	prop
} from "ramda";
import React, { useMemo } from "react";
import { Status as StatusComp } from "../components";

const deliveryToStatus = compose(
	cond([
		[equals("CREATED"), always("Placing your order")],
		[
			flip(includes)(["RECEIVED", "DELIVERED"]),
			always("Sit back and relax")
		],
		[equals("ERROR"), always("Something went wrong")]
	]),
	prop("status"),
	defaultTo({})
);

const Status = ({ delivery }) => {
	const status = useMemo(() => deliveryToStatus(delivery), [delivery]);
	return <StatusComp>{status}</StatusComp>;
};

export default Status;
