import { Button, EmptyState as CoreEmptyState, View } from "modules/core";
import React from "react";
import { reloadPage } from "utils";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: ${p => p.theme.spaceMedium};
`;

const EmptyState = styled(CoreEmptyState)`
	padding-bottom: ${p => p.theme.spaceGiant};
	margin-bottom: ${p => p.theme.spaceMedium};
	flex-grow: 0;
`;

const ReloadButton = styled(Button)`
	max-width: 200px;
`;

const UserErrorScreen = () => {
	const theme = useTheme();

	return (
		<View>
			<Container>
				<EmptyState
					label="Something went wrong"
					icon="alert-circle-outline"
					iconColor={theme.colorBitterSweetRed}
				/>

				<ReloadButton color="black" size="small" onClick={reloadPage}>
					Reload
				</ReloadButton>
			</Container>
		</View>
	);
};

export default UserErrorScreen;
