import { useEffect } from "react";
import { scroller } from "react-scroll";

const useNavigations = ({ refs, navigations, sections, navigationOffset }) => {
	useEffect(() => {
		const scrollToSection = section => {
			const ref = refs[section.menuSectionId];
			const sectionElementId = `menu-section-${section.menuSectionId}`;

			if (!ref) {
				return;
			}

			scroller.scrollTo(sectionElementId, {
				duration: "linear",
				offset: navigationOffset
			});
		};

		const sub = navigations.subscribe({ next: scrollToSection });
		return () => sub.unsubscribe();
	}, [refs, navigations, sections, navigationOffset]);
};

export default useNavigations;
