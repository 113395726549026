import { useEffect } from "react";

const imagesSrcs = [
	"./assets/brand/symbol-white.svg",
	"./assets/images/swish-logo-secondary-light-bg.svg"
];

const usePreloadedImages = () => {
	useEffect(() => {
		imagesSrcs.forEach(imageSrc => {
			const img = new Image();
			img.src = imageSrc;
		});
	}, []);
};

export default usePreloadedImages;
