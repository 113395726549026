import React from "react";
import styled from "styled-components";

const Text = styled.div`
	color: ${p => p.theme.colorTextMuted};
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: ${p => p.theme.spaceLarge};
`;

const Description = ({ children }) => {
	return <Text>{children}</Text>;
};

export default Description;
