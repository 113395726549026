import { route, useNavigation } from "modules/navigation";
import React, { useCallback } from "react";
import Cover from "./Cover";
import Image from "./Image";
import { Container, Main, Name, Information, Body } from "./Store.styles";

const Store = ({ checkin, callbackUrl }) => {
	const {
		checkinId,
		brandName,
		brandImage,
		brandCoverImage,
		brandInformation
	} = checkin;
	const { navigate } = useNavigation();
	const handleClick = useCallback(() => {
		navigate(route.menu, { checkinId, callbackUrl });
	}, [navigate, checkinId, callbackUrl]);

	return (
		<Container onClick={handleClick}>
			<Cover image={brandCoverImage} />

			<Main>
				<Image image={brandImage} />

				<Body>
					<Name>{brandName}</Name>
					{brandInformation && (
						<Information>{brandInformation}</Information>
					)}
				</Body>
			</Main>
		</Container>
	);
};

export default Store;
