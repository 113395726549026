import { useCheckin } from "modules/checkin";
import { always, compose, cond, equals, prop } from "ramda";
import React from "react";
import { Skeleton } from "./components";
import Pickup from "./Pickup";
import TableService from "./TableService";
import PreOrder from "./PreOrder";

const OrderProgress = ({ delivery, order, onClose }) => {
	const [checkin] = useCheckin(order?.checkinId);

	if (!delivery || !order || !checkin) {
		return <Skeleton />;
	}

	return compose(
		Component => <Component {...{ delivery, order, checkin, onClose }} />,
		cond([
			[equals("PICKUP"), always(Pickup)],
			[equals("TABLE_SERVICE"), always(TableService)],
			[equals("PREORDER"), always(PreOrder)]
		]),
		prop("method")
	)(delivery);
};

export default OrderProgress;
