import { compose, map } from "ramda";
import React from "react";
import styled from "styled-components";
import ItemWithLabelAndIcon from "./ItemWithLabelAndIcon";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: flex-end;
`;

const Items = ({ items }) => {
	const renderItems = compose(
		map(item => (
			<ItemWithLabelAndIcon
				item={item}
				onClick={item.onClick}
				key={item.key}
			/>
		))
	);

	return <Container>{renderItems(items)}</Container>;
};

export default Items;
