import { HorizontalList } from "modules/core";
import { useDelivery } from "modules/order";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import StoreImage from "./StoreImage";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding-top: ${p => p.theme.spaceLarge};
	padding-left: ${p => p.theme.spaceMedium};
	padding-right: ${p => p.theme.spaceMedium};
	padding-bottom: ${p => p.theme.spaceHuge};
`;

const StoreName = styled.span`
	font-family: Peak-Semibold;
	font-size: 25px;
	line-height: 1.1;
	margin-bottom: ${p => p.theme.spaceTiny};
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: ${p => p.theme.spaceMini};
	padding-right: ${p => p.theme.spaceMini};
	flex-grow: 1;
`;

const HorizontalListWrapper = styled.div`
	margin-left: 1px;
`;

const Header = ({ order }) => {
	const { orderId, storeName, storeImage, timeCreated } = order;
	const [delivery] = useDelivery({ orderId });
	const details = [
		moment(timeCreated).format("dddd, D MMM"),
		moment(timeCreated).format("HH.mm"),
		delivery && `${delivery.checkinLabelPrefix} ${delivery.checkinLabel}`
	];

	return (
		<Container>
			<StoreImage url={storeImage} />

			<Body>
				<StoreName>{storeName}</StoreName>

				<HorizontalListWrapper>
					<HorizontalList items={details} />
				</HorizontalListWrapper>
			</Body>
		</Container>
	);
};

export default Header;
