import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	margin-bottom: ${p => p.theme.spaceLarge};
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.03);
	border-radius: ${p => p.theme.spaceSmall};
	cursor: pointer;

	&:active,
	&:hover {
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
		transition: 100ms ease-in-out;
	}

	@media ${mediaQuery(device.tablet)} {
		margin-bottom: 0;
	}
`;

const Main = styled.div`
	display: flex;
	align-items: center;
	padding: ${p => p.theme.spaceSmall} ${p => p.theme.spaceSmall};
	border: 1px solid ${p => p.theme.colorBorderPrimary};
	border-top: 0;
	border-radius: 0 0 ${p => p.theme.spaceSmall} ${p => p.theme.spaceSmall};
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
`;

const Name = styled.div`
	font-size: 15px;
	font-weight: 500;
	line-height: 1;
	margin-top: 0.5px;
`;

const Information = styled.div`
	font-size: 12px;
	line-height: 1;
	margin-top: ${p => p.theme.spaceMini};
	color: ${p => p.theme.colorTextMuted};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export { Main, Container, Name, Information, Body };
