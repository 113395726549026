import { ViewOverlay } from "modules/core";
import { MenuItem, useMenuItem } from "modules/menu";
import { route, useNavigation } from "modules/navigation";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "utils";

const MenuItemView = ({ menu }) => {
	const { menuItemId } = useParams();
	const { callbackUrl, checkinId, eventId } = useQuery();
	const { item, productOptions } = useMenuItem({ menu, menuItemId });
	const { navigate } = useNavigation();

	const handleClose = useCallback(() => {
		navigate(route.CUSTOM, callbackUrl);
	}, [navigate, callbackUrl]);

	if (!item) {
		return null;
	}

	return (
		<ViewOverlay onClose={handleClose}>
			<MenuItem
				productOptions={productOptions}
				item={item}
				onClose={handleClose}
				checkinId={checkinId}
				eventId={eventId}
			/>
		</ViewOverlay>
	);
};

export default MenuItemView;
