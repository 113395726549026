import { compose, defaultTo, prop, propOr, sortBy } from "ramda";
import { useMemo } from "react";

const useDeliveryMethods = checkin =>
	useMemo(
		() =>
			compose(
				sortBy(prop("sortOrder")),
				propOr([], "deliveryMethods"),
				defaultTo({})
			)(checkin),
		[checkin]
	);

export default useDeliveryMethods;
