import React from "react";
import { CartButton } from "modules/cart";
import styled from "styled-components";
import { device, displayOn } from "styles/device";

const Container = styled.div`
	${displayOn({ min: device.tablet })}
`;

const Action = props => {
	return (
		<Container>
			<CartButton {...{ ...props, size: "mini", icon: true }}>
				Cart
			</CartButton>
		</Container>
	);
};

const CartAction = ({ menuCallbackUrl, menuId, checkinId }) => ({
	key: "cart",
	onClick: () => {},
	icon: () => <Action {...{ menuId, checkinId, menuCallbackUrl }} />
});

export default CartAction;
