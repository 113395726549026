import { compose, cond, identity, propEq, T } from "ramda";

const paymentMethodToDisplayPaymentMethod = ({ isApplePayAvailable }) =>
	compose(
		cond([
			[
				propEq("type", "APPLE_PAY"),
				pm => ({
					...pm,
					isAvailable: pm.isAvailable && isApplePayAvailable
				})
			],
			[T, identity]
		])
	);

export default paymentMethodToDisplayPaymentMethod;
