import React from "react";
import styled from "styled-components";
import Deals from "./Deals";
import MetaData from "./MetaData";
import SendReceiptButton from "../../SendReceiptButton";
import Tip from "./Tip";
import Total from "./Total";
import Vat from "./Vat";
import { Section } from "modules/core";

const Container = styled.div`
	margin-top: ${p => p.theme.spaceLarge};
	margin-bottom: ${p => p.theme.spaceMini};
`;

const Footer = ({ order, onClose }) => {
	return (
		<Container>
			<Total order={order} />
			<Deals order={order} />
			<Vat order={order} />
			<Tip tip={order.tip} />
			<Section>
				<SendReceiptButton orderId={order?.orderId} />
			</Section>
			<MetaData order={order} />
		</Container>
	);
};

export default Footer;
