import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;
	justify-content: flex-start;
	background-color: #fff;
	width: 100%;
	margin-bottom: 70px; // Floating footer height */
`;

const Content = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
`;

export { Container, Content };
