import { ToggleButton } from "modules/core";
import { compose, cond, find, map, propEq, propOr } from "ramda";
import React, { useCallback, useMemo } from "react";

const deliveryMethodToState = dm => ({
	label: cond([
		[
			propEq("deliveryMethod", "TABLE_SERVICE"),
			propOr("Table Service", "label")
		],
		[propEq("deliveryMethod", "PICKUP"), propOr("Pickup", "label")]
	])(dm),
	value: dm.deliveryMethod
});

const MethodPicker = ({ deliveryMethod, deliveryMethods, onChange }) => {
	const toggleStates = useMemo(
		() => map(deliveryMethodToState, deliveryMethods),
		[deliveryMethods]
	);

	const handleChange = useCallback(
		value =>
			compose(
				onChange,
				find(propEq("deliveryMethod", value))
			)(deliveryMethods),
		[onChange, deliveryMethods]
	);

	return (
		<ToggleButton
			value={deliveryMethod?.deliveryMethod}
			states={toggleStates}
			onChange={handleChange}
		/>
	);
};

export default MethodPicker;
