import { always, cond, equals, T } from "ramda";

const TimelineEvents = ({ delivery, order, checkinLabelWithPrefix }) => [
	{
		status: "completed",
		label: "Payment completed",
		first: true
	},
	{
		label: `Received by ${order.storeName}`,
		animated: true,
		errorMessage: "Couldn't place your order, please contact the staff.",
		status: cond([
			[equals("DELIVERED"), always("completed")],
			[equals("RECEIVED"), always("completed")],
			[equals("READY_FOR_PICKUP"), always("completed")],
			[equals("ERROR"), always("error")],
			[T, always("pending")]
		])(delivery.status)
	},
	{
		label: "See you soon",
		animated: true,
		completedMessage: `Your order will be served to ${checkinLabelWithPrefix}`,
		last: true,
		status: cond([
			[equals("DELIVERED"), always("completed")],
			[equals("RECEIVED"), always("completed")],
			[equals("ERROR"), always("pending")],
			[T, always("pending")]
		])(delivery.status)
	}
];

export default TimelineEvents;
