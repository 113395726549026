import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: block;
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
	padding-top: ${({ aspectRatio }) => 100 / aspectRatio}%;
`;

const ContainerInner = styled.div`
	display: block;
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;

const AspectRatioContainer = ({ aspectRatio, children }) => {
	return (
		<Container aspectRatio={aspectRatio}>
			<ContainerInner>{children}</ContainerInner>
		</Container>
	);
};

export default AspectRatioContainer;
