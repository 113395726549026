import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${p => p.theme.colorWhite};
	flex: 1;
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: flex-start;
	justify-content: space-evenly;
	padding: ${p => p.theme.spaceHuge} ${p => p.theme.spaceLarge}
		${p => p.theme.spaceLarge};
`;

export { Container, Body, Content };
