import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	align-items: baseline;
	margin-top: ${p => p.theme.spaceTiny};
	position: relative;
	left: 1px;
	height: 11.5px;

	${p =>
		p.size === "large" &&
		`
		@media ${mediaQuery(device.tablet)} {
			height: 12.5px;
		}

		@media ${mediaQuery(device.laptop)} {
			height: 13.5px;
		}
	`}
`;

const LabelWithPrefix = styled.span`
	font-size: 11.5px;
	line-height: 1.3;

	${p =>
		p.size === "large" &&
		`
		@media ${mediaQuery(device.tablet)} {
			font-size: 12.5px;
		}

		@media ${mediaQuery(device.laptop)} {
			font-size: 13.5px;
		}
	`}
`;

const CheckinLabelWithPrefix = ({ checkinLabelWithPrefix, size }) => {
	return (
		<Container size={size}>
			<LabelWithPrefix size={size}>
				{checkinLabelWithPrefix}
			</LabelWithPrefix>
		</Container>
	);
};

export default CheckinLabelWithPrefix;
