import styled from "styled-components";

const Container = styled.div`
	width: 100%;
	background-color: ${p => p.theme.colorWhite};
	border: 1px solid ${p => p.theme.colorBorderPrimary};
	border-radius: 10px;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.03);
	padding: ${p => p.theme.spaceSmall};
	display: flex;
	align-items: center;

	${p =>
		(p.onClick || p.clickable) &&
		`	cursor: pointer;

			&:hover {
				box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
				transition: 100ms ease-in-out;
			}
		`}
`;

const Tile = ({ children, onClick, clickable, className }) => {
	return (
		<Container {...{ onClick, clickable, className }}>{children}</Container>
	);
};

export default Tile;
