import { CheckoutFooter, useTotalWithTip } from "modules/checkout";
import { useDeliveryMethods } from "modules/delivery";
import { compose, head, isEmpty } from "ramda";
import React, { useEffect, useMemo } from "react";
import { useGlobalState } from "state";
import { useQuery, useSessionState } from "utils";
import { updateCart } from "../../functions";
import useCartMessages from "./use-cart-messages";

const Footer = ({ checkin, menuCallbackUrl, menuCartCallbackUrl }) => {
	const { cart, setCart, tip } = useGlobalState();
	const { eventId } = useQuery();
	const checkinLabelWithPrefix = useMemo(
		() => `${checkin?.labelPrefix} ${checkin?.label}`,
		[checkin]
	);
	const deliveryMethods = useDeliveryMethods(checkin);
	const [deliveryMethod, setDeliveryMethod] =
		useSessionState("deliveryMethod");
	const [eventTimeSlot, setEventTimeSlot] = useSessionState("eventSlot");

	const {
		cartId,
		total,
		isCheckoutable,
		deals,
		totalBeforeDiscount,
		isDiscounted,
		requiresTimeSlot
	} = cart;
	const messages = useCartMessages({ messages: cart.messages, deals });
	const totalWithTip = useTotalWithTip({ total, tip });

	useEffect(() => {
		if (deliveryMethod || !deliveryMethods || isEmpty(deliveryMethods)) {
			return;
		}

		compose(setDeliveryMethod, head)(deliveryMethods);
	}, [deliveryMethod, setDeliveryMethod, deliveryMethods]);

	useEffect(() => {
		if (!deliveryMethod) {
			return;
		}

		updateCart(setCart, {
			cartId,
			deliveryMethod: deliveryMethod.deliveryMethod,
			eventTimeSlotId: eventTimeSlot?.eventTimeSlotId
		});
	}, [deliveryMethod, setCart, cartId, eventTimeSlot]);

	const checkoutProps = {
		cart,
		tip,
		callbackSuccess: menuCallbackUrl,
		callbackError: menuCartCallbackUrl,
		callbackCancel: menuCartCallbackUrl
	};

	if (cart.isEmpty) {
		return null;
	}

	return (
		<CheckoutFooter
			{...{
				total,
				totalBeforeDiscount,
				totalWithTip,
				tip,
				isDiscounted,
				deliveryDestination: checkinLabelWithPrefix,
				deliveryMethods,
				deliveryMethod,
				onDeliveryMethodChanged: setDeliveryMethod,
				requiresTimeSlot,
				eventTimeSlot,
				eventId,
				onEventTimeSlotChanged: setEventTimeSlot,
				isCheckoutable,
				checkoutProps,
				messages
			}}
		/>
	);
};

export default Footer;
