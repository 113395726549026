import { compose, map, reverse } from "ramda";
import React, { useMemo } from "react";
import styled from "styled-components";
import AnimatedEvent from "./AnimatedEvent";
import Event from "./Event";

const Container = styled.div`
	display: flex;
	position: relative;
	flex-direction: column-reverse;
	padding-top: ${p => p.theme.spaceMedium};
	margin-bottom: ${p => p.theme.spaceHuge};
	padding-bottom: ${p => p.theme.spaceMedium};
	width: 100%;
	user-select: none;
`;

const Timeline = ({ events }) => {
	const eventComponents = useMemo(
		() =>
			compose(
				reverse,
				map(({ animated, ...props }) =>
					animated ? (
						<AnimatedEvent {...props} />
					) : (
						<Event {...props} />
					)
				)
			)(events),
		[events]
	);

	return <Container>{eventComponents}</Container>;
};

Timeline.defaultProps = {
	events: []
};

export default Timeline;
