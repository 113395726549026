import { AspectRatioContainer } from "modules/core";
import React from "react";
import styled from "styled-components";
import theme from "styles/theme";

const Background = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: ${({ image }) =>
		image ? `url("${image.large}")` : theme.colorSoftGray};
	background-size: cover;
	background-position: center center;
	border-radius: ${p => p.theme.spaceSmall} ${p => p.theme.spaceSmall} 0 0;
`;

const Cover = ({ image }) => {
	return (
		<AspectRatioContainer aspectRatio={2}>
			<Background image={image} />
		</AspectRatioContainer>
	);
};

export default Cover;
