import { always, cond, equals, T } from "ramda";

const TimelineEvents = ({ delivery, order, checkinLabelWithPrefix }) => [
	{
		status: "completed",
		label: "Payment completed",
		first: true
	},
	{
		label: `Received by ${order.storeName}`,
		animated: true,
		errorMessage: "Couldn't place your order, please contact the staff.",
		status: cond([
			[equals("DELIVERED"), always("completed")],
			[equals("RECEIVED"), always("completed")],
			[equals("READY_FOR_PICKUP"), always("completed")],
			[equals("ERROR"), always("error")],
			[T, always("pending")]
		])(delivery.status)
	},
	{
		label: "Sit back and relax",
		animated: true,
		completedMessage: `Your order is being prepared and will be served to ${checkinLabelWithPrefix} when it's ready`,
		last: true,
		status: cond([
			[equals("DELIVERED"), always("completed")],
			[equals("RECEIVED"), always("completed")],
			[equals("ERROR"), always("pending")],
			[T, always("pending")]
		])(delivery.status)
	}
];

export default TimelineEvents;
