import config from "../config";
import httpClient from "./http-client";

const cartRepo = {
	get: ({ cartId }) =>
		httpClient.fetch(`${config.API_BASE_URL}/carts/${cartId}`, {
			method: "GET"
		}),
	create: args =>
		httpClient.fetch(`${config.API_BASE_URL}/carts`, {
			method: "POST",
			body: JSON.stringify(args)
		}),
	update: ({ cartId, ...args }) =>
		httpClient.fetch(`${config.API_BASE_URL}/carts/${cartId}`, {
			method: "PATCH",
			body: JSON.stringify(args)
		}),
	addItem: ({ cartId, ...args }) =>
		httpClient.fetch(`${config.API_BASE_URL}/carts/${cartId}/items`, {
			method: "PUT",
			body: JSON.stringify(args)
		}),
	removeItem: ({ cartId, cartItemId }) =>
		httpClient.fetch(
			`${config.API_BASE_URL}/carts/${cartId}/items/${cartItemId}`,
			{ method: "DELETE" }
		)
};

export default cartRepo;
