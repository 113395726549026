import { Items } from "modules/core";
import { compose, isEmpty, prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "state";
import { mapCartItemsToDisplayItems } from "../functions";

const CartItems = () => {
	const { cart, setCart, setCartLoading } = useGlobalState();
	const [displayItems, setDisplayItems] = useState([]);

	useEffect(() => {
		compose(
			setDisplayItems,
			mapCartItemsToDisplayItems(cart, setCart, setCartLoading),
			prop("items")
		)(cart);
	}, [cart, setCart, setCartLoading]);

	if (isEmpty(displayItems)) {
		return null;
	}

	return <Items items={displayItems} />;
};

export default CartItems;
