import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 ${p => p.theme.spaceLarge};
	overflow: hidden;

	@media ${mediaQuery(device.tablet)} {
		justify-content: flex-start;
	}
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 38px;
	box-sizing: content-box;
	padding-top: ${p => p.theme.spaceHuge};
	padding-bottom: ${p => p.theme.spaceLarge};
	margin-bottom: ${p => p.theme.spaceHuge};
	border-bottom: 1px solid ${p => p.theme.colorBorderMuted};
`;

const Stores = styled.div`
	@media ${mediaQuery(device.tablet)} {
		display: grid;
		grid-template-columns: ${p => `repeat(2, 1fr)`};
		column-gap: ${p => p.theme.spaceLarge};
		row-gap: ${p => p.theme.spaceLarge};
	}

	@media ${mediaQuery(device.laptop)} {
		grid-template-columns: ${p => `repeat(3, 1fr)`};
	}

	@media ${mediaQuery(device.desktop)} {
		grid-template-columns: ${p => `repeat(4, 1fr)`};
		column-gap: ${p => p.theme.spaceHuge};
		row-gap: ${p => p.theme.spaceHuge};
		max-width: 2560px; // 24" at 122 PPI
	}
`;

const Store = ({ y }) => (
	<ContentLoader
		speed={2}
		width="100%"
		height={211.5}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ marginBottom: 16 }}
	>
		<rect x="0" y="0" rx="8" ry="8" width="100%" height="160" />
		<circle cx="17.5" cy={185.5} r="17.5" />
		<rect x="44" y={179} rx="4" ry="4" width="45%" height="16" />
	</ContentLoader>
);

const MarketplaceSkeleton = () => {
	return (
		<Container>
			<Header>
				<ContentLoader
					speed={2}
					width="100%"
					height={32}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<rect x="0" y="0" rx="4" ry="4" width="200" height="32" />
				</ContentLoader>
			</Header>

			<Stores>
				<Store />
				<Store />
				<Store />
				<Store />
				<Store />
				<Store />
			</Stores>
		</Container>
	);
};

export default MarketplaceSkeleton;
