import { eventRepo } from "data";
import { useCallback, useEffect, useState } from "react";

const useEvent = eventId => {
	const [event, setEvent] = useState();
	const [error, setError] = useState();

	const fetchEvent = useCallback(async () => {
		if (!eventId) {
			return;
		}

		try {
			const fetchedOrder = await eventRepo.getEvent(eventId);
			setEvent(fetchedOrder);
		} catch (e) {
			setError(e);
		}
	}, [eventId]);

	useEffect(() => {
		fetchEvent();
	}, [fetchEvent]);

	return [event, error];
};

export default useEvent;
