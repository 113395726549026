import { always, compose, cond, curry, equals, is, when } from "ramda";
import { openUrl } from ".";
import pushUrl from "./push-url";
import replaceUrl from "./replace-url";

/**
 * @param args { url, uriEncoded, mode }
 */
const navigateCustom = curry((history, args) =>
	cond([
		[is(String), pushUrl(history)],
		[
			is(Object),
			({ url, mode, uriEncoded }) =>
				compose(url => {
					if (equals(mode, "replace")) {
						replaceUrl(url);
						return;
					}

					if (equals(mode, "open")) {
						openUrl(url);
						return;
					}

					pushUrl(history, url);
				}, when(always(uriEncoded), decodeURI))(url)
		]
	])(args)
);

export default navigateCustom;
