import { CheckoutPending } from "modules/checkout";
import { View } from "modules/core";
import React from "react";

const CheckoutInitView = () => {
	return (
		<View>
			<CheckoutPending />
		</View>
	);
};

export default CheckoutInitView;
