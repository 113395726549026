import styled from "styled-components";
import { FormCheck } from "react-bootstrap";

const Container = styled(FormCheck)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: ${p => p.theme.spaceTiny};
`;

const Label = styled(FormCheck.Label)`
	text-align: left;
	width: 100%;
	color: ${p => p.theme.colorTextMuted};
	font-size: 13.5px;
	line-height: 1.5;
`;

const Checkbox = styled.div`
	margin-right: ${p => p.theme.spaceSmall};
	height: 27px;
	display: flex;
	align-items: center;
`;

const Price = styled.div`
	width: 100px;
	text-align: right;
	color: ${p => p.theme.colorTextMuted};
	font-size: 13.5px;
	line-height: 1.5;
`;

const Input = styled(FormCheck.Input)`
	width: 1.1rem;
	height: 1.1rem;
	margin-top: 0;
`;

export { Container, Label, Checkbox, Price, Input };
