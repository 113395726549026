import { always, compose, cond, equals, propOr } from "ramda";
import React from "react";
import { Header as HeaderComp } from "../components";

const deliveryToBackgroundColor = compose(
	cond([
		[equals("CREATED"), always("purple")],
		[equals("RECEIVED"), always("green")],
		[equals("DELIVERED"), always("green")],
		[equals("ERROR"), always("red")]
	]),
	propOr("", "status")
);

const Header = ({ children, delivery }) => {
	return (
		<HeaderComp color={deliveryToBackgroundColor(delivery)}>
			{children}
		</HeaderComp>
	);
};

export default Header;
