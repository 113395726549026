import styled from "styled-components";

const ButtonContainer = styled.div`
	position: absolute;
	top: 104px; // 104 = button height + (spaceGiant x 2)
	margin-top: ${p => p.theme.spaceGiant};
	width: 120px;
`;

export default ButtonContainer;
