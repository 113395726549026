import { map, reduce } from "ramda";
import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import Section from "../Section";
import useNavigations from "./use-navigations";

const Container = styled.div`
	flex-grow: 1;
	padding: 0 ${p => p.theme.spaceGutterMobile};

	@media ${mediaQuery(device.tablet)} {
		padding: 0 ${p => p.theme.spaceGutterTablet};
	}
`;

const Sections = ({
	menuId,
	sections,
	navigations,
	navigationOffset,
	menuCallbackUrl
	// onCurrentSectionChange,
	// onMomentumScrollEnd,
}) => {
	const refs = reduce(
		(acc, value) => {
			acc[value.menuSectionId] = React.createRef();
			return acc;
		},
		{},
		sections
	);
	// const scrollEventsRef = useRef(new Subject());
	// const isScrolledTop = useIsScrolledTop(scrollEventsRef.current);
	// const isScrolledBottom = useIsScrolledBottom(scrollEventsRef.current);

	const renderSections = map(s => (
		<Element name={`menu-section-${s.menuSectionId}`} key={s.menuSectionId}>
			<Section
				menuId={menuId}
				menuCallbackUrl={menuCallbackUrl}
				section={s}
				ref={refs[s.menuSectionId]}
			/>
		</Element>
	));

	// TODO reimplement these

	useNavigations({ refs, navigations, sections, navigationOffset });

	// const handleViewableItemsChanged = useOnCurrentSectionChange({
	//   onCurrentSectionChange,
	//   isScrolledTop,
	//   isScrolledBottom,
	// });

	// const handleScroll = useCallback(
	//   compose((e) => scrollEventsRef.current.next(e), prop("nativeEvent")),
	//   []
	// );

	// TODO: skeleton is base on onLayout foe height and width

	// if (!sections) {
	//   return <SectionsSkeleton />;
	// }

	return <Container>{renderSections(sections)}</Container>;
};

export default Sections;
