import passbuySvg from "assets/icons/payment-methods/passbuy-white.svg";
import { Button as CoreButton } from "modules/core";
import React from "react";
import styled from "styled-components";

const Button = styled(CoreButton)`
	height: 52px;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
	width: 100%;
`;

const Icon = styled.img`
	height: ${p => p.size}px;
	width: auto;
	user-select: none;
	position: absolute;
	left: 0;
`;

const Label = styled.span``;

const TestButton = ({ onClick }) => {
	return (
		<Button onClick={onClick}>
			<Container>
				<Icon src={passbuySvg} size={32} /> <Label>Pay</Label>
			</Container>
		</Button>
	);
};

export default TestButton;
