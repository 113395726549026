import urlcat from "urlcat";
import config from "../config";
import httpClient from "./http-client";

const MarketplaceRepo = {
	get: ({ marketplaceId }) =>
		httpClient.fetch(
			urlcat(`${config.API_BASE_URL}/marketplaces/:marketplaceId`, {
				marketplaceId
			})
		)
};

export default MarketplaceRepo;
