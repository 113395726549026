const route = {
	BACK: "BACK",
	CUSTOM: "CUSTOM",
	checkout: "checkout",
	checkoutSuccess: "checkoutSuccess",
	checkoutError: "checkoutError",
	tab: "tab",
	menuItem: "menuItem",
	menu: "menu",
	menuCart: "menuCart",
	home: "home",
	receipt: "receipt",
	app: "app",
	orderProgress: "orderProgress",
	paymentMethods: "paymentMethods",
	account: "account",
	accountOrder: "accountOrder",
	checkin: "checkin",
	marketplace: "marketplace",
	landing: "landing",
	event: "event"
};

export default route;
