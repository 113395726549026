import { Loader, View } from "modules/core";
import { useEvent } from "modules/event";
import { route, useNavigation } from "modules/navigation";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "utils";
import ClosedState from "./ClosedState";

const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const EventView = () => {
	const { eventId } = useParams();
	const { checkinId } = useQuery();
	const { navigate } = useNavigation();
	const [event] = useEvent(eventId);

	useEffect(() => {
		if (!event) {
			return;
		}

		if (event.isOpenForPreOrders) {
			navigate(route.checkin, { checkinId, eventId });
		}
	}, [event, checkinId, eventId, navigate]);

	return (
		<View>
			<Container>
				{!event && <Loader size="large" />}
				{event && !event.isOpenForPreOrders && <ClosedState />}
			</Container>
		</View>
	);
};

export default EventView;
