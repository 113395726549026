import React from "react";
import Option from "../Option";
import Description from "./Description";
import Label from "./Label";

const SimpleOption = props => {
	const { option, selected } = props;
	const { label, description } = option;

	return (
		<Option {...props}>
			<Label selected={selected}>{label}</Label>
			{description && (
				<Description selected={selected}>{description}</Description>
			)}
		</Option>
	);
};

export default SimpleOption;
