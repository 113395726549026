import { useState, useEffect, useCallback } from "react";
import throttle from "lodash.throttle";

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined
	});
	/* eslint-disable react-hooks/exhaustive-deps */
	const handleResizeThrottled = useCallback(throttle(setWindowSize, 50), []);
	const handleResize = useCallback(
		() =>
			handleResizeThrottled({
				width: window.innerWidth,
				height: window.innerHeight
			}),
		[]
	);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [handleResize]);

	return windowSize;
};

export default useWindowSize;
