import { Section, StoreHeader } from "modules/core";
import { SendReceiptButton } from "modules/receipt";
import React from "react";
import Detail from "./Detail";
import Footer from "./Footer";
import { Body, Container } from "./styled";

const Framework = ({ children, onClose, order, checkin }) => {
	return (
		<Container>
			<StoreHeader
				checkin={checkin}
				closeable
				onClose={onClose}
				hideLabel
			/>

			<Body>
				{children}

				<Detail label="Order reference" value={order?.orderId} first />

				<Section last>
					<SendReceiptButton orderId={order?.orderId} color="white" />
				</Section>
			</Body>

			<Footer order={order} />
		</Container>
	);
};

export default Framework;
