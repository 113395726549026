import { useCart } from "modules/cart";
import { useCheckin } from "modules/checkin";
import TimeSlotPicker from "./TimeSlotPicker";

const TimeSlot = ({
	checkinId,
	eventId,
	eventTimeSlot,
	onEventTimeSlotChanged
}) => {
	const [checkin] = useCheckin(checkinId);

	const {
		cart: { cartId, requiresTimeSlot }
	} = useCart();

	if (!checkin) return null;
	if (!requiresTimeSlot) return null;

	const { storeId } = checkin;

	return (
		<TimeSlotPicker
			{...{
				cartId,
				storeId,
				eventId,
				eventTimeSlot,
				onEventTimeSlotChanged
			}}
		/>
	);
};

export default TimeSlot;
