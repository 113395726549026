import styled from "styled-components";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: ${p => p.theme.spaceMedium};
`;

export default Content;
