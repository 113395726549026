import { compose, path } from "ramda";
import React, { useCallback, useMemo } from "react";
import { FormControl } from "react-bootstrap";
import emailValidator from "email-validator";
import {
	Container,
	Label,
	FormGroup,
	FormCheck,
	FormControlContainer,
	ButtonContainer,
	Button
} from "./EmailForm.styles";

const EmailForm = ({
	label,
	buttonLabel,
	onComplete,
	onCancel,
	onChange,
	value: email,
	saveAsDefaultOption,
	saveAsDefaultValue,
	onSaveAsDefaultChange
}) => {
	const isValidEmail = useMemo(() => emailValidator.validate(email), [email]);

	const handleClick = useCallback(() => {
		onComplete(email);
	}, [email, onComplete]);

	const handleChange = useCallback(
		e => compose(onChange, path(["target", "value"]))(e),
		[onChange]
	);

	const handleSaveAsDefaultChange = useCallback(
		e => compose(onSaveAsDefaultChange, path(["target", "checked"]))(e),
		[onSaveAsDefaultChange]
	);

	return (
		<Container>
			{label && <Label>{label}</Label>}

			<FormGroup>
				<FormControlContainer>
					<FormControl
						type="email"
						inputMode="email"
						value={email}
						onChange={handleChange}
						isValid={isValidEmail}
						autoFocus
					/>
				</FormControlContainer>

				<ButtonContainer>
					<Button onClick={onCancel} size="small" color="gray">
						Cancel
					</Button>

					<Button
						onClick={handleClick}
						size="small"
						disabled={!isValidEmail}
					>
						{buttonLabel}
					</Button>
				</ButtonContainer>
			</FormGroup>

			{saveAsDefaultOption && (
				<FormGroup>
					<FormCheck
						tyoe="checkbox"
						name="save-email"
						id="save-email"
						label="Save as default email"
						checked={saveAsDefaultValue}
						onChange={handleSaveAsDefaultChange}
					/>
				</FormGroup>
			)}
		</Container>
	);
};

EmailForm.defaultProps = {
	label: "Email",
	buttonLabel: "Save"
};

export default EmailForm;
