import { EmptyState, View } from "modules/core";
import React from "react";
import { useTheme } from "styled-components";

const SessionExpiredScreen = () => {
	const theme = useTheme();

	return (
		<View>
			<EmptyState
				label="Session expired"
				icon="alert-circle-outline"
				iconColor={theme.colorBitterSweetRed}
			/>
		</View>
	);
};

export default SessionExpiredScreen;
