import { cartRepo } from "data";
import { curry, map } from "ramda";
import itemDisplayMessages from "./item-display-messages";
import refreshCart from "./refresh-cart";

const mapCartItemsToDisplayItems = curry(
	(cart, setCart, setCartLoading, items) =>
		map(
			i => ({
				key: i.cartItemId,
				name: i.product.name,
				quantity: i.quantity,
				price: i.productPrice,
				messages: itemDisplayMessages(i),
				actions: [
					...(!i.mandatory
						? [
								{
									handler: async () => {
										const { cartId } = cart;
										await cartRepo.removeItem({
											cartId,
											cartItemId: i.cartItemId
										});
										refreshCart(
											setCart,
											setCartLoading,
											cartId
										);
									},
									icon: "trash-can"
								}
						  ]
						: [])
				]
			}),
			items
		)
);

export default mapCartItemsToDisplayItems;
