import { Tile as CoreTile } from "modules/core";
import styled from "styled-components";

const Tile = styled(CoreTile)`
	height: 48px;
	margin-bottom: ${p => p.theme.spaceSmall};
	display: flex;

	&:last-child {
		margin-bottom: 0;
	}
`;

const TileInner = styled.div`
	position: relative;
	flex-grow: 1;
	display: flex;
	align-items: center;
`;

const IconContainer = styled.div`
	width: 30px;
	margin-right: ${p => p.theme.spaceSmall};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Label = styled.div`
	font-size: 13.5px;
	line-height: 1.4;
`;

export { Tile, TileInner, IconContainer, Label };
