import { deliveryRepo } from "data";
import { useCallback, useEffect, useState } from "react";

const useDelivery = ({ orderId }) => {
	const [delivery, setDelivery] = useState();
	const [error, setError] = useState();

	const fetchDelivery = useCallback(async ({ orderId }) => {
		try {
			if (!orderId) {
				return [false];
			}

			const [fetchedDilvery] = await deliveryRepo.getByOrderId({
				orderId
			});
			setDelivery(fetchedDilvery);
			return fetchedDilvery;
		} catch (e) {
			setError(e);
		}
	}, []);

	useEffect(() => {
		fetchDelivery({ orderId });
	}, [fetchDelivery, orderId]);

	return [delivery, error, fetchDelivery];
};

export default useDelivery;
