import { contactChannelRepo } from "data";
import { compose, path } from "ramda";
import React, { useCallback, useMemo, useState } from "react";
import { FormControl } from "react-bootstrap";
import emailValidator from "email-validator";
import ContactChannelModal from "./ContactChannelModal";

const EmailModal = ({ isOpen, onClose, fetchUserContactChannels }) => {
	const [email, setEmail] = useState("");
	const isValidEmail = useMemo(() => emailValidator.validate(email), [email]);

	const save = useCallback(
		() =>
			contactChannelRepo.save({
				type: "EMAIL",
				identifier: email,
				consented: true
			}),
		[email]
	);

	const handleChange = useCallback(
		e => compose(setEmail, path(["target", "value"]))(e),
		[]
	);

	return (
		<ContactChannelModal
			heading="Email"
			isOpen={isOpen}
			onClose={onClose}
			onSave={save}
			fetchUserContactChannels={fetchUserContactChannels}
			disabled={!isValidEmail}
		>
			<FormControl
				type="email"
				inputMode="email"
				value={email}
				onChange={handleChange}
				isValid={isValidEmail}
				autoFocus
			/>
		</ContactChannelModal>
	);
};

export default EmailModal;
