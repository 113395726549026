import { AuthProvider } from "modules/auth";
import StripeProvider from "modules/payment/components/StripeProvider";
import React from "react";
import Router from "router";
import { StateProvider } from "state";
import { ThemeProvider } from "styled-components";
import theme from "styles/theme";
import { AnalyticsProvider } from "modules/analytics";

const Providers = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<StripeProvider>
					<AnalyticsProvider>
						<StateProvider>
							<AuthProvider>{children}</AuthProvider>
						</StateProvider>
					</AnalyticsProvider>
				</StripeProvider>
			</Router>
		</ThemeProvider>
	);
};

export default Providers;
