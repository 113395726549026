import { Button as CoreButton } from "modules/core";
import React from "react";
import styled from "styled-components";

const StyledCoreButton = styled(CoreButton)`
	margin-bottom: ${p => p.theme.spaceSmall};
`;

const Button = ({ label, ...props }) => {
	return (
		<StyledCoreButton color="white" {...props}>
			{label}
		</StyledCoreButton>
	);
};

export default Button;
