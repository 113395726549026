import config from "../config";
import httpClient from "./http-client";

const eventRepo = {
	createOrUpdateEvent: payload =>
		httpClient.fetch(`${config.API_BASE_URL}/events`, {
			method: "PUT",
			body: JSON.stringify(payload)
		}),
	getEvent: eventId =>
		httpClient.fetch(`${config.API_BASE_URL}/events/${eventId}`),
	getTimeSlots: ({ eventId, storeId }) =>
		httpClient.fetch(
			`${config.API_BASE_URL}/events/${eventId}/time-slots?storeId=${storeId}`,
			{
				method: "GET"
			}
		)
};

export default eventRepo;
