import React from "react";
import { Icon as CoreIcon } from "modules/core";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${p => p.theme.spaceMedium} 0;
`;

const Icon = styled(CoreIcon)`
	margin-right: ${p => p.theme.spaceSmall};
`;

const Text = styled.div``;

const Message = ({ icon, iconColor, children }) => {
	return (
		<Container>
			<Icon size="20" name={icon} color={iconColor} />
			<Text>{children}</Text>
		</Container>
	);
};

export default Message;
