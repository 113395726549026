import { all, anyPass, is, when } from "ramda";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "../Button";
import ButtonWrapper from "./ButtonWrapper";
import Container from "./Container";
import Content from "./Content";
import Main from "./Main";
import Footer from "./Footer";
import Header from "./Header";
import Heading from "./Heading";
import Loader from "./Loader";
import Text from "./Text";

const Modal = ({
	heading,
	isOpen,
	onClose,
	confirmButtonLabel,
	cancelButtonLabel,
	children,
	onConfirm,
	onCancel,
	disabled,
	cancelDisabled,
	backdropClassName,
	hideFooter,
	closeButton,
	isLoading,
	tmpDontCloseOnConfirm // TODO: remove
}) => {
	const renderChildren = when(
		anyPass([is(String), when(is(Array), all(is(String)))]),
		c => <Text>{c}</Text>
	);

	const handleConfirm = () => {
		onConfirm();
		!tmpDontCloseOnConfirm && onClose();
	};

	const handleCancel = () => {
		onCancel();
		onClose();
	};

	return (
		<BootstrapModal
			show={isOpen}
			onHide={onClose}
			animation={false}
			centered
			{...{ backdropClassName }}
		>
			<Container>
				<Content>
					<Header onClose={onClose} closeButton={closeButton}>
						<Heading>{heading}</Heading>
					</Header>

					<Main>
						{renderChildren(children)}

						{isLoading && <Loader />}
					</Main>
				</Content>

				{!hideFooter && (
					<Footer>
						<ButtonWrapper left>
							<Button
								onClick={handleCancel}
								size="small"
								color="gray"
								disabled={cancelDisabled}
							>
								{cancelButtonLabel}
							</Button>
						</ButtonWrapper>

						<ButtonWrapper right>
							<Button
								onClick={handleConfirm}
								size="small"
								disabled={disabled}
							>
								{confirmButtonLabel}
							</Button>
						</ButtonWrapper>
					</Footer>
				)}
			</Container>
		</BootstrapModal>
	);
};

Modal.defaultProps = {
	heading: "Confirm",
	isOpen: false,
	confirmButtonLabel: "Confirm",
	cancelButtonLabel: "Cancel",
	onClose: () => {},
	onConfirm: () => {},
	onCancel: () => {}
};

export default Modal;
