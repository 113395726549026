import { Checkin, useCheckin } from "modules/checkin";
import { View, ViewContainer } from "modules/core";
import React from "react";
import { useQuery } from "utils";

const CheckinView = () => {
	const { checkinId, eventId } = useQuery();
	const [checkin, error] = useCheckin(checkinId);

	return (
		<View>
			<ViewContainer>
				<Checkin {...{ checkin, eventId, error }} />
			</ViewContainer>
		</View>
	);
};

export default CheckinView;
