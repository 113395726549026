import styled from "styled-components";

const Main = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const ProductInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

const Name = styled.h3`
	font-size: 13.5px;
	line-height: 1.25;
	font-weight: 500;
	flex-grow: 1;
	margin-bottom: ${p => p.theme.spaceMini};
`;

const PriceContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Price = styled.div`
	font-size: 12px;
	line-height: 1;
`;

const OldPrice = styled(Price)`
	text-decoration: line-through;
	margin-right: ${p => p.theme.spaceSmall};
`;

const Quantity = styled.span`
	font-size: 13.5px;
	font-weight: 500;
	margin-right: ${p => p.theme.spaceSmall};
`;

const Footer = styled.div`
	margin-top: ${p => p.theme.spaceSmall};
	margin-right: ${p => p.theme.spaceSmall};
`;

export {
	Footer,
	Main,
	Name,
	PriceContainer,
	Price,
	OldPrice,
	ProductInfo,
	Quantity
};
