import config from "config";
import { v4 as uuidv4 } from "uuid";
import { compose, unless, isNil, complement } from "ramda";

const getCookie = name => {
	const cookies = `; ${document.cookie}`;
	const parts = cookies.split(`; ${name}=`);
	return parts.length === 2 ? parts.pop().split(";").shift() : null;
};

const fromCookie = () => getCookie(config.SESSION_COOKIE_NAME);
const fromQuery = () =>
	new URLSearchParams(window.location.search).get(
		config.SESSION_QUERY_PARAMETER
	);
const storeAsCookie = session => {
	document.cookie = `${config.SESSION_COOKIE_NAME}=${session}; domain=passbuy.com; path=/; max-age=86400; SameSite=lex; Secure`;
	return session;
};

export default compose(
	storeAsCookie,
	unless(complement(isNil), uuidv4),
	unless(complement(isNil), fromCookie),
	fromQuery
);
