import { orderRepo } from "data";
import { compose, prop, union, descend, inc, sort } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSwitch } from "utils";

const sortOrdersByTimeCreated = sort(
	descend(compose(t => new Date(t), prop("timeCreated")))
);

const useUserOrders = props => {
	const { pageSize = 30 } = useMemo(() => props || {}, [props]);
	const [orders, setOrders] = useState();
	const [isFetching, startFetching, stopFetching] = useSwitch(false);
	const [page, setPage] = useState(1);
	const [isMore, setIsMore] = useState(true);

	const fetchOrders = useCallback(
		async ({ page, pageSize }) => {
			startFetching();

			try {
				const { orders: fetchedOrders } = await orderRepo.getUserOrders(
					{
						page,
						pageSize
					}
				);

				const updateFn = compose(
					sortOrdersByTimeCreated,
					union(fetchedOrders)
				);
				setOrders(updateFn);
				setIsMore(fetchedOrders.length === pageSize);
			} finally {
				stopFetching();
			}
		},
		[startFetching, stopFetching]
	);

	useEffect(() => {
		fetchOrders({ page, pageSize });
	}, [fetchOrders, page, pageSize]);

	const fetchNextPage = useCallback(() => setPage(inc), []);

	return [orders, isFetching, fetchNextPage, isMore];
};

export default useUserOrders;
