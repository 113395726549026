import React from "react";
import { ScreenHeader, Section, SectionLabel } from "modules/core";
import { UserOrderHistory } from "modules/order";
import { Container } from "./Account.styles";
import { UserPaymentMethods } from "modules/payment";

const Account = ({ onClose }) => {
	return (
		<Container>
			<ScreenHeader heading="Account" onClose={onClose} />

			<Section clear>
				<SectionLabel vertical>Payment methods</SectionLabel>
				<UserPaymentMethods />
			</Section>

			<Section clear>
				<SectionLabel vertical>Order history</SectionLabel>
				<UserOrderHistory />
			</Section>
		</Container>
	);
};

export default Account;
