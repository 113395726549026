import { useUserOrders } from "modules/order/hooks";
import React, { useMemo } from "react";
import { Container } from "./UserOrderHistory.styles";
import { isEmpty, map } from "ramda";
import MoreButton from "./MoreButton";
import Skeleton from "./Skeleton";
import Order from "./Order";
import { EmptyState as CoreEmptyState } from "modules/core";
import styled from "styled-components";

const EmptyState = styled(CoreEmptyState)`
	margin-top: ${p => p.theme.spaceGiant};
	padding-top: ${p => p.theme.spaceGiant};
`;

const pageSize = 30;

const UserOrderHistory = () => {
	const [orders, isFetching, fetchMoreOrders, isMore] = useUserOrders({
		pageSize
	});
	const orderComps = useMemo(
		() => map(o => <Order order={o} key={o.orderId} />, orders || []),
		[orders]
	);

	if (!orders) {
		return <Skeleton numOrders={pageSize} />;
	}

	return (
		<Container>
			{orderComps}

			{isEmpty(orders) && (
				<EmptyState label="No orders made" icon="shopping" />
			)}

			<MoreButton
				onClick={fetchMoreOrders}
				isLoading={isFetching}
				hide={!isMore}
			/>
		</Container>
	);
};

export default UserOrderHistory;
