import { Section } from "modules/core";
import React from "react";
import NetTotal from "./NetTotal";
import VatGroups from "./VatGroups";

const Vat = ({ order }) => {
	return (
		<Section>
			<NetTotal order={order} />
			<VatGroups order={order} />
		</Section>
	);
};

export default Vat;
