import { authRepo } from "data";

const fetchCustomUserToken = async customUserTokenId => {
	if (customUserTokenId === null) {
		return null;
	}

	if (typeof customUserTokenId !== "string") {
		throw new Error("customUserTokenId is invalid");
	}

	return authRepo.getCustomUserToken(customUserTokenId);
};

export default fetchCustomUserToken;
