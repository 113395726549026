import { Icon } from "modules/core";
import React from "react";
import styled from "styled-components";

const Button = styled.div`
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 99px;
	position: absolute;
	top: ${p => p.theme.spaceMedium};
	right: ${p => p.theme.spaceMedium};
	z-index: 999;
	cursor: pointer;

	&:hover {
		background-color: rgba(0, 0, 0, 0.95);
		transition: 100ms ease-in-out;
	}
`;

const CloseButton = ({ onClick }) => {
	return (
		<Button onClick={onClick}>
			<Icon name="close" size={24} color="white" />
		</Button>
	);
};

export default CloseButton;
