import { RoundButton } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import React from "react";

const ReceiptButton = ({ order, callbackUrl }) => {
	const { navigate } = useNavigation();

	const handleClick = () =>
		order &&
		navigate(route.receipt, { orderId: order.orderId, callbackUrl });

	return <RoundButton icon="receipt" size="large" onClick={handleClick} />;
};

export default ReceiptButton;
