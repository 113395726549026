import { compose, concat, is, mergeRight, when } from "ramda";

const defaultConfig = {
	suffix: "kr",
	locales: "sv-SE",
	minimumFractionDigits: 2,
	toFixed: 2
};

const formatCurrency = (number = "", config = {}) => {
	const { suffix, locales, minimumFractionDigits, toFixed } = mergeRight(
		defaultConfig,
		config
	);

	return compose(
		p => concat(p, ` ${suffix}`),
		p =>
			new Intl.NumberFormat(locales, { minimumFractionDigits }).format(p),
		p => p.toFixed(toFixed),
		when(is(String), Number.parseFloat)
	)(number);
};

export default formatCurrency;
