import { FloatingFooter } from "modules/core";
import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import { CartButton } from "modules/cart";
import { useGlobalState } from "state";

const Container = styled(FloatingFooter)`
	@media ${mediaQuery(device.tablet)} {
		display: none;
	}
`;

const Footer = ({ menuId, menuCallbackUrl, checkinId }) => {
	const { cart } = useGlobalState();

	if (!cart) {
		return null;
	}

	return (
		<Container fixed>
			<CartButton
				{...{
					menuId,
					menuCallbackUrl,
					checkinId,
					size: "medium",
					leftIcon: true
				}}
			/>
		</Container>
	);
};

export default Footer;
