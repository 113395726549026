import React from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import { capitalize } from "utils";

const Outer = styled.div`
	background: ${p =>
		p.transparent ? "transparent" : p.theme[`color${capitalize(p.color)}`]};
	min-height: 100%;
	display: flex;

	@media ${mediaQuery(device.mobileLarge)} {
		background-color: unset;
	}
`;

const Inner = styled.div`
	flex: 1;
	min-height: 100%;
	width: 100%;
	display: flex;
`;

const View = ({ children, backgroundColor, transparent }) => {
	return (
		<Outer color={backgroundColor} transparent={transparent}>
			<CSSTransition appear in timeout={250} classNames="fade">
				<Inner>{children}</Inner>
			</CSSTransition>
		</Outer>
	);
};

View.defaultProps = {
	backgroundColor: "white"
};

export default View;
