import { isEmpty } from "ramda";
import React from "react";
import { formatCurrency, useToggle } from "utils";
import Messages from "../Messages";
import FloatingActionButtons from "../FloatingActionButtons";
import Container from "./Container";
import {
	Footer,
	Main,
	Name,
	PriceContainer,
	Price,
	OldPrice,
	ProductInfo,
	Quantity
} from "./Item.styles";

const Item = ({ item }) => {
	const { actions, name, price, oldPrice, quantity, messages } = item;
	const [isActionsOpen, toggleActions] = useToggle(false);
	const shouldRenderFooter = messages && !isEmpty(messages);
	const shouldRenderActions = !isEmpty(actions) && isActionsOpen;

	const renderFooter = () => (
		<Footer>
			<Messages messages={messages} size="small" />
		</Footer>
	);

	return (
		<Container clickable={actions?.length > 0}>
			<Main onClick={toggleActions}>
				<ProductInfo>
					<Name>{name}</Name>

					<PriceContainer>
						{oldPrice && (
							<OldPrice>{formatCurrency(oldPrice)}</OldPrice>
						)}
						{price && <Price>{formatCurrency(price)}</Price>}
					</PriceContainer>
				</ProductInfo>

				<Quantity>{quantity}</Quantity>

				{shouldRenderActions && (
					<FloatingActionButtons actions={actions} />
				)}
			</Main>

			{shouldRenderFooter && renderFooter()}
		</Container>
	);
};

export default Item;
