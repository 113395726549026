import { route, useNavigation } from "modules/navigation";
import { pick } from "ramda";
import { useCallback } from "react";
import checkout from "./checkout";
import claimTabItems from "./claim-tab-items";
import validateCheckoutArgs from "./validate-checkout-args";

const useCheckout = () => {
	const { navigate } = useNavigation();

	/**
	 * @param cart Object
	 * @param tab Object
	 * @param tip Number
	 * @param paymentProvider String
	 * @param paymentMethod String
	 * @param deliveryMethod String
	 * @param callbackSuccess String
	 * @param callbackError String
	 * @param callbackCancel String
	 */
	const initCheckout = useCallback(
		async args => {
			const { callbackError, cart, tab } = args;

			if (cart && tab) {
				throw new Error("Can't checkout cart and tab at the same time");
			}

			try {
				if (tab) {
					await claimTabItems(tab);
				}

				const checkoutArgs = {
					...(cart && pick(["cartId"], cart)),
					...(tab && pick(["tabId"], tab)),
					...pick(
						[
							"tip",
							"paymentProvider",
							"paymentProviderArgs",
							"callbackSuccess",
							"callbackError",
							"callbackCancel"
						],
						args
					)
				};

				validateCheckoutArgs(checkoutArgs);

				const { order, payment } = await checkout(checkoutArgs);

				const navigationArgs = {
					...pick(
						["callbackSuccess", "callbackError", "callbackCancel"],
						args
					),
					...pick(["orderId"], order),
					...pick(["paymentId"], payment)
				};

				navigate(route.checkout, navigationArgs);
			} catch (error) {
				console.error(`Checkout error`, error);
				navigate(route.checkoutError, { callbackError });
			}
		},
		[navigate]
	);

	return initCheckout;
};

export default useCheckout;
