import queryString from "query-string";
import { compose, curry, defaultTo, isNil, mergeRight, reject } from "ramda";

const patchQueryParams = curry((query, queryUpdate) =>
	compose(
		queryString.stringify,
		reject(isNil),
		mergeRight(query),
		defaultTo({})
	)(queryUpdate)
);

export default patchQueryParams;
