import React from "react";
import styled from "styled-components";
import {
	compose,
	reduce,
	reject,
	isNil,
	concat,
	dropLast,
	isEmpty
} from "ramda";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
`;

const Item = styled.span`
	font-size: 12px;
	line-height: 1.4;
`;

const Spacer = styled.div`
	margin-left: ${p => p.theme.spaceMini};
	margin-right: ${p => p.theme.spaceMini};
	background-color: ${p => p.theme.colorTextMuted};
	height: 1.5px;
	width: 1.5px;
	border-radius: 1.5px;
`;

const HorizontalList = ({ items }) => {
	const renderContainer = children => <Container>{children}</Container>;
	const displayItems = reject(isNil, items);

	if (isEmpty(displayItems)) {
		return null;
	}

	return compose(
		renderContainer,
		dropLast(1),
		reduce(
			(acc, v) =>
				concat(acc, [
					<Item key={v}>{v}</Item>,
					<Spacer key={`${v}-spacer`} />
				]),
			[]
		)
	)(displayItems);
};

HorizontalList.defaultProps = {
	items: []
};

export default HorizontalList;
