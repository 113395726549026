import { Button } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	padding: ${p => p.theme.spaceLarge};
`;

const ButtonContainer = styled.div`
	width: 120px;
`;

const Footer = ({ onCancel }) => {
	const [isShowing, setIsShowing] = useState();
	const { navigate } = useNavigation();

	useEffect(() => {
		const timeout = setTimeout(() => setIsShowing(true), 1500);
		return () => clearTimeout(timeout);
	}, []);

	const openSwish = useCallback(
		() => navigate(route.CUSTOM, { url: "swish://", mode: "replace" }),
		[navigate]
	);

	if (!isShowing) {
		return null;
	}

	return (
		<Container>
			<ButtonContainer>
				<Button color="black" size="small" onClick={openSwish}>
					Open Swish
				</Button>
			</ButtonContainer>

			<ButtonContainer>
				<Button color="black" size="small" onClick={onCancel}>
					Cancel
				</Button>
			</ButtonContainer>
		</Container>
	);
};

export default Footer;
