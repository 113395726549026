import config from "config";

const checkoutCallbackUrl = ({
	orderId,
	customUserTokenId,
	callbackSuccess,
	callbackError,
	callbackCancel
}) => {
	const encodedCallbackSuccess = encodeURIComponent(callbackSuccess);
	const encodedCallbackError = encodeURIComponent(callbackError);
	const encodedCallbackCancel = encodeURIComponent(callbackCancel);

	return `${config.APP_BASE_URL}/checkout?orderId=${orderId}&paymentId=$paymentId$&customUserTokenId=${customUserTokenId}&callbackSuccess=${encodedCallbackSuccess}&callbackError=${encodedCallbackError}&callbackCancel=${encodedCallbackCancel}&isCallback=true`;
};

export default checkoutCallbackUrl;
