import React from "react";
import styled from "styled-components";
import Item from "./Item";

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: ${p => p.theme.spaceSmall};
	padding-top: ${p => p.theme.spaceSmall};
	padding-bottom: ${p => p.theme.spaceHuge};
`;

const Label = styled.div`
	color: ${p => p.theme.colorTextPrimary};
	font-weight: 500;
	font-size: 16px;
`;

const CloseButton = ({ onClick }) => {
	return (
		<Item onClick={onClick}>
			<Container>
				<Label>Close</Label>
			</Container>
		</Item>
	);
};

export default CloseButton;
