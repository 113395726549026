import { EmptyState as CoreEmptyMessage } from "modules/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
`;

const ClosedMessage = () => {
	return (
		<Container>
			<CoreEmptyMessage label="Sorry, we're closed" icon="sleep" />
		</Container>
	);
};

export default ClosedMessage;
