import { Icon } from "modules/core";
import { assoc } from "ramda";
import React, { useState } from "react";
import styled from "styled-components";
import theme from "styles/theme";
import Option from "../Option";
import Modal from "./Modal";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 1px;
`;

const OptionCustom = props => {
	const [isModalOpen, setIsModalOpen] = useState();
	const { selected, option } = props;
	const amount = option?.amount;

	const color = selected ? theme.colorTextWhite : theme.colorTextPrimary;

	const handleClick = () => setIsModalOpen(true);

	const handleConfirm = a => {
		const optionWithAmount = assoc("amount", a, props.option);
		const selection = a > 0 ? optionWithAmount : {};
		props.onSelect(selection);
	};

	const closeModal = () => setIsModalOpen(false);

	return (
		<>
			<Option {...{ ...props, onSelect: handleClick }}>
				<Container>
					<Icon name="square-edit-outline" size={20} color={color} />
				</Container>
			</Option>

			<Modal
				amount={amount}
				onConfirm={handleConfirm}
				isOpen={isModalOpen}
				onClose={closeModal}
			/>
		</>
	);
};

export default OptionCustom;
