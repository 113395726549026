import { pick } from "ramda";
import config from "../config";
import httpClient from "./http-client";

const checkoutRepo = {
	/**
	 * @param {{tabId}} args
	 */
	checkout: async (args = {}) => {
		const body = pick(["tabId", "cartId", "deliveryMethod"], args);

		if (!body.tabId && !body.cartId) {
			throw new Error("tabId or cartId is required");
		}

		return httpClient.fetch(`${config.API_BASE_URL}/checkout`, {
			method: "POST",
			body: JSON.stringify(body)
		});
	},
	/**
	 * @param {{orderId, provider, providerArgs}} args
	 */
	createPayment: async (args = {}) => {
		const body = pick(["orderId", "provider", "providerArgs", "tip"], args);

		if (!body.orderId) {
			throw new Error("orderId is required");
		}

		if (!body.provider) {
			throw new Error("provider is required");
		}

		if (!body.providerArgs) {
			throw new Error("providerArgs is required");
		}

		return httpClient.fetch(`${config.API_BASE_URL}/payments`, {
			method: "POST",
			body: JSON.stringify(body)
		});
	},
	getPayment: paymentId => {
		if (!paymentId) {
			throw new Error("paymentId is required");
		}

		return httpClient.fetch(`${config.API_BASE_URL}/payments/${paymentId}`);
	},
	cancelPayment: ({ paymentId }) => {
		if (!paymentId) {
			throw new Error("paymentId is required");
		}

		return httpClient.fetch(
			`${config.API_BASE_URL}/payments/${paymentId}`,
			{
				method: "PATCH",
				body: JSON.stringify({ status: "CANCELED" })
			}
		);
	}
};

export default checkoutRepo;
