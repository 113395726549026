import { AddToCartButton } from "modules/cart";
import { FloatingFooter } from "modules/core";
import { compose, flatten, pluck, prop, reduce } from "ramda";
import React, { useEffect, useMemo, useState } from "react";
import Body from "./Body";
import Cover from "./Cover";
import { Container } from "./Item.styles";

const Item = ({ item, productOptions, onClose, checkinId, eventId }) => {
	const { product } = item;
	const { imageUrl } = product;
	const [note, setNote] = useState("");
	const [quantity, setQuantity] = useState(1);
	const [selectedProductOptions, setSelectedProductOptions] = useState(null);
	const cartItem = useMemo(
		() => ({ note, quantity, productId: product.productId }),
		[product, note, quantity]
	);

	useEffect(() => {
		if (!productOptions || !productOptions.sections) return;

		compose(
			setSelectedProductOptions,
			reduce(
				(acc, val) => ({
					...acc,
					[val.menuItemId]: false
				}),
				{}
			),
			flatten,
			pluck("items"),
			prop("sections")
		)(productOptions);
	}, [productOptions]);

	return (
		<Container>
			{imageUrl && <Cover {...{ onClose, imageUrl }} />}

			<Body
				{...{
					item,
					productOptions,
					selectedProductOptions,
					onSelectedProductOptionChanged: setSelectedProductOptions,
					onClose,
					note,
					onNoteChanged: setNote,
					quantity,
					onQuantityChanged: setQuantity
				}}
			/>

			<FloatingFooter>
				<AddToCartButton
					{...{
						checkinId,
						eventId,
						cartItem,
						productPrice: item.priceAfterDiscount,
						productOptions: selectedProductOptions,
						onClick: onClose
					}}
				/>
			</FloatingFooter>
		</Container>
	);
};

export default Item;
