import { tabRepo } from "data";
import { useCallback, useEffect, useState } from "react";

const useTab = ({ checkinId, tabId }) => {
	const [tab, setTab] = useState();
	const [error, setError] = useState();

	const fetchTab = useCallback(async () => {
		if (!checkinId && !tabId) {
			return;
		}

		try {
			const fetchedTab = tabId
				? await tabRepo.get(tabId)
				: await tabRepo.getByCheckinId(checkinId);

			setError();
			setTab(fetchedTab);
		} catch (error) {
			const message =
				error.message === "Not found"
					? error.message
					: "Failed to fetch";

			setError(new Error(message));
		}
	}, [checkinId, tabId]);

	useEffect(() => {
		fetchTab();
	}, [fetchTab]);

	return [tab, error, fetchTab];
};

export default useTab;
