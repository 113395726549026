import React from "react";
import theme from "styles/theme";
import ViewContainer from "../ViewContainer";
import Footer from "./Footer";
import Header from "./Header";

const CheckoutError = () => {
	return (
		<ViewContainer
			header={<Header />}
			heading="Failed"
			message="Payment failed"
			footer={<Footer />}
			colors={[theme.colorBitterSweetRed, theme.colorBitterSweetRed]}
		/>
	);
};

export default CheckoutError;
