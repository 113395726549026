import React from "react";
import { useRedirectToApp } from "../hooks";
import { Content, ErrorScreen, Skeleton } from "./components";

const Checkin = ({ checkin, eventId, error }) => {
	const { isRedirecting } = useRedirectToApp({ checkin, eventId });

	if (error) {
		return <ErrorScreen error={error} />;
	}

	if (!checkin || isRedirecting) {
		return <Skeleton />;
	}

	return <Content checkin={checkin} />;
};

export default Checkin;
