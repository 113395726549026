import { always, compose, cond, defaultTo, equals, prop } from "ramda";
import React, { useMemo } from "react";
import { Status as StatusComp } from "../components";

const deliveryToStatus = compose(
	cond([
		[equals("CREATED"), always("Placing your order")],
		[equals("RECEIVED"), always("Preparing your order")],
		[equals("READY_FOR_PICKUP"), always("Ready for pickup")],
		[equals("DELIVERED"), always("Pickup completed")],
		[equals("ERROR"), always("Something went wrong")]
	]),
	prop("status"),
	defaultTo({})
);

const Status = ({ delivery }) => {
	const status = useMemo(() => deliveryToStatus(delivery), [delivery]);
	return <StatusComp>{status}</StatusComp>;
};

export default Status;
