import React from "react";
import styled from "styled-components";
import CoreLoader from "../Loader";

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Loader = () => {
	return (
		<Container>
			<CoreLoader size="small" />
		</Container>
	);
};

export default Loader;
