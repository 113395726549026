import { compose, map, prop } from "ramda";
import React from "react";
import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
	margin-top: ${p => p.theme.spaceHuge};
	display: flex;
	flex-direction: column;
`;

const Buttons = compose(
	btns => <Container>{btns}</Container>,
	map(props => <Button {...{ ...props, color: "white" }} />),
	prop("contactChannels")
);

export default Buttons;
