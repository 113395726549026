import { paymentMethodRepo } from "data";
import { andThen, compose, prop, sortBy, tap } from "ramda";
import { useCallback, useEffect } from "react";
import { useGlobalState } from "state";
import { useSwitch } from "utils";

const usePaymentMethods = checkinId => {
	const { setPaymentMethods, paymentMethods } = useGlobalState();
	const [isPaymentMethodsLoading, startLoading, stopLoading] =
		useSwitch(false);

	const fetchPaymentMethods = useCallback(() => {
		startLoading();

		try {
			return compose(
				andThen(tap(stopLoading)),
				andThen(tap(setPaymentMethods)),
				andThen(sortBy(prop("sortOrder"))),
				paymentMethodRepo.getPaymentMethods
			)({ checkinId });
		} catch {
			stopLoading();
		}
	}, [setPaymentMethods, checkinId, startLoading, stopLoading]);

	useEffect(() => {
		fetchPaymentMethods();
	}, [fetchPaymentMethods, setPaymentMethods, checkinId]);

	return { paymentMethods, fetchPaymentMethods, isPaymentMethodsLoading };
};

export default usePaymentMethods;
