import React from "react";
import styled from "styled-components";
import CloseButton from "./CloseButton";
import Items from "./Items";
import PaymentMethodsHOC from "./PaymentMethodsHOC";

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const PaymentMethods = ({ items, onClose }) => {
	return (
		<Container>
			<Items items={items} />

			<CloseButton onClick={onClose} />
		</Container>
	);
};

export default PaymentMethodsHOC(PaymentMethods);
