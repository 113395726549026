import { compose, ifElse, prop } from "ramda";
import findItemByProductIdOrBarcode from "./find-item-by-product-id-or-barcode";
import generateCartItemId from "./generate-cart-item-id";
import shouldCreateNewItem from "./should-create-new-item";

const findOrGenerateCartItemId = ifElse(
	shouldCreateNewItem,
	() => generateCartItemId(),
	compose(prop("cartItemId"), findItemByProductIdOrBarcode)
);

export default findOrGenerateCartItemId;
