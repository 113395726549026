import { ViewOverlay } from "modules/core";
import { route, useNavigation } from "modules/navigation";
import { useOrder } from "modules/order";
import { Receipt, ReceiptViewHeader } from "modules/receipt";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import { useQuery } from "utils";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-top: 50px;

	@media ${mediaQuery(device.tablet)} {
		padding-top: 0;
	}
`;

const AccountOrderView = () => {
	const { navigate } = useNavigation();
	const { orderId } = useParams();
	const { callbackUrl } = useQuery();
	const order = useOrder(orderId);

	const handleClose = () =>
		navigate(route.CUSTOM, { url: callbackUrl, uriEncoded: true });

	return (
		<ViewOverlay onClose={handleClose}>
			<Container>
				<ReceiptViewHeader onClose={handleClose} />
				<Receipt order={order} />
			</Container>
		</ViewOverlay>
	);
};

export default AccountOrderView;
