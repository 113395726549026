import messengerIconSvg from "assets/icons/messenger.svg";
import React from "react";
import styled from "styled-components";

const Logo = styled.img`
	height: 24px;
	width: auto;
	user-select: none;
`;

const MessengerIcon = () => {
	return <Logo src={messengerIconSvg}></Logo>;
};

export default MessengerIcon;
