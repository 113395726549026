import { eventRepo } from "data";
import { Section, SectionLabel, Messages, OptionButtons } from "modules/core";
import { compose, find, map, propEq } from "ramda";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const MessageContainer = styled.div`
	display: flex;
	margin-top: ${p => p.theme.spaceMedium};
	align-items: center;
	justify-content: space-between;
`;

const timeSlotToOption = (ts = {}) => ({
	optionId: ts.eventTimeSlotId,
	label: ts.timeSlot,
	disabled: !ts.isAvailable
});

const TimeSlotPicker = ({
	eventId,
	storeId,
	eventTimeSlot,
	onEventTimeSlotChanged
}) => {
	const [timeSlots, setTimeSlots] = useState({
		timeSlots: []
	});

	useEffect(() => {
		eventRepo.getTimeSlots({ eventId, storeId }).then(setTimeSlots);
	}, [eventId, storeId]);

	const options = useMemo(
		() => map(timeSlotToOption, timeSlots.timeSlots),
		[timeSlots.timeSlots]
	);

	const handleOptionSelected = compose(
		onEventTimeSlotChanged,
		({ optionId }) =>
			find(propEq("eventTimeSlotId", optionId), timeSlots.timeSlots)
	);

	return (
		<>
			<Section>
				<Container>
					<SectionLabel>Time slot</SectionLabel>
					<OptionButtons
						options={options}
						selectedOption={timeSlotToOption(eventTimeSlot)}
						onOptionSelected={handleOptionSelected}
					/>
				</Container>

				<MessageContainer>
					{timeSlots.message && (
						<Messages
							messages={[
								{
									type: "info",
									description: timeSlots.message
								}
							]}
						/>
					)}
				</MessageContainer>
			</Section>
		</>
	);
};

export default TimeSlotPicker;
