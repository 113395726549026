import { useMemo } from "react";
import useWindowSize from "./use-window-size";

const useBreakpoint = device => {
	const { width } = useWindowSize();

	return useMemo(() => width > device, [width, device]);
};

export default useBreakpoint;
