import React from "react";
import styled from "styled-components";
import { formatCurrency } from "utils";
import Label from "./Label";
import Text from "./Text";

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom: ${p => p.theme.spaceTiny};
`;

const NetTotal = ({ order }) => {
	const { netTotal } = order;

	if (!netTotal) {
		return null;
	}

	return (
		<Container>
			<Label>Net total</Label>
			<Text>{formatCurrency(netTotal)}</Text>
		</Container>
	);
};

export default NetTotal;
