import { Icon } from "modules/core";
import React from "react";
import theme from "styles/theme";
import { useDimensions } from "utils";
import {
	Body,
	Container,
	Label,
	Message,
	StatusDot,
	StatusDotInner,
	Timeline
} from "./Event.styles";

const Event = ({
	status,
	first,
	last,
	label,
	errorMessage,
	completedMessage
}) => {
	const [containerRef, { height }] = useDimensions(0);
	const iconName = status === "completed" ? "check" : "close";

	return (
		<Container ref={containerRef} first={first} last={last}>
			{!first && <Timeline height={height} status={status} />}

			<StatusDot>
				<StatusDotInner status={status}>
					<Icon name={iconName} size={20} color={theme.colorWhite} />
				</StatusDotInner>
			</StatusDot>

			<Body>
				<Label>{label}</Label>

				{status === "error" && <Message>{errorMessage}</Message>}
				{status === "completed" && (
					<Message>{completedMessage}</Message>
				)}
			</Body>
		</Container>
	);
};

export default Event;
