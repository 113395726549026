import {
	getAnalytics,
	setUserId as firebaseSetUserId
} from "firebase/analytics";
import getSession from "./get-session";

const setUserId = userId => {
	const { postSession } = require("data/analytics-repo.js").default;
	const analytics = getAnalytics();
	const session = getSession();
	firebaseSetUserId(analytics, userId);
	postSession({ session, userId });
};

export default setUserId;
