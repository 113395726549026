import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-bottom: 70px; // Floating footer height
`;

const Header = styled.header`
	padding: ${p => p.theme.spaceMedium} ${p => p.theme.spaceMedium}
		${p => p.theme.spaceSmall};
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	min-width: 0;
	overflow: hidden;
`;

const Name = styled.div`
	font-size: 32px;
	line-height: 1.1;
	flex-shrink: 1;
	color: ${p => p.theme.colorTextPrimary};
	font-family: Peak-Semibold;
	font-weight: 500;
`;

const MainActions = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: ${p => p.theme.spaceSmall};
`;

export { Container, Header, Name, MainActions };
