import { paymentMethodRepo } from "data";
import { FloatingActionButtons } from "modules/core";
import React, { useCallback, useMemo } from "react";
import { useSwitch, useToggle } from "utils";
import PaymentMethodIcon from "../../PaymentMethodIcon";
import { Tile, TileInner, IconContainer, Label } from "./PaymentMethod.styles";
import RemoveModal from "./RemoveModal";

const PaymentMethod = ({ paymentMethod, onRemove }) => {
	const { paymentMethodId, label, icon, type } = paymentMethod;
	const [isActionsOpen, toggleActions] = useToggle(false);
	const [isRemoveModalOpen, openRemoveModal, closeRemoveModal] = useSwitch();
	const [isLoading, startLoading, stopLoading] = useSwitch();
	const isCard = useMemo(() => type === "CARD", [type]);
	const actions = useMemo(
		() => [
			{
				handler: openRemoveModal,
				icon: "trash-can"
			}
		],
		[openRemoveModal]
	);

	const removePaymentMethod = useCallback(async () => {
		startLoading();

		try {
			await paymentMethodRepo.removePaymentMethod(paymentMethodId);
			closeRemoveModal();
			onRemove(paymentMethod);
		} finally {
			stopLoading();
		}
	}, [
		paymentMethodId,
		onRemove,
		startLoading,
		stopLoading,
		closeRemoveModal,
		paymentMethod
	]);

	return (
		<>
			<Tile onClick={toggleActions}>
				<TileInner>
					<IconContainer>
						<PaymentMethodIcon name={icon} />
					</IconContainer>

					<Label>{label}</Label>

					{isCard && isActionsOpen && (
						<FloatingActionButtons actions={actions} />
					)}
				</TileInner>
			</Tile>

			<RemoveModal
				{...{
					isOpen: isRemoveModalOpen,
					onClose: closeRemoveModal,
					onConfirm: removePaymentMethod,
					isLoading,
					paymentMethod
				}}
			/>
		</>
	);
};

export default PaymentMethod;
