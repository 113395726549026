import { any, isNil } from "ramda";

const validateCheckoutArgs = ({
	tabId,
	cartId,
	paymentProvider,
	paymentProviderArgs,
	callbackSuccess,
	callbackError,
	callbackCancel
}) => {
	const isAnyMandatoryArgNil = any(isNil, [
		paymentProvider,
		paymentProviderArgs,
		callbackSuccess,
		callbackError,
		callbackCancel
	]);

	if (isAnyMandatoryArgNil) {
		throw new Error("checkout args is not valid");
	}

	const isOptionalParametersCorrect = !!tabId || !!cartId;

	if (!isOptionalParametersCorrect) {
		throw new Error("checkout args is not valid");
	}
};

export default validateCheckoutArgs;
