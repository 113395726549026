const config = {
	local: {
		ENV_NAME: "local",
		API_BASE_URL:
			process.env.REACT_APP_API_BASE_URL || "https://api.dev.passbuy.com",
		PUBLIC_API_BASE_URL:
			process.env.REACT_APP_API_BASE_URL ||
			"https://public-api.dev.passbuy.com",
		APP_BASE_URL: process.env.REACT_APP_APP_BASE_URL,
		STRIPE_PUBLIC_KEY:
			"pk_test_51IIbcSBxaNBcEZyAHNh4V9eDX4GhqzGnPqbcYTrd2yBxPoo4jbous1DeGfgfFInyzKJiQXISPl8PUCfuXx1rc6qK001UwHqCfS",
		DEFAULT_STORE_IMAGE_URL:
			"https://firebasestorage.googleapis.com/v0/b/way-development.appspot.com/o/merchant-images%2Fstore-default-small.png?alt=media&token=10019991-6ee0-419e-8a00-318ee36625c0",
		SESSION_QUERY_PARAMETER: "session",
		SESSION_COOKIE_NAME: "session",
		FIREBASE: {
			apiKey: "AIzaSyADqgAsFC6rHVigZHxTHkSf9t7emU1XXyc",
			authDomain: "way-development.firebaseapp.com",
			databaseURL: "https://way-development.firebaseio.com",
			projectId: "way-development",
			storageBucket: "way-development.appspot.com",
			messagingSenderId: "165198280014",
			appId: "1:165198280014:web:201cdc1e524ce09d3b5a1f",
			measurementId: "G-18EPZ2WCC4"
		},
		FACEBOOK_MESSENGER_BOT_URL: "https://m.me/passbuyhq",
		COMPANY_WEBSITE_URL: "https://passbuy.com",
		SWISH_QR_CODE_URL:
			"https://api.dev.passbuy.com/payment-methods/swish/qr",
		CORS_PROXY_URL: "https://blooming-sierra-77953.herokuapp.com"
	},
	development: {
		ENV_NAME: "development",
		API_BASE_URL: "https://api.dev.passbuy.com",
		PUBLIC_API_BASE_URL: "https://public-api.dev.passbuy.com",
		APP_BASE_URL:
			process.env.REACT_APP_APP_BASE_URL || "https://app.dev.passbuy.com",
		STRIPE_PUBLIC_KEY:
			"pk_test_51IIbcSBxaNBcEZyAHNh4V9eDX4GhqzGnPqbcYTrd2yBxPoo4jbous1DeGfgfFInyzKJiQXISPl8PUCfuXx1rc6qK001UwHqCfS",
		DEFAULT_STORE_IMAGE_URL:
			"https://firebasestorage.googleapis.com/v0/b/way-development.appspot.com/o/merchant-images%2Fstore-default-small.png?alt=media&token=10019991-6ee0-419e-8a00-318ee36625c0",
		SESSION_QUERY_PARAMETER: "session",
		SESSION_COOKIE_NAME: "session",
		FIREBASE: {
			apiKey: "AIzaSyADqgAsFC6rHVigZHxTHkSf9t7emU1XXyc",
			authDomain: "way-development.firebaseapp.com",
			databaseURL: "https://way-development.firebaseio.com",
			projectId: "way-development",
			storageBucket: "way-development.appspot.com",
			messagingSenderId: "165198280014",
			appId: "1:165198280014:web:201cdc1e524ce09d3b5a1f",
			measurementId: "G-18EPZ2WCC4"
		},
		FACEBOOK_MESSENGER_BOT_URL: "https://m.me/passbuyhq",
		COMPANY_WEBSITE_URL: "https://passbuy.com",
		SWISH_QR_CODE_URL:
			"https://api.dev.passbuy.com/payment-methods/swish/qr"
	},
	staging: {
		ENV_NAME: "staging",
		API_BASE_URL: "https://api.staging.passbuy.com",
		PUBLIC_API_BASE_URL: "https://public-api.staging.passbuy.com",
		APP_BASE_URL: "https://app.staging.passbuy.com",
		STRIPE_PUBLIC_KEY: "pk_test_ESt5MzonmAnTHkZC7YVCn8x900TF3FURg2",
		DEFAULT_STORE_IMAGE_URL:
			"https://firebasestorage.googleapis.com/v0/b/way-staging-123.appspot.com/o/merchant-images%2Fstore-default-small.png?alt=media&token=cc723b77-eb73-401a-8968-2bbf6802e474",
		SESSION_QUERY_PARAMETER: "session",
		SESSION_COOKIE_NAME: "session",
		FIREBASE: {
			apiKey: "AIzaSyCg1MKUMfM3xfagQkSA14ocp_DzKoTd5QE",
			authDomain: "way-staging-123.firebaseapp.com",
			databaseURL: "https://way-staging-123.firebaseio.com",
			projectId: "way-staging-123",
			storageBucket: "way-staging-123.appspot.com",
			messagingSenderId: "192652521278",
			appId: "1:192652521278:web:bec7d04de1617df0d5c66f",
			measurementId: "G-PEVXVKKB0V"
		},
		FACEBOOK_MESSENGER_BOT_URL: "https://m.me/passbuyhq",
		COMPANY_WEBSITE_URL: "https://passbuy.com",
		SWISH_QR_CODE_URL:
			"https://api.staging.passbuy.com/payment-methods/swish/qr"
	},
	production: {
		ENV_NAME: "production",
		API_BASE_URL: "https://api.passbuy.com",
		PUBLIC_API_BASE_URL: "https://public-api.passbuy.com",
		APP_BASE_URL: "https://app.passbuy.com",
		STRIPE_PUBLIC_KEY: "pk_live_FtB3hoBlTkgpMOJpX4CuW9cp00V6IOHCPZ",
		DEFAULT_STORE_IMAGE_URL:
			"https://firebasestorage.googleapis.com/v0/b/way-production-730b6.appspot.com/o/store-default-small.png?alt=media&token=9d83da0e-cba6-4feb-b4f1-a9ed610eb986",
		SESSION_QUERY_PARAMETER: "session",
		SESSION_COOKIE_NAME: "session",
		FIREBASE: {
			apiKey: "AIzaSyC0nGtWUMPUzMONxX6zaRRuB2cJCu60kZg",
			authDomain: "way-production-730b6.firebaseapp.com",
			databaseURL: "https://way-production-730b6.firebaseio.com",
			projectId: "way-production-730b6",
			storageBucket: "way-production-730b6.appspot.com",
			messagingSenderId: "1064468219873",
			appId: "1:1064468219873:web:29a199a892a8395763f4a0",
			measurementId: "G-V6LSKBK9L3"
		},
		FACEBOOK_MESSENGER_BOT_URL: "https://m.me/passbuyhq",
		COMPANY_WEBSITE_URL: "https://passbuy.com",
		SWISH_QR_CODE_URL: "https://api.passbuy.com/payment-methods/swish/qr"
	}
};

export default config[process.env.REACT_APP_ENVIRONMENT] || config.development;
