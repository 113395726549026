import { RoundButton } from "modules/core";
import { compose, defaultTo, isEmpty, map } from "ramda";
import React, { useCallback, useMemo } from "react";
import { useGlobalState } from "state";
import { useSwitch } from "utils";
import { useDisplayPaymentMethods, usePaymentMethods } from "../../functions";
import AddCardModal from "../AddCardModal";
import PaymentMethod from "./PaymentMethod";
import Skeleton from "./Skeleton";
import { Container, AddCardButtonContainer } from "./UserPaymentMethods.styles";

const UserPaymentMethods = ({ checkinId }) => {
	const { paymentMethod, setPaymentMethod } = useGlobalState();
	const [isModal, openModal, closeModal] = useSwitch(false);
	const { paymentMethods, isPaymentMethodsLoading, fetchPaymentMethods } =
		usePaymentMethods(checkinId);
	const displayPaymentMethods = useDisplayPaymentMethods(paymentMethods);
	const isAddButton = useMemo(
		() => displayPaymentMethods && isEmpty(displayPaymentMethods),
		[displayPaymentMethods]
	);

	const handleRemove = useCallback(
		pm => {
			fetchPaymentMethods();

			if (paymentMethod.paymentMethodId === pm.paymentMethodId) {
				setPaymentMethod();
			}
		},
		[fetchPaymentMethods, paymentMethod, setPaymentMethod]
	);

	const paymentMethodComps = useMemo(
		() =>
			compose(
				map(pm => (
					<PaymentMethod
						paymentMethod={pm}
						onRemove={handleRemove}
						key={pm.paymentMethodId}
					/>
				)),
				defaultTo([])
			)(displayPaymentMethods),
		[displayPaymentMethods, handleRemove]
	);

	if (isPaymentMethodsLoading) {
		return <Skeleton numPaymentMethods={2} />;
	}

	return (
		<>
			<Container>
				{paymentMethodComps}

				{isAddButton && (
					<AddCardButtonContainer>
						<RoundButton icon="plus" onClick={openModal} />
					</AddCardButtonContainer>
				)}
			</Container>

			<AddCardModal
				{...{
					isOpen: isModal,
					onCardAdded: fetchPaymentMethods,
					onClose: closeModal
				}}
			/>
		</>
	);
};

export default UserPaymentMethods;
