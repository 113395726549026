import { useCheckin } from "modules/checkin";
import { ScreenFooter, View, ViewContainer } from "modules/core";
import Menu, { useMenu } from "modules/menu";
import { route, useNavigation } from "modules/navigation";
import React, { useMemo } from "react";
import { RouterView } from "router";
import styled from "styled-components";
import { device } from "styles/device";
import { useBreakpoint, useQuery } from "utils";
import useChildRoutes from "./use-child-routes";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
	flex: 1;
`;

const MenuView = () => {
	const { checkinId, callbackUrl } = useQuery();
	const [menu, isLoading] = useMenu({ checkinId });
	const { buildUrl } = useNavigation();
	const [checkin] = useCheckin(checkinId);
	const childRoutes = useChildRoutes({ menu });
	const isTabletBreakpoint = useBreakpoint(device.tablet);

	const menuCallbackUrl = useMemo(
		() =>
			menu &&
			buildUrl(route.menu, {
				menuId: menu.menuId,
				checkinId,
				callbackUrl
			}),
		[menu, buildUrl, checkinId, callbackUrl]
	);
	const headerProps = useMemo(
		() => ({
			menuId: menu?.menuId,
			checkin,
			checkinId,
			menuCallbackUrl,
			callbackUrl
		}),
		[checkin, menuCallbackUrl, callbackUrl, menu, checkinId]
	);

	if (!checkin) {
		return null;
	}

	return (
		<>
			<View>
				<ViewContainer breakpoint="desktop">
					<Container>
						<Menu
							{...{
								menu,
								isLoading,
								headerProps,
								menuCallbackUrl,
								checkinId
							}}
						/>

						{isTabletBreakpoint && <ScreenFooter />}
					</Container>
				</ViewContainer>
			</View>

			<RouterView routes={childRoutes} />
		</>
	);
};

export default MenuView;
