import { compose, defaultTo, map, prop, propOr, uniqBy } from "ramda";
import dealToMessage from "./deal-to-message";

const itemDisplayMessages = cartItem => {
	let result = [];
	const { note, deals } = cartItem;
	const messages = propOr([], "messages")(cartItem);
	const dealsMessages = compose(
		map(dealToMessage),
		uniqBy(prop("dealId")),
		defaultTo([])
	)(deals);

	result = [...messages, ...dealsMessages];

	if (note) {
		const noteMessage = { type: "note", name: "NOTE", description: note };
		result = [noteMessage, ...result];
	}

	return result;
};

export default itemDisplayMessages;
