import { useCallback, useMemo } from "react";
import usePaymentRequest from "./use-payment-request";

const useApplePay = ({ amount }, callback) => {
	const options = useMemo(
		() => ({
			country: "SE",
			currency: "sek",
			total: {
				label: "Passbuy",
				amount
			}
		}),
		[amount]
	);

	const onPaymentMethod = useCallback(
		({ complete, paymentMethod }) =>
			callback({
				complete: () => complete("success"),
				paymentMethod: paymentMethod.id
			}),
		[callback]
	);

	return usePaymentRequest(options, onPaymentMethod);
};

export default useApplePay;
