import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import { isHighlighted, navigateToSection } from "./functions";
import Item from "./Item";
import useHighlightedSection from "./use-highlighted-section";
import useHighlights from "./use-highlights";
import useItems from "./use-items";
import useNavigationEnds from "./use-navigation-ends";
import useScrollToSection from "./use-scroll-to-section";

const Container = styled.div`
	background-color: ${p => p.theme.colorWhite};
	display: flex;
	width: 100%;
	box-sizing: border-box;
	overflow-x: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	border-bottom: 1px solid ${p => p.theme.colorBorderPrimary};

	/* PaddingLeft Should be large. But doing large here and then account for the first item having medium padding left doesn't align when is highlighted.
	So just visually aligned highlighted first item with sections by putting 6 */
	padding-left: 6px;
	padding-right: ${p => p.theme.spaceHuge};

	&::-webkit-scrollbar {
		display: none;
	}

	@media ${mediaQuery(device.tablet)} {
		padding-left: 4px;
	}
`;

const SectionNavigator = ({
	sections,
	navigations,
	navigationEnds,
	highlights
}) => {
	const flatListRef = useRef();
	const navigatingToRef = useRef();
	const items = useItems(sections);
	const [highlightedSection, setHighlightedSection] = useHighlightedSection({
		navigatingToRef
	});
	const [sectionToHighlight, setSectionToHighlight] = useState();

	useHighlights({
		highlights,
		sectionToHighlight,
		setSectionToHighlight,
		setHighlightedSection
	});

	useNavigationEnds({
		navigationEnds,
		navigatingToRef,
		setHighlightedSection,
		sectionToHighlight,
		highlightedSection
	});

	useScrollToSection({
		section: highlightedSection,
		sections,
		flatListRef
	});

	const handleItemClick = useCallback(
		i =>
			navigateToSection({
				navigations,
				navigatingToRef,
				highlightedSection
			})(i),
		[highlightedSection, navigations]
	);

	const renderItem = useCallback(
		(item, index) => {
			const first = index === 0;
			const last = index === items.length - 1;
			const highlighted = isHighlighted(
				{ sectionToHighlight, navigatingToRef },
				item
			);

			return (
				<Item
					{...{ item, first, last, highlighted }}
					key={index}
					onClick={handleItemClick}
				/>
			);
		},
		[handleItemClick, items.length, sectionToHighlight]
	);

	return useMemo(
		() => (
			<Container ref={flatListRef}>
				{items.length > 1 && items.map(renderItem)}
			</Container>
		),
		[items, renderItem]
	);
};

SectionNavigator.defaultProps = {
	selectedSection: {}
};

export default SectionNavigator;
