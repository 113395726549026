import { compose, defaultTo, map } from "ramda";
import { useState } from "react";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";

const sectionToItem = s => ({
	key: s.menuSectionId,
	label: s.name,
	section: s
});

const useItems = sections => {
	const [items, setItems] = useState([]);

	useDeepCompareEffect(() => {
		compose(setItems, map(sectionToItem), defaultTo([]))(sections);
	}, [sections]);

	return items;
};

export default useItems;
