import { menuRepo } from "data";
import { useCallback } from "react";
import { useGlobalState } from "state";
import { useFetch } from "utils";

const useMenu = ({ checkinId, storeId, ignoreSchedule }) => {
	const { menu, setMenu } = useGlobalState();

	const fetchMenu = useCallback(async () => {
		if (!checkinId && !storeId) {
			return [];
		}

		return menuRepo.getAll({ checkinId, storeId, ignoreSchedule });
	}, [checkinId, storeId, ignoreSchedule]);

	const [menus = [], isLoading] = useFetch(fetchMenu);
	const fetchedMenu = menus[0];

	if (menu?.menuId !== fetchedMenu?.menuId) {
		setMenu(fetchedMenu);
	}

	return [menu, isLoading];
};

export default useMenu;
