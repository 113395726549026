import styled from "styled-components";

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding-left: ${p => p.theme.spaceLarge};
	padding-right: ${p => p.theme.spaceLarge};
	padding-bottom: ${p => p.theme.spaceHuge};
`;

const Header = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: ${p => p.theme.spaceHuge};
	padding-bottom: ${p => p.theme.spaceLarge};
	margin-bottom: ${p => p.theme.spaceHuge};
	border-bottom: 1px solid ${p => p.theme.colorBorderMuted};
`;

const Logo = styled.img`
	max-height: 38px;
	width: auto;
	max-width: 200px;
`;

export { Container, Header, Logo };
