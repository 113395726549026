import React from "react";
import styled from "styled-components";

const Outer = styled.div`
	position: relative;
	padding: ${p => p.theme.spaceSmall};
	border: 1px solid ${p => p.theme.colorBorderMuted};
	margin-bottom: ${p => p.theme.spaceMedium};
	border-radius: 10px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.01);

	${p =>
		p.clickable &&
		`
		cursor: pointer;

		&:hover {
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
		}
	`}
`;

const Inner = styled.div`
	display: flex;
	flex-direction: column;
`;

const Container = ({ children, clickable }) => {
	return (
		<Outer clickable={clickable}>
			<Inner>{children}</Inner>
		</Outer>
	);
};

export default Container;
