import React from "react";
import styled, { css } from "styled-components";

const horizontal = css`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Container = styled.div`
	padding-top: ${p => (p.slim ? p.theme.spaceSmall : p.theme.spaceMedium)};
	padding-bottom: ${p => (p.slim ? p.theme.spaceSmall : p.theme.spaceMedium)};
	border-top: 1px solid ${p => p.theme.colorBorderMuted};
	border-top-width: ${p => (p.clear ? 0 : "1px")};
	border-bottom-width: ${p => (p.last ? "1px" : 0)};
	border-bottom-color: ${p => p.theme.colorBorderMuted};
	border-bottom-style: solid;
`;

const ContainerInner = styled.div`
	padding-left: ${p => p.theme.spaceMedium};
	padding-right: ${p => p.theme.spaceMedium};
	${p => (p.horizontal ? horizontal : "")};

	& .core-section-inner {
		padding-left: 0;
		padding-right: 0;
	}
`;

const Section = ({ children, last, clear, slim, horizontal, className }) => {
	const classNames = ["core-section", className].join(" ");

	return (
		<Container {...{ last, clear, slim, className: classNames }}>
			<ContainerInner {...{ horizontal }} className="core-section-inner">
				{children}
			</ContainerInner>
		</Container>
	);
};

export default Section;
