import { is, isEmpty, map } from "ramda";
import React, { useMemo } from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import Icon from "../Icon";

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const ButtonContainer = styled.div`
	padding: 0 ${p => p.theme.spaceMini};
	margin-left: ${p => p.theme.spaceMedium};
	cursor: pointer;

	@media ${mediaQuery(device.tablet)} {
		&:last-child {
			margin-right: ${p => p.theme.spaceSmall};
		}
	}
`;

const Button = ({ onClick, icon }) => (
	<ButtonContainer onClick={onClick}>
		{is(String, icon) && <Icon name={icon} size={26} />}
		{is(Object, icon) && icon()}
	</ButtonContainer>
);

const Actions = ({ actions }) => {
	const actionComps = useMemo(
		() => map(a => <Button {...a} key={a.key} />, actions),
		[actions]
	);

	if (isEmpty(actions)) {
		return null;
	}

	return <Container>{actionComps}</Container>;
};

export default Actions;
