import { getAuth } from "firebase/auth";

const auth = getAuth();

const getCurrentUser = () =>
	new Promise(resolve => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			resolve(user);
			unsubscribe();
		});
	});

export default getCurrentUser;
