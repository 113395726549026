import { Section } from "modules/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const Label = styled.div`
	font-size: 13.5px;
	user-select: none;
`;

const Value = styled.div`
	font-size: 13.5px;
	color: ${p => p.theme.colorTextMuted};
`;

const Detail = ({ label, value, first }) => {
	return (
		<Section>
			<Container first={first}>
				<Label>{label}</Label>
				<Value>{value}</Value>
			</Container>
		</Section>
	);
};

export default Detail;
