import React from "react";
import CloseButton from "./CloseButton";
import { Container, ContainerInner, Image } from "./Cover.styles";

const Cover = ({ imageUrl, onClose }) => {
	return (
		<Container>
			<ContainerInner>
				<CloseButton onClick={onClose} />
				<Image src={imageUrl} alt="product" />
			</ContainerInner>
		</Container>
	);
};

export default Cover;
