import { Loader } from "modules/core";
import { identity, unless } from "ramda";
import React, { useCallback } from "react";
import styled from "styled-components";

const Container = styled.div`
	padding: ${p => p.theme.spaceLarge};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContainerInner = styled.div`
	position: relative;
`;

const Label = styled.span`
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
	visibility: ${p => (p.hide ? "hidden" : "visible")};
	cursor: pointer;
`;

const LoaderContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const MoreButton = ({ isLoading, hide, onClick, ...props }) => {
	const handleClick = useCallback(
		() => unless(identity, onClick)(hide),
		[onClick, hide]
	);

	return (
		<Container {...props} onClick={handleClick}>
			<ContainerInner>
				<Label hide={hide || isLoading}>Load more</Label>

				{isLoading && (
					<LoaderContainer>
						<Loader size="mini" color="gray" />
					</LoaderContainer>
				)}
			</ContainerInner>
		</Container>
	);
};

export default MoreButton;
