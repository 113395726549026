import { compose, trim } from "ramda";
import React, { useEffect, useState } from "react";
import { useToggle } from "utils";
import {
	Button,
	ButtonIcon,
	Container,
	Overlay,
	Text
} from "./Brandinformation.styles";

const BrandInformation = ({ children }) => {
	const [isOpen, toggle] = useToggle();
	const [text, setText] = useState();
	const expandable = text?.length > 200;

	useEffect(() => {
		if (typeof children !== "string") {
			setText(null);
			return;
		}

		compose(setText, trim)(children);
	}, [children]);

	return (
		<Container onClick={toggle}>
			{text !== null && (
				<Text text={text} {...{ isOpen, expandable }}>
					{text}

					{expandable && <Overlay isOpen={isOpen} />}
				</Text>
			)}

			{expandable && (
				<Button>
					<ButtonIcon
						isOpen={isOpen}
						className="mdi mdi-chevron-down"
					/>
				</Button>
			)}
		</Container>
	);
};

export default BrandInformation;
