import CartView from "../CartView";
import MenuItemView from "../MenuItemView";
import { useRouteMatch } from "react-router-dom";
import { useMemo } from "react";

const useChildRoutes = ({ menu }) => {
	let { path } = useRouteMatch();

	return useMemo(
		() => [
			{
				path: `${path}/item/:menuItemId`,
				Component: MenuItemView,
				exact: true,
				props: { menu }
			},
			{
				path: `${path}/cart/:cartId?`,
				Component: CartView,
				exact: true
			}
		],
		[path, menu]
	);
};

export default useChildRoutes;
