import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const Container = styled.div`
	height: ${p => p.size}px;
	width: ${p => p.size}px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;

const Skeleton = ({ size }) => {
	const rectSize = size * 0.8; // 80% because the actual Swish QR have transparent 20% padding

	return (
		<Container size={size}>
			<ContentLoader
				speed={2}
				width="100%"
				height={rectSize}
				viewBox={`0 0 ${rectSize} ${rectSize}`}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
			>
				<rect
					x="0"
					y="0"
					rx="32"
					ry="32"
					width={rectSize}
					height={rectSize}
				/>
			</ContentLoader>
		</Container>
	);
};

export default Skeleton;
