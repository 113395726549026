import { Icon } from "modules/core";
import React from "react";
import theme from "styles/theme";

const Header = () => {
	return (
		<Icon name="close-circle-outline" color={theme.colorWhite} size={140} />
	);
};

export default Header;
