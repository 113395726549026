import { compose, head } from "ramda";
import { useEffect } from "react";
import { useGlobalState } from "state";
import useDisplayPaymentMethods from "./use-display-payment-methods";
import usePaymentMethods from "./use-payment-methods";

const useDefaultPaymentMethod = checkinId => {
	const { setPaymentMethod, paymentMethod } = useGlobalState();
	const { paymentMethods } = usePaymentMethods(checkinId);
	const displayPaymentMethods = useDisplayPaymentMethods(paymentMethods);

	useEffect(() => {
		if (paymentMethod) {
			return;
		}

		if (!displayPaymentMethods) {
			return;
		}

		compose(setPaymentMethod, head)(displayPaymentMethods);
	}, [paymentMethod, setPaymentMethod, displayPaymentMethods]);
};

export default useDefaultPaymentMethod;
