import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${p => p.theme.spaceMedium};
	border-top: 1px solid ${p => p.theme.colorBorderMuted};
`;

const Skeleton = () => (
	<Container>
		<ContentLoader
			speed={2}
			width={80}
			height={24}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
		>
			<rect y="2" rx="4" ry="4" width="80" height="20" />
		</ContentLoader>

		<ContentLoader
			speed={2}
			width={80}
			height={24}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
		>
			<rect y="2" rx="4" ry="4" width="80" height="20" />
		</ContentLoader>
	</Container>
);

export default Skeleton;
