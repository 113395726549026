import { useCallback, useEffect, useState } from "react";
import { useQuery } from "utils";
import { getAuth } from "firebase/auth";
import { setUserId, logError } from "modules/analytics";
import resolveUser from "./resolve-user";

const auth = getAuth();

const useUser = () => {
	const [isResolved, setIsResolved] = useState(false);
	const [error, setError] = useState();
	const { customUserTokenId } = useQuery();

	const effect = useCallback(async (customUserTokenId = null) => {
		try {
			setIsResolved(false);
			await resolveUser({ customUserTokenId });
			setIsResolved(true);
			setError();
		} catch (error) {
			setError(error);
			logError(error, true);
		}
	}, []);

	useEffect(() => {
		effect(customUserTokenId);
	}, [effect, customUserTokenId]);

	useEffect(() => {
		auth.onAuthStateChanged(user => {
			if (user) {
				setUserId(user.uid);
			}
		});
	}, []);

	return [auth.currentUser, isResolved, error];
};

export default useUser;
