import { route, useNavigation } from "modules/navigation";
import React, { useCallback } from "react";
import Description from "./Description";
import Image from "./Image";
import Price from "./Price";
import { Container, Body, Name } from "./ItemPreview.styles";

const Item = ({ menuId, item, callbackUrl }) => {
	const { menuItemId, product } = item;
	const { name, description, imageUrl } = product;
	const { navigate } = useNavigation();

	const handleClick = useCallback(
		() =>
			navigate(route.menuItem, {
				menuId,
				menuItemId,
				callbackUrl
			}),
		[navigate, menuId, menuItemId, callbackUrl]
	);

	return (
		<>
			<Container onClick={handleClick}>
				<Body>
					<Name>{name}</Name>
					{description && <Description>{description}</Description>}
					<Price item={item} />
				</Body>

				<Image src={imageUrl} />
			</Container>
		</>
	);
};

export default Item;
