import { route, useNavigation } from "modules/navigation";
import React, { useMemo } from "react";
import { AccountButton, Stores } from "./components";
import { Container, Header, Logo } from "./Marketplace.styles";

const Marketplace = ({ marketplace }) => {
	const { buildUrl } = useNavigation();
	const { marketplaceId, logo, checkins } = marketplace;
	const marketplaceCallbackUrl = useMemo(
		() => buildUrl(route.marketplace, { marketplaceId }),
		[buildUrl, marketplaceId]
	);

	return (
		<Container>
			<Header>
				<Logo src={logo?.large} />

				<AccountButton callbackUrl={marketplaceCallbackUrl} />
			</Header>

			<Stores checkins={checkins} callbackUrl={marketplaceCallbackUrl} />
		</Container>
	);
};

export default Marketplace;
