import { compose, curry, find, flatten, pluck, prop, propEq } from "ramda";

const menuItem = curry((menuItemId, menu) =>
	compose(
		find(propEq("menuItemId", menuItemId)),
		flatten,
		pluck("items"),
		prop("sections")
	)(menu)
);

export default menuItem;
