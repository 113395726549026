import { getAuth, getIdToken } from "firebase/auth";
import { getSession } from "modules/analytics";
import { andThen, compose, curry, isEmpty, mergeDeepRight } from "ramda";

const auth = getAuth();

const curriedFetch = curry((url, config) => fetch(url, config));

const HttpClient = () => {
	const mergeGlobalConfig = async config => {
		const currentUserToken =
			auth.currentUser && (await getIdToken(auth.currentUser));
		const headers = new Headers();
		currentUserToken &&
			headers.append("Authorization", `Bearer ${currentUserToken}`);
		headers.append("Accept", "application/json");
		headers.append("Content-Type", "application/json");
		headers.append("Session", getSession());
		const globalConfig = { headers };

		return mergeDeepRight(globalConfig, config);
	};

	const fetch = async (url, reqConfig = {}, config = {}) => {
		const res = await compose(
			andThen(curriedFetch(url)),
			mergeGlobalConfig
		)(reqConfig);

		if (!res.ok) {
			const error = !isEmpty(res.statusText)
				? res.statusText
				: res.status;
			throw new Error(error);
		}

		if (config.rawResponse) {
			return res;
		}

		try {
			return await res.json();
		} catch (error) {
			return null;
		}
	};

	return { fetch };
};

export default HttpClient();
