import { deliveryRepo } from "data";
import { identity, includes } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { poll } from "utils";

const finalStatuses = ["DELIVERED", "ERROR"]; // TODO: add case for table service

const usePollDelivery = orderId => {
	const [delivery, setDelivery] = useState();

	const pollCallback = useCallback(async () => {
		try {
			if (!orderId) {
				return [false];
			}

			const [fetchedDilvery] = await deliveryRepo.getByOrderId({
				orderId
			});
			setDelivery(fetchedDilvery);
			const isCompleted = includes(fetchedDilvery.status, finalStatuses);
			return [isCompleted, fetchedDilvery];
		} catch (e) {
			return [false];
		}
	}, [setDelivery, orderId]);

	useEffect(() => {
		const [resultPromise, cancelPolling] = poll(
			pollCallback,
			10800000,
			1500
		); // 3h timeout
		resultPromise.catch(identity);
		return () => cancelPolling();
	}, [pollCallback]);

	return delivery;
};

export default usePollDelivery;
