import { always, compose, map, toPairs } from "ramda";
import React from "react";
import Message from "./Message";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Messages = ({ messages, size }) => {
	const getMetaData = i => ({
		key: i,
		isHead: i === "0",
		isTail: i === (messages.length - 1).toString()
	});

	const rendeMessage = ([i, m]) => (
		<Message message={m} size={size} {...getMetaData(i)} />
	);

	const renderMessages = compose(
		map(rendeMessage),
		always(toPairs(messages))
	);

	if (messages.length === 0) {
		return null;
	}

	return <Container>{renderMessages()}</Container>;
};

Messages.defaultProps = {
	messages: [],
	size: "medium"
};

export default Messages;
