import { always, cond, eqProps, map, propEq } from "ramda";
import React from "react";
import styled from "styled-components";
import OptionCustom from "./OptionCustom";
import OptionPercentage from "./OptionPercentage";

const Container = styled.div`
	display: flex;
`;

const Options = ({ options, selectedOption, onOptionSelected }) => {
	const isSelected = eqProps("optionId", selectedOption || {});

	const renderOption = o => {
		const props = {
			key: o.optionId,
			option: o,
			selected: isSelected(o),
			onSelect: onOptionSelected
		};

		return cond([
			[propEq("type", "custom"), always(<OptionCustom {...props} />)],
			[
				propEq("type", "percentage"),
				always(<OptionPercentage {...props} />)
			]
		])(o);
	};

	const renderOptions = map(renderOption);

	return <Container>{renderOptions(options)}</Container>;
};

Options.defaultProps = {
	options: []
};

export default Options;
