import { StoreHeader } from "modules/core";
import { useNavigation } from "modules/navigation";
import { compose, mergeRight, pick } from "ramda";
import React, { forwardRef, useMemo } from "react";
import Sticky from "react-sticky-el";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";
import { useBreakpoint, useToggle } from "utils";
import SectionNavigator from "../SectionNavigator";
import AccountAction from "./AccountAction";
import CartAction from "./CartAction";

const StickyInner = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${p => p.theme.colorWhite};
	box-shadow: ${p =>
		p.isSticky ? "0px 0px 16px rgba(0, 0, 0, 0.05)" : "none"};

	@media ${mediaQuery(device.desktop)} {
		box-shadow: none;
	}
`;

const Header = ({ headerProps, navigatorProps, sections }, ref) => {
	const [isSticky, toggleSticky] = useToggle(false);
	const { navigate } = useNavigation();
	const storeHeaderProps = useMemo(
		() =>
			compose(
				mergeRight({
					size: "large",
					actions: [
						CartAction({ ...headerProps, navigate }),
						AccountAction({
							menuCallbackUrl: headerProps.menuCallbackUrl,
							navigate
						})
					]
				}),
				pick(["checkin", "callbackUrl"])
			)(headerProps),

		[navigate, headerProps]
	);
	const isDesktopBreakpoint = useBreakpoint(device.desktop);

	return (
		<>
			{headerProps && !isDesktopBreakpoint && (
				<StoreHeader {...storeHeaderProps} />
			)}

			<Sticky stickyStyle={{ zIndex: 999 }} onFixedToggle={toggleSticky}>
				<StickyInner ref={ref} isSticky={isSticky}>
					{headerProps && isDesktopBreakpoint && (
						<StoreHeader {...storeHeaderProps} />
					)}

					<SectionNavigator {...{ ...navigatorProps, sections }} />
				</StickyInner>
			</Sticky>
		</>
	);
};

export default forwardRef(Header);
