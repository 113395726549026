import { cond, equals, flip, hasIn } from "ramda";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "utils";
import { navigateBack, navigateCustom, navigateToRoute } from "./functions";
import route from "./route";
import routes from "./routes";

const useNavigate = () => {
	const history = useHistory();
	const currentQuery = useQuery();

	const navigate = useCallback(
		(routeName, args) =>
			cond([
				[equals(route.BACK), navigateBack(history)],
				[equals(route.CUSTOM), () => navigateCustom(history, args)],
				[
					flip(hasIn)(routes),
					name => navigateToRoute(history, currentQuery, name, args)
				]
			])(routeName),
		[history, currentQuery]
	);

	return navigate;
};

export default useNavigate;
