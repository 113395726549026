import { always, cond, equals, path, propEq } from "ramda";
import React from "react";
import RSpinner from "react-spinkit";
import styled from "styled-components";

const getSize = cond([
	[equals("tiny"), always(9)],
	[equals("mini"), always(12)],
	[equals("small"), always(16)],
	[equals("medium"), always(20)],
	[equals("large"), always(25)]
]);

const Spinner = styled(RSpinner)`
	color: ${cond([
		[propEq("$color", "green"), path(["theme", "colorEmeraldGreen"])],
		[propEq("$color", "gray"), path(["theme", "colorLightGray"])],
		[propEq("$color", "white"), path(["theme", "colorWhite"])]
	])};

	& > div {
		width: ${p => getSize(p.size)}px;
		height: ${p => getSize(p.size)}px;
	}
`;

const Loader = ({ size, fadeIn, className, color }) => {
	return (
		<Spinner
			name="three-bounce"
			{...{ size, $color: color, fadeIn, className }}
		/>
	);
};

Loader.defaultProps = {
	size: "medium",
	fadeIn: "none",
	color: "green"
};

export default Loader;
