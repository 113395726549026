import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const FloatingFooter = styled.div`
	position: ${p => (p.fixed ? "fixed" : "absolute")};
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${p => p.theme.colorWhite};
	padding: ${p => p.theme.spaceSmall};
	padding-bottom: ${p => p.theme.spaceLarge};
	border-top: 1px solid ${p => p.theme.colorBorderPrimary};
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

	@media ${mediaQuery(device.tablet)} {
		padding-bottom: ${p => p.theme.spaceSmall};
	}
`;

export default FloatingFooter;
