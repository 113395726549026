import { route, useNavigation } from "modules/navigation";
import React from "react";
import styled from "styled-components";
import { useQuery } from "utils";
import CloseButton from "../CloseButton";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${p => p.theme.spaceLarge};
`;

const Footer = () => {
	const { callbackError } = useQuery();
	const { navigate } = useNavigation();

	const handleClick = () =>
		navigate(route.CUSTOM, { url: callbackError, uriEncoded: true });

	return (
		<Container>
			<CloseButton onClick={handleClick} />
		</Container>
	);
};

export default Footer;
