import { Modal as CoreModal } from "modules/core";
import { compose, is, path, when } from "ramda";
import React, { useEffect, useState } from "react";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import styled from "styled-components";
import isValidTip from "../is-valid-tip";

const inputToAmount = when(is(String), Number.parseInt);

const validateInput = compose(isValidTip, inputToAmount);

const formControlStyle = {
	boxShadow: "none",
	borderColor: "rgb(206, 212, 218)"
};

const Container = styled.div`
	margin-top: ${p => p.theme.spaceLarge};
`;

const Modal = ({ amount, isOpen, onClose, onConfirm }) => {
	const [inputValue, setInputValue] = useState("");
	const [isValidAmount, setIsValidAmount] = useState();

	useEffect(() => {
		compose(setIsValidAmount, validateInput)(inputValue);
	}, [inputValue]);

	useEffect(() => {
		if (!amount) {
			return;
		}

		setInputValue(amount);
	}, [amount]);

	const handleChange = compose(setInputValue, path(["target", "value"]));

	const handleConfirm = () => compose(onConfirm, inputToAmount)(inputValue);

	return (
		<CoreModal
			heading="Add a tip"
			isOpen={isOpen}
			onClose={onClose}
			onConfirm={handleConfirm}
			disabled={!isValidAmount}
			backdropClassName="tip-modal-backdrop"
		>
			<Container>
				<InputGroup>
					<FormControl
						value={inputValue}
						onChange={handleChange}
						type="number"
						inputMode="numeric"
						placeholder="Amount"
						autoFocus
						isValid={validateInput(inputValue)}
						style={formControlStyle}
					/>

					<InputGroup.Text>Kr</InputGroup.Text>
				</InputGroup>
			</Container>
		</CoreModal>
	);
};

export default Modal;
