import { Tile as CoreTile } from "modules/core";
import styled from "styled-components";

const Tile = styled(CoreTile)`
	margin-bottom: ${p => p.theme.spaceSmall};
	display: flex;
	align-items: center;
`;

const Image = styled.img`
	height: 35px;
	width: 35px;
	border-radius: 30px;
`;

const Body = styled.div`
	flex-grow: 1;
	margin-left: ${p => p.theme.spaceSmall};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const BodyLeft = styled.div`
	display: flex;
	flex-direction: column;
`;

const StoreName = styled.div`
	font-size: 13.5px;
	line-height: 1;
	margin-top: 1px;
`;

const Date = styled.div`
	color: ${p => p.theme.colorTextMuted};
	font-size: ;
	margin-top: 3px;
	font-size: 11px;
	line-height: 1.2;
`;

export { Tile, Image, Body, BodyLeft, StoreName, Date };
