import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	margin: ${p => p.theme.spaceMedium};
	margin-bottom: ${p => p.theme.spaceMedium};
	overflow: hidden;
`;

const HeaderContainer = styled.div`
	margin-bottom: ${p => p.theme.spaceHuge};
`;

const ItemSkeleton = ({ y }) => (
	<ContentLoader
		speed={2}
		width="100%"
		height={38}
		viewBox={`0 0 ${window.innerWidth} 38`}
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		style={{ marginBottom: 16 }}
	>
		<rect x="0" y="0" rx="4" ry="4" width="75%" height="16" />
		<rect x="0" y="21" rx="4" ry="4" width="55%" height="13" />
	</ContentLoader>
);

const TabSkeleton = () => {
	return (
		<Container>
			{/* Header */}
			<div>
				<HeaderContainer>
					<ContentLoader
						speed={2}
						width="100%"
						height={40}
						viewBox={`0 0 ${window.innerWidth} 40`}
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="50"
							y="4"
							rx="4"
							ry="4"
							width="160"
							height="14.5"
						/>
						<rect
							x="50"
							y="25.5"
							rx="4"
							ry="4"
							width="60"
							height="13"
						/>
						<circle cx="20" cy="20" r="20" />
					</ContentLoader>
				</HeaderContainer>

				{/* Items */}
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
				<ItemSkeleton />
			</div>

			{/* Footer */}
			<div>
				<ContentLoader
					speed={2}
					width="100%"
					height={52}
					viewBox={`0 0 ${window.innerWidth} 52`}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<rect x="0" y="0" rx="8" ry="8" width="100%" height="50" />
				</ContentLoader>
			</div>
		</Container>
	);
};

export default TabSkeleton;
