import Loader from "./Loader";
import View from "./View";
import React from "react";
import styled from "styled-components";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;

	@media ${mediaQuery(device.tablet)} {
		position: absolute;
	}
`;

const ContainerInner = styled.div`
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const LoaderOverlay = ({ isLoading }) => {
	if (!isLoading) {
		return null;
	}

	return (
		<Container>
			<View transparent>
				<ContainerInner>
					<Loader />
				</ContainerInner>
			</View>
		</Container>
	);
};

export default LoaderOverlay;
