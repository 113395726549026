import { Section } from "modules/core";
import Description from "../Description";
import NoteInput from "../NoteInput";
import Price from "../Price";
import ProductOptions from "../ProductOptions";
import QuantityCounter from "../QuantityCounter";
import CloseButton from "./CloseButton";
import { Container, Header, MainActions, Name } from "./Body.styles";
import { device } from "styles/device";
import { useBreakpoint } from "utils";

const Body = ({
	item,
	productOptions,
	selectedProductOptions,
	onSelectedProductOptionChanged,
	onClose,
	note,
	onNoteChanged,
	quantity,
	onQuantityChanged
}) => {
	const { product } = item;
	const { name, description, imageUrl } = product;
	const isTabletBreakpoint = useBreakpoint(device.tablet);

	return (
		<Container>
			<Header>
				<Name>{name}</Name>

				{!imageUrl && <CloseButton onClick={onClose} />}
			</Header>

			<Section clear>
				{!!description && <Description>{description}</Description>}
				<Price item={item} />
			</Section>

			<Section clear>
				<MainActions>
					<NoteInput note={note} onChange={onNoteChanged} />
					<QuantityCounter
						quantity={quantity}
						onChange={onQuantityChanged}
					/>
				</MainActions>
			</Section>

			{selectedProductOptions && (
				<ProductOptions
					{...{
						selectedProductOptions,
						onSelectedProductOptionChanged,
						productOptions,
						collapse: isTabletBreakpoint
					}}
				/>
			)}
		</Container>
	);
};

export default Body;
