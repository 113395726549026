import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13.5px;
	line-height: 1;
	font-weight: 500;
	padding: ${p => p.theme.spaceSmall};
	border-radius: ${p => p.theme.spaceSmall};
	color: ${p => p.theme.colorTextMuted};
	cursor: pointer;

	&:hover {
		color: ${p => p.theme.colorTextPrimary};
	}

	${({ active, theme }) =>
		active &&
		`
        background: ${theme.colorWhite};
        border: 1px solid ${theme.colorBorderPrimary}
        box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
        color: ${theme.colorTextPrimary}
    `};
`;

const Button = ({ onClick, children, active }) => {
	return (
		<Container onClick={onClick} active={active}>
			{children}
		</Container>
	);
};

export default Button;
