import { Badge, Section, SectionLabel } from "modules/core";
import {
	always,
	compose,
	cond,
	defaultTo,
	isEmpty,
	propEq,
	propOr,
	T
} from "ramda";
import React, { useMemo } from "react";
import MethodPicker from "../MethodPicker";
import Skeleton from "./Skeleton";

const Delivery = ({
	deliveryDestination,
	deliveryMethod,
	deliveryMethods,
	onChange
}) => {
	const isMultipleMethods = useMemo(
		() => deliveryMethods?.length > 1,
		[deliveryMethods]
	);
	const deliveryLabel = useMemo(
		() =>
			compose(
				cond([
					[
						propEq("deliveryMethod", "TABLE_SERVICE"),
						always(deliveryDestination)
					],
					[
						propEq("deliveryMethod", "PREORDER"),
						always(deliveryDestination)
					],
					[
						propEq("deliveryMethod", "PICKUP"),
						propOr("Pickup", "label")
					],
					[T, always(null)]
				]),
				defaultTo({})
			)(deliveryMethod),
		[deliveryMethod, deliveryDestination]
	);

	if (!deliveryMethods || isEmpty(deliveryMethods)) {
		return <Skeleton />;
	}

	return (
		<>
			{isMultipleMethods && (
				<Section horizontal slim>
					<MethodPicker
						deliveryMethod={deliveryMethod}
						deliveryMethods={deliveryMethods}
						onChange={onChange}
					/>
				</Section>
			)}

			{deliveryLabel && (
				<Section horizontal>
					<SectionLabel>Delivery</SectionLabel>
					<Badge>{deliveryLabel}</Badge>
				</Section>
			)}
		</>
	);
};

export default Delivery;
