import React, { useCallback } from "react";
import styled from "styled-components";
import accountIcon from "assets/icons/account-linear-black.svg";
import { route, useNavigation } from "modules/navigation";

const Container = styled.div`
	width: 22px;
	height: 22px;
	box-sizing: content-box;
	padding: ${p => p.theme.spaceSmall};
	position: relative;
	right: -${p => p.theme.spaceMini};
	cursor: pointer;
`;

const Icon = styled.img`
	color: ${p => p.theme.colorIconBlack};
	width: 22px;
	height: auto;
	position: relative;
	top: -2px;
`;

const AccountButton = ({ callbackUrl }) => {
	const { navigate } = useNavigation();

	const handleClick = useCallback(
		() => navigate(route.account, { callbackUrl }),
		[navigate, callbackUrl]
	);

	return (
		<Container onClick={handleClick}>
			<Icon src={accountIcon} alt="account" />
		</Container>
	);
};

export default AccountButton;
