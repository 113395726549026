import { useCallback, useState } from "react";
import { useInterval } from "utils";

const usePoll = (fn, { active = true, callback, interval = 1000 }) => {
	const [error, setError] = useState();

	const poll = useCallback(async () => {
		if (!active) {
			return;
		}

		try {
			const res = await fn();
			callback(res);
		} catch (error) {
			setError(error);
		}
	}, [fn, callback, active]);

	useInterval(poll, interval);

	return error;
};

export default usePoll;
