import React from "react";
import styled from "styled-components";
import accountIcon from "assets/icons/account-linear-black.svg";
import { route } from "modules/navigation";

const Container = styled.div`
	display: flex;
	align-items: center;
	padding: ${p => p.theme.spaceSmall} ${p => p.theme.spaceMini};
	width: 20px;
	height: 20px;
	box-sizing: content-box;
	overflow: hidden;
`;

const Icon = styled.img`
	color: ${p => p.theme.colorIconBlack};
	width: 21px;
	height: auto;
`;

const AccountAction = ({ menuCallbackUrl, navigate }) => ({
	key: "account",
	onClick: () => navigate(route.account, { callbackUrl: menuCallbackUrl }),
	icon: () => (
		<Container>
			<Icon src={accountIcon} alt="icon" />
		</Container>
	)
});

export default AccountAction;
