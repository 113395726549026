const { route } = require("modules/navigation");

const AccountItem = ({ navigate, callbackUrl }) => ({
	key: "account",
	type: "link",
	label: "Account",
	icon: "account",
	iconPack: "passbuy",
	description: "View your order history & payment methods",
	onClick: () => navigate(route.account, { callbackUrl })
});

export default AccountItem;
