import styled from "styled-components";
import { FormCheck as BoostrapFormCheck } from "react-bootstrap";
import { Button as CoreButton } from "modules/core";
import { device, mediaQuery } from "styles/device";

const Container = styled.div`
	width: 100%;
`;

const Label = styled.div`
	font-size: 14px;
	margin-bottom: 6px;
	font-weight: 500;
`;

const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 6px;

	&:last-child {
		margin-bottom: 0;
	}

	@media ${mediaQuery(device.tablet)} {
		flex-direction: row;
		align-items: center;
	}
`;

const FormCheck = styled(BoostrapFormCheck)`
	font-size: 14px;
`;

const FormControlContainer = styled.div`
	flex-grow: 1;
	margin-bottom: ${p => p.theme.spaceMedium};

	@media ${mediaQuery(device.tablet)} {
		margin-bottom: 0;
		margin-right: ${p => p.theme.spaceMedium};
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	flex-shrink: 1;
`;

const Button = styled(CoreButton)`
	margin-left: ${p => p.theme.spaceSmall};

	&:first-child {
		margin-left: 0;
	}
`;

export {
	Container,
	Label,
	FormGroup,
	FormCheck,
	FormControlContainer,
	ButtonContainer,
	Button
};
