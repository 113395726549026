import config from "../config";
import httpClient from "./http-client";

const baseUrl = `${config.API_BASE_URL}/orders`;

const orderRepo = {
	getOrder: orderId => {
		if (!orderId) {
			throw new Error("orderId is required");
		}

		return httpClient.fetch(`${baseUrl}/${orderId}`);
	},
	getUserOrders: ({ page, pageSize }) => {
		if (!page) {
			throw new Error("page is required");
		}

		if (!pageSize) {
			throw new Error("pageSize is required");
		}

		return httpClient.fetch(`${baseUrl}?page=${page}&pageSize=${pageSize}`);
	}
};

export default orderRepo;
