import React, { useMemo } from "react";
import { formatCurrency } from "utils";
import styled from "styled-components";
import { Badge } from "modules/core";

const Text = styled.div`
	font-size: 12px;
	line-height: 1.3;
`;

const Total = ({ order }) => {
	const { total, isDiscounted } = order;
	const displayTotal = useMemo(() => formatCurrency(total), [total]);

	if (isDiscounted) {
		return <Badge color="green">{displayTotal}</Badge>;
	}

	return <Text>{displayTotal}</Text>;
};

export default Total;
