import { defaultTo, findIndex } from "ramda";
import { useRef } from "react";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";
import { isSameSection } from "./functions";

const useScrollToSection = ({
	section,
	sections,
	flatListRef,
	sectionToHighlight
}) => {
	const isFirstHighlight = useRef(true);

	useDeepCompareEffect(() => {
		const index = findIndex(
			isSameSection(section),
			defaultTo([], sections)
		);
		const isSectionNotFound = index < 0;

		if (isSectionNotFound) {
			return;
		}

		if (isSameSection(section, sectionToHighlight)) {
			return;
		}

		// Aborting scroll on first highlight (first section on render) otherwise it might do a weird scroll on mount
		if (isFirstHighlight.current) {
			isFirstHighlight.current = false;
			return;
		}

		flatListRef.current.scrollToIndex({
			index,
			animated: true,
			viewPosition: 0.5
		});
	}, [flatListRef, section, sectionToHighlight, sections]);
};

export default useScrollToSection;
