import { useMemo } from "react";
import useBuildUrl from "./use-build-url";
import useNavigate from "./use-navigate";

const useNavigation = () => {
	const navigate = useNavigate();
	const buildUrl = useBuildUrl();

	const navigation = useMemo(
		() => ({ navigate, buildUrl }),
		[navigate, buildUrl]
	);

	return navigation;
};

export default useNavigation;
