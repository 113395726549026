import { Icon } from "modules/core";
import { isEmpty, map } from "ramda";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import theme from "styles/theme";

const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: stretch;
`;

const Panel = styled.div`
	display: flex;
	background-color: ${p => p.theme.colorWhite};
	border-radius: ${p => p.theme.spaceSmall};
	border: 1px solid ${p => p.theme.colorBorderPrimary};
	padding: ${p => p.theme.spaceMini};
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
	min-height: 32px;

	&:hover {
		background-color: ${p => p.theme.colorDirtyWhite};
		transition: 100ms ease-in-out;
	}
`;

const Button = styled.div`
	display: flex;
	align-items: center;
	padding: 0 ${p => p.theme.spaceSmall};
	cursor: pointer;
`;

const FloatingActionButtons = ({ actions }) => {
	const renderAction = useCallback(
		({ handler, icon }) => (
			<Button onClick={handler} key={icon}>
				<Icon size={17.5} name={icon} color={theme.colorIconBlack} />
			</Button>
		),
		[]
	);

	const buttons = useMemo(
		() => map(renderAction, actions),
		[actions, renderAction]
	);

	if (isEmpty(buttons)) {
		return null;
	}

	return (
		<Container>
			<Panel>{buttons}</Panel>
		</Container>
	);
};

FloatingActionButtons.defaultProps = {
	actions: []
};

export default FloatingActionButtons;
