import { map } from "ramda";
import React, { useMemo } from "react";
import styled from "styled-components";
import Section from "./Section";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${p => p.theme.spaceHuge} ${p => p.theme.spaceLarge};
`;

const Items = ({ items }) => {
	const sectionComps = useMemo(
		() =>
			map(
				section => <Section section={section} key={section.id} />,
				items
			),
		[items]
	);

	return <Container>{sectionComps}</Container>;
};

export default Items;
