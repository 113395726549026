import { loadCart } from "modules/cart";
import { useEffect } from "react";
import { useGlobalState } from "state";

const useLoadCart = cartId => {
	const { cart, setCart, setCartLoading, isCartLoading } = useGlobalState();

	useEffect(() => {
		if (!cartId) {
			return;
		}

		if (cart?.cartId === cartId) {
			return;
		}

		if (isCartLoading) {
			return;
		}

		loadCart(setCart, setCartLoading, cartId);
	}, [cart, cartId, setCart, setCartLoading, isCartLoading]);
};

export default useLoadCart;
