import { cartRepo } from "data";
import { always, compose, isEmpty, isNil, reject, when } from "ramda";
import findOrGenerateCartItemId from "./find-or-generate-cart-item-id";

const addItem = async props => {
	const { cart, productId, barcode, quantity = 1 } = props;
	const { cartId, items } = cart;
	const note = when(isEmpty, always(null))(props.note);

	const cartItemId = compose(
		findOrGenerateCartItemId,
		reject(isNil)
	)({
		items,
		productId,
		barcode,
		note
	});

	await compose(
		cartRepo.addItem,
		reject(isNil)
	)({
		cartId,
		cartItemId,
		productId,
		barcode,
		quantity,
		note
	});
};

export default addItem;
