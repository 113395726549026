import config from "../config";
import httpClient from "./http-client";

const authRepo = {
	createCustomUserToken: () =>
		httpClient.fetch(`${config.API_BASE_URL}/users/custom-token`, {
			method: "POST",
			body: JSON.stringify({ useLimit: 0 })
		}),
	registerUser: () =>
		httpClient.fetch(`${config.API_BASE_URL}/users`, {
			method: "PUT"
		}),
	getCustomUserToken: customUserTokenId => {
		if (!customUserTokenId) {
			throw new Error("customUserTokenId is required");
		}

		return httpClient.fetch(
			`${config.API_BASE_URL}/users/custom-token/${customUserTokenId}`
		);
	}
};

export default authRepo;
