import { Icon, SectionLabel, Section as CoreSection } from "modules/core";
import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { useToggle } from "utils";
import Item from "./Item";

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${p => p.theme.spaceSmall};
`;

const Section = ({
	section,
	selectedProductOptions,
	onSelectedProductOptionChanged
}) => {
	const [isExpanded, toggleExpand] = useToggle(true);
	const theme = useTheme();

	const icon = useMemo(() => {
		const name = isExpanded ? "chevron-up" : "chevron-down";
		return <Icon size={26} name={name} color={theme.colorIconMuted} />;
	}, [isExpanded, theme]);

	return (
		<CoreSection clear>
			<Header onClick={toggleExpand}>
				<SectionLabel>{section.name}</SectionLabel>
				{icon}
			</Header>

			{isExpanded &&
				section.items.map(item => (
					<Item
						{...{
							key: item.menuItemId,
							selectedProductOptions,
							onSelectedProductOptionChanged,
							item
						}}
					/>
				))}
		</CoreSection>
	);
};

export default Section;
