import React, { useCallback } from "react";
import styled from "styled-components";
import logoWhite from "assets/brand/logo-white.svg";
import logoBlack from "assets/brand/logo-black.svg";
import { route, useNavigation } from "modules/navigation";
import config from "config";
import { device, mediaQuery } from "styles/device";
import { useBreakpointTernary } from "utils";

const logoHeight = 25;

const Container = styled.div`
	background: ${p => p.theme.colorDark};
	padding: ${p => p.theme.spaceGiant} ${p => p.theme.spaceMedium};
	color: ${p => p.theme.colorWhite};

	@media ${mediaQuery(device.desktop)} {
		background: none;
		border-top: 1px solid ${p => p.theme.colorBorderPrimary};
	}
`;

const Message = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: ${p => p.theme.spaceTiny};
`;

const Text = styled.span`
	font-size: 12.5px;
	line-height: ${logoHeight}px;
	margin-right: ${p => p.theme.spaceMini};

	@media ${mediaQuery(device.desktop)} {
		color: ${p => p.theme.colorTextPrimary};
	}
`;

const Logo = styled.img`
	height: ${logoHeight}px;
	width: auto;
`;

const ScreenFooter = () => {
	const { navigate } = useNavigation();
	const logoSrc = useBreakpointTernary(device.desktop, logoBlack, logoWhite);

	const handleMessageClick = useCallback(() => {
		navigate(route.CUSTOM, {
			url: config.COMPANY_WEBSITE_URL,
			mode: "open"
		});
	}, [navigate]);

	return (
		<Container>
			<Message onClick={handleMessageClick}>
				<Text>Powered by</Text> <Logo src={logoSrc} />
			</Message>
		</Container>
	);
};

export default ScreenFooter;
